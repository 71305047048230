import {
  ADD_COMPANY_CONFIGURATION,
  ADD_COMPANY_CONFIGURATION_SUCCESS,
  COMPANY_CONFIGURATION_ERROR,
  COMPANY_CONFIGURATION_MESSAGE,
  LOAD_COMPANY_CONFIGURATION,
  LOAD_COMPANY_CONFIGURATION_SUCCESS,
  LOAD_TRACKING,
  LOAD_TRACKING_SUCCESS,
  UPDATE_COMPANY_CONFIGURATION,
  UPDATE_COMPANY_CONFIGURATION_SUCCESS,
} from 'redux/constants/tracking/tracking';

export const loadCompanyConfiguration = (companyId) => {
  return {
    type: LOAD_COMPANY_CONFIGURATION,
    payload: { companyId },
  };
};

export const addCompanyConfiguration = (data) => {
  return {
    type: ADD_COMPANY_CONFIGURATION,
    payload: { data },
  };
};

export const updateCompanyConfiguration = (configId, data) => {
  return {
    type: UPDATE_COMPANY_CONFIGURATION,
    payload: { configId, data },
  };
};

export const loadCompanyConfigurationSuccess = (configurations) => {
  return {
    type: LOAD_COMPANY_CONFIGURATION_SUCCESS,
    payload: configurations,
  };
};
export const addCompanyConfigurationSuccess = (config) => {
  return {
    type: ADD_COMPANY_CONFIGURATION_SUCCESS,
    payload: config,
  };
};
export const updateCompanyConfigurationSuccess = (config) => {
  return {
    type: UPDATE_COMPANY_CONFIGURATION_SUCCESS,
    payload: config,
  };
};

export const loadTrackingAction = (userId, type, projectId = null) => {
  return {
    type: LOAD_TRACKING,
    payload: { userId, type, projectId },
  };
};

export const loadTrackingSuccess = (data) => {
  return {
    type: LOAD_TRACKING_SUCCESS,
    payload: data,
  };
};

export const companyConfigurationError = (msg) => {
  return {
    type: COMPANY_CONFIGURATION_ERROR,
    payload: msg,
  };
};

export const showCompanyConfigurationMassage = (message) => {
  return {
    type: COMPANY_CONFIGURATION_MESSAGE,
    message,
  };
};
