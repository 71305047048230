import {
  CREATE_COMMENT_SUCCESS,
  DELETE_COMMENT_SUCCESS,
  LOAD_COMMENT_SUCCESS,
  UPDATE_COMMENT_SUCCESS,
} from 'redux/constants/manage-project/comment.type';

const initialState = [];

function CommentReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_COMMENT_SUCCESS:
      return action.payload;
    case CREATE_COMMENT_SUCCESS:
      return [...state, action.payload];
    case UPDATE_COMMENT_SUCCESS:
      const updated = state.map((comment) =>
        comment._id === action.payload._id ? action.payload : comment
      );
      return updated;
    case DELETE_COMMENT_SUCCESS:
      return state.filter((comment) => comment._id !== action.payload._id);

    default:
      return state;
  }
}

export default CommentReducer;
