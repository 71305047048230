import axios from 'axios';
import { API_BASE_URL } from 'constants/ApiConstant';

const url = API_BASE_URL + 'comment/';

export const created = (data) => {
  return axios.post(`${url}`, data);
};

export const updated = (id, data) => {
  return axios.put(`${url}${id}`, data);
};

export const deleted = (id) => {
  return axios.delete(`${url}${id}`);
};

export const loaded = (taskID) => {
  return axios.get(`${url}${taskID}`);
};
