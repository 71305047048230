export const LOAD_COMPANY_CONFIGURATION = 'LOAD_COMPANY_CONFIGURATION';
export const LOAD_COMPANY_CONFIGURATION_SUCCESS =
  'LOAD_COMPANY_CONFIGURATION_SUCCESS';
export const ADD_COMPANY_CONFIGURATION = 'ADD_COMPANY_CONFIGURATION';
export const ADD_COMPANY_CONFIGURATION_SUCCESS =
  'ADD_COMPANY_CONFIGURATION_SUCCESS';
export const UPDATE_COMPANY_CONFIGURATION = 'UPDATE_COMPANY_CONFIGURATION';
export const UPDATE_COMPANY_CONFIGURATION_SUCCESS =
  'UPDATE_COMPANY_CONFIGURATION_SUCCESS';
export const COMPANY_CONFIGURATION_ERROR = 'COMPANY_CONFIGURATION_ERROR';
export const COMPANY_CONFIGURATION_MESSAGE = 'COMPANY_CONFIGURATION_MESSAGE';

export const LOAD_TRACKING = 'LOAD_TRACKING';
export const LOAD_TRACKING_SUCCESS = 'LOAD_TRACKING_SUCCESS';
