export const LOAD_CHECKLIST_BY_TASK = 'LOAD_CHECKLIST_BY_TASK';
export const CREATE_CHECKLIST = 'CREATE_CHECKLIST';
export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST';
export const DELETE_CHECKLIST = 'DELETE_CHECKLIST';

export const LOAD_CHECKLIST_BY_TASK_SUCCESS = 'LOAD_CHECKLIST_BY_TASK_SUCCESS';
export const CREATE_CHECKLIST_SUCCESS = 'CREATE_CHECKLIST_SUCCESS';
export const UPDATE_CHECKLIST_SUCCESS = 'UPDATE_CHECKLIST_SUCCESS';
export const DELETE_CHECKLIST_SUCCESS = 'DELETE_CHECKLIST_SUCCESS';

export const CHECKLIST_MESSAGE = 'CHECKLIST_MESSAGE';
export const CHECKLIST_ERROR = 'CHECKLIST_ERROR';
