import {
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE_SUCCESS,
  LOAD_BY_PROJECT_ROLE_SUCCESS,
  UPDATE_ROLE_SUCCESS,
} from 'redux/constants/manage-project/role.type';

const initialState = [];

function RoleReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_BY_PROJECT_ROLE_SUCCESS:
      return action.payload;
    case CREATE_ROLE_SUCCESS:
      return [...state, action.payload];
    case UPDATE_ROLE_SUCCESS:
      const updated = state.map((role) =>
        role._id === action.payload._id ? action.payload : role
      );
      return updated;
    case DELETE_ROLE_SUCCESS:
      return state.filter((role) => role._id !== action.payload._id);

    default:
      return state;
  }
}

export default RoleReducer;
