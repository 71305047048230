import axios from 'axios';
import { API_BASE_URL } from 'constants/ApiConstant';

const url = API_BASE_URL + 'workspace/';

export const created = (data) => {
  return axios.post(`${url}`, data);
};

export const updated = (id, data) => {
  return axios.put(`${url}${id}`, data);
};

export const deleted = (id) => {
  return axios.delete(`${url}${id}`);
};

export const loaded = (id = null, email = null) => {
  return axios.get(`${url}${id}/${email}`);
};

export const loadedDetails = (id) => {
  return axios.post(`${url}details/`, { id });
};

export const currentW = (id) => {
  return axios.get(`${url}${id}`);
};
