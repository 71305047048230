import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  addMessageSuccess,
  loadMessageSuccess,
  messageError,
  showMessageMessage,
} from 'redux/actions/chat/message';
import { ADD_MESSAGE, LOAD_MESSAGE } from 'redux/constants/chat/message';
import { addMessage, listMessageByChannel, listMessageByConversation } from 'services/ChatService';

export function* listMessage() {
  yield takeEvery(LOAD_MESSAGE, function* ({ payload }) {
    const { channelID , conversationID} = payload;
    try {
      const response = channelID !==""? yield call(listMessageByChannel, channelID) : yield call(listMessageByConversation, conversationID);
      if (response.data.message) {
        yield put(messageError(response.data.message));
      } else {
        yield put(loadMessageSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showMessageMessage(err.response ? err.response.data : err));
    }
  });
}

export function* createMessage() {
  yield takeEvery(ADD_MESSAGE, function* ({ payload }) {
    const { data } = payload;
    try {
      yield put(addMessageSuccess(data));
    } catch (err) {
      yield put(showMessageMessage(err));
    }
  });
}

function* onNewMessage() {
  // here
}

export default function* rootSaga() {
  yield all([fork(listMessage), fork(createMessage)]);
}
