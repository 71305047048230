export const CLEAR_SUCCESS_MESSAGE = 'CLEAR_SUCCESS_MESSAGE';
export const SHOW_MESSAGE_ERROR = 'SHOW_MESSAGE_ERROR';
export const CLEAR_MESSAGE_ERROR = 'CLEAR_MESSAGE_ERROR';
export const CHARGEMENT = 'CHARGEMENT';
export const SET_ACTION = 'SET_ACTION';
export const DROP_ACTION = 'DROP_ACTION';
export const IGNORE_ALL_NOTIFICATION = 'IGNORE_ALL_NOTIFICATION';
export const IGNORE_ONE_NOTIFICATION = 'IGNORE_ONE_NOTIFICATION';

export const VERIFY_SESSION = 'VERIFY_SESSION'
export const VERIFY_SESSION_SUCCESS = 'VERIFY_SESSION_SUCCESS'

export const DELETE_SESSION = 'DELETE_SESSION'
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS'

export const CREATE_SESSION = 'CREATE_SESSION'
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS'

export const CREATE_CONNEXION = 'CREATE_CONNEXION';
export const CREATE_CONNEXION_SUCCESS = 'CREATE_CONNEXION_SUCCESS';

export const LIST_CONNEXION = 'LIST_CONNEXION';
export const LIST_CONNEXION_SUCCESS = 'LIST_CONNEXION_SUCCESS';

export const RETRIEVE_CONNEXION = 'RETRIEVE_CONNEXION';
export const RETRIEVE_CONNEXION_SUCCESS = 'RETRIEVE_CONNEXION_SUCCESS';

export const UPDATE_CONNEXION = 'UPDATE_CONNEXION';
export const UPDATE_CONNEXION_SUCCESS = 'UPDATE_CONNEXION_SUCCESS';

export const DELETE_CONNEXION = 'DELETE_CONNEXION';
export const DELETE_CONNEXION_SUCCESS = 'DELETE_CONNEXION_SUCCESS';

export const TOKEN_OBTAIN_PAIR = 'TOKEN_OBTAIN_PAIR';
export const TOKEN_OBTAIN_PAIR_SUCCESS = 'TOKEN_OBTAIN_PAIR_SUCCESS';

export const TOKEN_REFRESH = 'TOKEN_REFRESH';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';

export const TOKEN_REJECT = 'TOKEN_REJECT';
export const TOKEN_REJECT_SUCCESS = 'TOKEN_REJECT_SUCCESS';

export const RETRY_WITH_TOKEN_REFRESH = 'RETRY_WITH_TOKEN_REFRESH';
export const RETRY_WITH_TOKEN_REFRESH_SUCCESS = 'RETRY_WITH_TOKEN_REFRESH_SUCCESS';

export const SYNCHRONIZE_EMAIL = 'SYNCHRONIZE_EMAIL';
export const SYNCHRONIZE_ALL_EMAIL_SUCCESS = 'SYNCHRONIZE_ALL_EMAIL_SUCCESS';

export const SYNCHRONIZE_EMAIL_SUCCESS = 'SYNCHRONIZE_EMAIL_SUCCESS';

export const UNSYNCHRONIZE_EMAIL = 'UNSYNCHRONIZE_EMAIL';

export const UPDATE_LOCAL_DATA = 'UPDATE_LOCAL_DATA';
export const UPDATE_LOCAL_DATA_SUCCESS = 'UPDATE_LOCAL_DATA_SUCCESS';

export const EMPTY_TRASH = 'EMPTY_TRASH';
export const EMPTY_TRASH_SUCCESS = 'EMPTY_TRASH_SUCCESS';

export const ALL_MAILBOX = 'ALL_MAILBOX';
export const ALL_MAILBOX_SUCCESS = 'ALL_MAILBOX_SUCCESS';

export const ALL_EMAILS_OF_MAILBOX = 'ALL_EMAILS_OF_MAILBOX';
export const ALL_EMAILS_OF_MAILBOX_SUCCESS = 'ALL_EMAILS_OF_MAILBOX_SUCCESS';

export const RETREIVE_EMAIL = 'RETREIVE_EMAILS';
export const RETREIVE_EMAIL_SUCCESS = 'RETREIVE_EMAIL_SUCCESS';

export const DROP_SELECTED = "DROP_SELECTED";

// CONTACT
export const LIST_CONTACT= "LIST_CONTACT";
export const LIST_CONTACT_SUCCESS= "LIST_CONTACT_SUCCESS";

export const UPDATE_CONTACT= "UPDATE_CONTACT";
export const UPDATE_CONTACT_SUCCESS= "UPDATE_CONTACT_SUCCESS";

export const CREATE_CONTACT= "CREATE_CONTACT";
export const CREATE_CONTACT_SUCCESS= "CREATE_CONTACT_SUCCESS";

export const DELETE_CONTACT= "DELETE_CONTACT";
export const DELETE_CONTACT_SUCCESS= "DELETE_CONTACT_SUCCESS";

// END CONTACT

export const DELETE_EMAIL = 'DELETE_EMAIL';
export const DELETE_EMAIL_SUCCESS = 'DELETE_EMAIL_SUCCESS';

export const SPAM_EMAIL = 'SPAM_EMAIL';
export const SPAM_EMAIL_SUCCESS = 'SPAM_EMAIL_SUCCESS';

export const UNSPAM_EMAIL = 'UNSPAM_EMAIL';
export const UNSPAM_EMAIL_SUCCESS = 'UNSPAM_EMAIL_SUCCESS';

export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';

export const EMAIL_ATTACHMENT = 'EMAIL_ATTACHMENT';
export const EMAIL_ATTACHMENT_SUCCESS = 'EMAIL_ATTACHMENT_SUCCESS';

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';

export const DRAFT_EMAIL = 'DRAFT_EMAIL';
export const DRAFT_EMAIL_SUCCESS = 'DRAFT_EMAIL_SUCCESS';

export const CHECK_INBOX = 'CHECK_INBOX';
export const CHECK_INBOX_SUCCESS = 'CHECK_INBOX_SUCCESS';

export const LIST_MODEL_SIGNATURE = 'LIST_MODEL_SIGNATURE';
export const LIST_MODEL_SIGNATURE_SUCCESS = 'LIST_MODEL_SIGNATURE_SUCCESS';

export const CREATE_SIGNATURE_VIA_MODEL = 'CREATE_SIGNATURE_VIA_MODEL';
export const CREATE_SIGNATURE_VIA_MODEL_SUCCESS = 'CREATE_SIGNATURE_VIA_MODEL_SUCCESS';

export const CREATE_SIGNATURE = 'CREATE_SIGNATURE';
export const CREATE_SIGNATURE_SUCCESS = 'CREATE_SIGNATURE_SUCCESS';

export const LIST_SIGNATURE = 'LIST_SIGNATURE';
export const LIST_SIGNATURE_SUCCESS = 'LIST_SIGNATURE_SUCCESS';

export const DELETE_SIGNATURE = 'DELETE_SIGNATURE';
export const DELETE_SIGNATURE_SUCCESS = 'DELETE_SIGNATURE_SUCCESS';

export const RETREIVE_SIGNATURE = 'RETREIVE_SIGNATURE';

export const ACTIVATE_RH = 'ACTIVATE_RH';
export const DEACTIVATE_RH = 'DEACTIVATE_RH';
export const ACTIVATE_RH_SUCCESS = 'ACTIVATE_RH_SUCCESS';
export const DEACTIVATE_RH_SUCCESS = 'DEACTIVATE_RH_SUCCESS';