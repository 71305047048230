import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Workspaces from './manage-project/workspace';
import Sprint from './manage-project/sprint';
import ListSprint from './manage-project/listSprint';
import Projects from './manage-project/project';
import Task from './manage-project/task';
import List from './manage-project/list';
import Agenda from './manage-project/agenda';
import Comment from './manage-project/comment';
import Role from './manage-project/role';
import Channel from './chat/channel';
import Message from './chat/message';
import Tracking from './tracking/tracking';
import Checklist from './manage-project/checklist';
import Mail from './mail/mail';
import Timer from './timer/timer';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Workspaces(),
    Projects(),
    Task(),
    List(),
    Sprint(),
    ListSprint(),
    Agenda(),
    Comment(),
    Role(),
    Channel(),
    Message(),
    Tracking(),
    Checklist(),
    Timer(),
    Mail(),
  ]);
}
