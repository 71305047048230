import axios from 'axios';
import { API_BASE_URL } from 'constants/ApiConstant';

const url = API_BASE_URL + 'project/';
const urlLabel = API_BASE_URL + 'label/';

export const created = (data) => {
  return axios.post(`${url}`, data);
};

// CREATE LABEL
export const createdLabelRequest = (data) => {
  return axios.post(`${urlLabel}`, data);
};

export const updated = (id, data) => {
  return axios.put(`${url}${id}`, data);
};

export const deleted = (id) => {
  return axios.delete(`${url}${id}`);
};

export const loaded = () => {
  return axios.get(`${url}`);
};

export const loadProjectByOwner = (id) => {
  return axios.get(`${url}owner/${id}`);
};

export const loadProjectByWorkspace = (id) => {
  return axios.get(`${url}workspace/${id}`);
};

export const loadByID = (id) => {
  return axios.get(`${url}${id}`);
};

export const allUsers = (id) => {
  return axios.get(`${url}${id}/users`);
};

export const allLabels = (id) => {
  return axios.get(`${urlLabel}${id}`);
}

export const deletedLabelRequest = (id) => {
  return axios.delete(`${urlLabel}${id}`);
};
