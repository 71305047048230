import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  createdCommentSuccess,
  deleteCommentSuccess,
  loadCommentSuccess,
  showCommentMessage,
  updateCommentSuccess,
} from 'redux/actions/manage-project/comment';
import {
  CREATE_COMMENT,
  DELETE_COMMENT,
  LOAD_COMMENT,
  UPDATE_COMMENT,
} from 'redux/constants/manage-project/comment.type';
import { created, deleted, loaded, updated } from 'services/CommentService';

export function* add() {
  yield takeEvery(CREATE_COMMENT, function* ({ payload }) {
    const { comment } = payload;
    try {
      const response = yield call(created, comment);
      if (response.data.message) {
        yield put(showCommentMessage(response.data.message));
      } else {
        yield put(createdCommentSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showCommentMessage(err.response ? err.response.data : err));
    }
  });
}

export function* update() {
  yield takeEvery(UPDATE_COMMENT, function* ({ payload }) {
    const { comment, commentID } = payload;
    try {
      const response = yield call(updated, commentID, comment);
      if (response.data.message) {
        yield put(showCommentMessage(response.data.message));
      } else {
        yield put(updateCommentSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showCommentMessage(err.response ? err.response.data : err));
    }
  });
}

export function* del() {
  yield takeEvery(DELETE_COMMENT, function* ({ payload }) {
    try {
      const response = yield call(deleted, payload);
      if (response.data.message) {
        yield put(showCommentMessage(response.data.message));
      } else {
        yield put(deleteCommentSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showCommentMessage(err.response ? err.response.data : err));
    }
  });
}

export function* byTask() {
  yield takeEvery(LOAD_COMMENT, function* ({ payload }) {
    const { taskID } = payload;
    try {
      const response = yield call(loaded, taskID);
      if (response.data.message) {
        yield put(showCommentMessage(response.data.message));
      } else {
        yield put(loadCommentSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showCommentMessage(err.response ? err.response.data : err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(add), fork(update), fork(del), fork(byTask)]);
}
