import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  createdSprintSuccess,
  deleteSprintSuccess,
  loadSprintByProjectSuccess,
  loadSprintSuccess,
  showSprintMessage,
  updateSprintSuccess,
} from "redux/actions/manage-project/sprint";
import {
  CREATE_SPRINT,
  DELETE_SPRINT,
  LOAD_BY_PROJECT_SPRINT,
  LOAD_SPRINT,
  UPDATE_SPRINT,
} from "redux/constants/manage-project/sprint.type";

import {
  created,
  deleted,
  loadByproject,
  loaded,
  updated,
} from "services/SprintService";

export function* add() {
  yield takeEvery(CREATE_SPRINT, function* ({ payload }) {
    const { sprint } = payload;
    try {
      const response = yield call(created, sprint);
      if (response.data.message) {
        yield put(showSprintMessage(response.data.message));
      } else {
        yield put(createdSprintSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showSprintMessage(err.response ? err.response.data : err));
    }
  });
}

export function* allSprint() {
  yield takeEvery(LOAD_SPRINT, function* () {
    try {
      const response = yield call(loaded);
      if (response.data.message) {
        yield put(showSprintMessage(response.data.message));
      } else {
        yield put(loadSprintSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showSprintMessage(err.response ? err.response.data : err));
    }
  });
}

export function* update() {
  yield takeEvery(UPDATE_SPRINT, function* ({ payload }) {
    const { sprint, sprintID } = payload;
    try {
      const response = yield call(updated, sprintID, sprint);
      if (response.data.message) {
        yield put(showSprintMessage(response.data.message));
      } else {
        yield put(updateSprintSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showSprintMessage(err.response ? err.response.data : err));
    }
  });
}

export function* del() {
  yield takeEvery(DELETE_SPRINT, function* ({ payload }) {
    try {
      const response = yield call(deleted, payload);
      if (response.data.message) {
        yield put(showSprintMessage(response.data.message));
      } else {
        yield put(deleteSprintSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showSprintMessage(err.response ? err.response.data : err));
    }
  });
}

export function* byProject() {
  yield takeEvery(LOAD_BY_PROJECT_SPRINT, function* ({ payload }) {
    const { projectID } = payload;
    try {
      const response = yield call(loadByproject, projectID);
      if (response.data.message) {
        yield put(showSprintMessage(response.data.message));
      } else {
        yield put(loadSprintByProjectSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showSprintMessage(err.response ? err.response.data : err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(add),
    fork(update),
    fork(del),
    fork(byProject),
    fork(allSprint),
  ]);
}
