export const LOAD_TASK = "LOAD_TASK";
export const CREATE_TASK = "CREATE_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const LOAD_TASK_BY_LIST = "LOAD_TASK_BY_LIST";
export const TASK_ERROR = "TASK_ERROR";
export const TASK_MESSAGE = "TASK_MESSAGE";

export const LOAD_TASK_SUCCESS = "LOAD_TASK_SUCCESS";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const DRAG_TASK_SUCCESS = "DRAG_TASK_SUCCESS";
export const DRAG_TASK = "DRAG_TASK";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const LOAD_TASK_BY_LIST_SUCCESS = "LOAD_TASK_BY_LIST_SUCCESS";

export const DELETE_CURRENT_TASK = "DELETE_CURRENT_TASK";
export const DELETE_CURRENT_TASK_SUCCESS = "DELETE_CURRENT_TASK_SUCCESS";

export const SET_MEMBERS = "SET_MEMBERS";
export const SET_MEMBERS_SUCCESS = "SET_MEMBERS_SUCCESS";

export const SET_LABELS = "SET_LABELS";
export const SET_LABELS_SUCCESS = "SET_LABELS_SUCCESS";

export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_DESCRIPTION_SUCCESS = "SET_DESCRIPTION_SUCCESS";

export const SET_STORYPOINT = "SET_STORYPOINT";
export const SET_STORYPOINT_SUCCESS = "SET_STORYPOINT_SUCCESS";

export const LOAD_ALL_TASK = "LOAD_ALL_TASK"
export const LOAD_ALL_TASK_SUCCESS = "LOAD_ALL_TASK_SUCCESS"
