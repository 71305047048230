import { API_TIMER_URL } from 'constants/ApiConstant';
import io from 'socket.io-client';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  addNoteTimerAction,
  addTimerAction,
  updateTimerAction,
} from 'redux/actions/timer';

export const SocketTimerContext = React.createContext();

const timerSocket = io(API_TIMER_URL, {
  reconnectionAttempts: 4,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 1000,
  timeout: 80000,
});

const formatDuration = (duration, showStatus = false) => {
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / 60000) % 60);
  const hours = Math.floor(duration / 3600000);

  if (hours > 0) {
    return showStatus
      ? `${hours}h ${minutes}m`
      : `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes > 0) {
    return showStatus ? `${minutes}m` : `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
};

const TimerProvider = ({ children, current }) => {
  const dispatch = useDispatch();
  const [waitTimer, setWaitTimer] = useState(false);

  useEffect(() => {
    return () => {
      timerSocket.off('TIMER_CREATED');
      timerSocket.off('TIMER_STARTED');
      timerSocket.off('TIMER_PLAYED');
      timerSocket.off('TIMER_PAUSED');
      timerSocket.off('TIMER_STOPPED');
      timerSocket.off('TIMER_NOTE_ADDED');
    };
  }, []);

  useEffect(() => {
    if (current) {
      // setTask(currentTask);
      timerSocket.on('TIMER_CREATED', (data) => {
        dispatch(addTimerAction(data.payload));
        setWaitTimer(false);
      });
      timerSocket.on('TIMER_STARTED', (data) => {
        dispatch(addTimerAction(data.payload));
        if (data.payload.userId === current._id) {
          setWaitTimer(false);
        }
      });
      timerSocket.on('TIMER_PLAYED', (data) => {
        dispatch(updateTimerAction(data.payload));
        if (data.payload.userId === current._id) {
          setWaitTimer(false);
        }
      });
      timerSocket.on('TIMER_PAUSED', (data) => {
        dispatch(updateTimerAction(data.payload));
        if (data.payload.userId === current._id) {
          setWaitTimer(false);
        }
      });
      timerSocket.on('TIMER_STOPPED', (data) => {
        dispatch(updateTimerAction(data.payload));
        setWaitTimer(false);
      });
      timerSocket.on('TIMER_NOTE_ADDED', (data) => {
        dispatch(addNoteTimerAction(data.payload));
      });
    }
  }, [timerSocket, current]);

  return (
    <SocketTimerContext.Provider
      value={{ timerSocket, waitTimer, setWaitTimer, formatDuration }}
    >
      {children}
    </SocketTimerContext.Provider>
  );
};

const mapStateToProps = ({ auth }) => {
  const { current } = auth;
  return { current };
};

export default connect(mapStateToProps)(TimerProvider);
