import {
  CREATE_SPRINT_SUCCESS,
  DELETE_SPRINT_SUCCESS,
  SPRINT_MESSAGE,
  LOAD_BY_PROJECT_SPRINT_SUCCESS,
  LOAD_SPRINT_SUCCESS,
  UPDATE_SPRINT_SUCCESS,
  CREATE_TASK_IN_SPRINT_SUCCESS,
  UPDATE_TASK_IN_SPRINT_SUCCESS,
  DELETE_TASK_IN_SPRINT_SUCCESS,
} from 'redux/constants/manage-project/sprint.type';

const initialState = {
  data: [],
  loading: false,
  message: '',
};

function SprintReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SPRINT_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case CREATE_SPRINT_SUCCESS:
      return { ...state, data: [action.payload, ...state.data] };
    case UPDATE_SPRINT_SUCCESS:
      const updated = state.data.map((s) => {
        if (s._id === action.payload._id) return action.payload;
        return s;
      });
      return { ...state, loading: false, data: updated };
    case CREATE_TASK_IN_SPRINT_SUCCESS:
      state = {
        ...state,
        data: state.data.map((sprint) => {
          if (sprint._id === action.payload.sprintId) {
            return { ...sprint, tasks: [...sprint.tasks, action.payload.task] };
          }
          return sprint;
        }),
      };
      return state;
    case UPDATE_TASK_IN_SPRINT_SUCCESS:
      state = {
        ...state,
        data: state.data.map((sprint) => {
          if (sprint._id === action.payload.sprintId) {
            return {
              ...sprint,
              tasks: [
                ...sprint.tasks.map((task) =>
                  task._id === action.payload.task._id
                    ? action.payload.task
                    : task
                ),
              ],
            };
          }
          return sprint;
        }),
      };
      return state;
    case DELETE_TASK_IN_SPRINT_SUCCESS:
      state = state = {
        ...state,
        data: state.data.map((sprint) => {
          if (sprint._id === action.payload.sprintId) {
            return {
              ...sprint,
              tasks: sprint.tasks.filter(
                (task) => task._id !== action.payload.task._id
              ),
            };
          }
          return sprint;
        }),
      };
      return state;
    case DELETE_SPRINT_SUCCESS:
      const deleted = state.data.filter((s) => s._id !== action.payload._id);
      return { ...state, loading: false, data: deleted };
    case LOAD_BY_PROJECT_SPRINT_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case SPRINT_MESSAGE:
      return {
        ...state,
        message: action.message,
        loading: false,
      };
    default:
      return state;
  }
}

export default SprintReducer;
