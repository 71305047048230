export function isAccessTokenExpiredError(error) {
  if (
    error.response &&
    error.response.data &&
    error.response.data.code === 'token_not_valid'
  ) {
    return true;
  }
  return false;
}

export function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

export function formatDateLarge(inputDate) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = new Date(inputDate);
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  const formattedDate = `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

export function validerAdresseEmail(email) {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(email);
}

export function filtrerContacts(contacts) {
  const emailCounts = {};
  const filteredContacts = [];

  for (const contact of contacts) {
    const { mail, name } = contact;
    if (emailCounts[mail]) {
      if (name !== 'Unknown') {
        filteredContacts.push(contact);
      }
    } else {
      filteredContacts.push(contact);
    }
    emailCounts[mail] = (emailCounts[mail] || 0) + 1;
  }

  return filteredContacts;
}

export function queryStringToJson(queryString) {
  const params = new URLSearchParams(queryString);
  const json = {};

  for (const [key, value] of params) {
    json[key] = value;
  }

  return json;
}

export function combineDateAndTime(dateStr, timeStr) {
  const timeParts = timeStr.match(/(\d+):(\d+) (\w+)/);
  const date = new Date(dateStr);

  if (timeParts) {
    let hours = parseInt(timeParts[1], 10);
    const minutes = parseInt(timeParts[2], 10);
    const ampm = timeParts[3];

    if (ampm === 'PM' && hours < 12) {
      hours += 12;
    }
    if (ampm === 'AM' && hours === 12) {
      hours = 0;
    }

    date.setHours(hours, minutes, 0, 0);
  }

  return date;
}

export function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  if (seconds == 60) {
    minutes++;
    seconds = '00';
  }
  return `${minutes} minutes et ${seconds} seconds`;
}

export function formatTimeLeft(endDate, locale = 'fr-FR') {
  const now = new Date();
  const diff = new Date(endDate).getTime() - now.getTime();

  if (diff <= 0) {
    return 0;
  }

  const seconds = Math.floor(diff / 1000) % 60;
  const minutes = Math.floor(diff / (1000 * 60)) % 60;
  const hours = Math.floor(diff / (1000 * 60 * 60)) % 24;
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  const timeUnits = {
    'fr-FR': {
      month: 'mois',
      day: 'jour',
      hour: 'heure',
      minute: 'minute',
      second: 's',
    },
    'en-US': {
      month: 'month',
      day: 'day',
      hour: 'hour',
      minute: 'minute',
      second: 's',
    },
  };

  const units = timeUnits[locale] || timeUnits['fr-FR'];

  let formattedTime = '';

  if (days > 30) {
    formattedTime += `${days} ${units.month} restant${days > 1 ? 's' : ''}`;
  } else if (days > 0) {
    formattedTime += `${days} ${units.day}${days > 1 ? 's' : ''} restant${
      days > 1 ? 's' : ''
    }`;
  } else if (hours > 0) {
    formattedTime += `${hours} ${units.hour}${hours > 1 ? 's' : ''} restant${
      hours > 1 ? 's' : ''
    }`;
  } else {
    formattedTime += `${minutes} ${units.minute} restant${
      minutes > 1 ? 's' : ''
    }`;
  }

  return formattedTime.trim();
}
