import {
  CHECKLIST_ERROR,
  CHECKLIST_MESSAGE,
  CREATE_CHECKLIST,
  CREATE_CHECKLIST_SUCCESS,
  DELETE_CHECKLIST,
  DELETE_CHECKLIST_SUCCESS,
  LOAD_CHECKLIST_BY_TASK,
  LOAD_CHECKLIST_BY_TASK_SUCCESS,
  UPDATE_CHECKLIST,
  UPDATE_CHECKLIST_SUCCESS,
} from 'redux/constants/manage-project/checklist.type';

export const loadChecklistByTask = (taskID) => {
  return {
    type: LOAD_CHECKLIST_BY_TASK,
    payload: { taskID },
  };
};

export const createChecklist = (checklist) => {
  return {
    type: CREATE_CHECKLIST,
    payload: { checklist },
  };
};

export const updateChecklist = (checklistID, checklist) => {
  return {
    type: UPDATE_CHECKLIST,
    payload: {
      checklist,
      checklistID,
    },
  };
};

export const deleteChecklist = (checklistID) => {
  return {
    type: DELETE_CHECKLIST,
    payload: checklistID,
  };
};

export const loadChecklistByTaskSuccess = (data) => {
  return {
    type: LOAD_CHECKLIST_BY_TASK_SUCCESS,
    payload: data,
  };
};

export const deleteChecklistSuccess = (deleted) => {
  return {
    type: DELETE_CHECKLIST_SUCCESS,
    payload: deleted,
  };
};

export const updateChecklistSuccess = (updated) => {
  return {
    type: UPDATE_CHECKLIST_SUCCESS,
    payload: updated,
  };
};

export const createdChecklistSuccess = (created) => {
  return {
    type: CREATE_CHECKLIST_SUCCESS,
    payload: created,
  };
};

export const checklistError = (msg) => {
  return {
    type: CHECKLIST_ERROR,
    payload: msg,
  };
};

export const showChecklistMessage = (message) => {
  return {
    type: CHECKLIST_MESSAGE,
    message,
  };
};
