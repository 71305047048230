import {
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_SUCCESS,
} from 'redux/constants/user/auth.type';
import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  LOAD_ALL_USER,
  LOAD_ALL_USER_SUCCESS,
} from '../constants/Auth';

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user,
  };
};

export const loadAllUser = () => {
  return {
    type: LOAD_ALL_USER,
  };
};

export const loadAllUserSuccess = (user) => {
  return {
    type: LOAD_ALL_USER_SUCCESS,
    payload: user,
  };
};

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token,
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT,
  };
};

export const currentUser = (token) => {
  return {
    type: VALIDATE_TOKEN,
    payload: token,
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const validateSuccess = (user) => {
  return {
    type: VALIDATE_TOKEN_SUCCESS,
    payload: user,
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user,
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
