import {
    LIST_SPRINT_MESSAGE,
    LOAD_LIST_SPRINT,
    LOAD_LIST_SPRINT_SUCCESS,
    ADD_TASK_TO_LIST_SPRINT,
    DELETE_TASK_IN_LIST_SPRINT_SUCCESS,
    DRAG_TASK_IN_LIST_SPRINT,
  } from "redux/constants/manage-project/listSprint.type";
  
  export const loadListSprint = () => {
    return {
      type: LOAD_LIST_SPRINT,
    };
  };

  export const loadListSprintSuccess = (listSprint) => {
    return {
      type: LOAD_LIST_SPRINT_SUCCESS,
      payload: listSprint,
    };
  };

  export const addTaskToListSprint = (task) => {
    return {
      type: ADD_TASK_TO_LIST_SPRINT,
      payload: task,
    };
  };

  export const deleteTaskInListSprintSuccess = ({task, sprintId}) => {
    return {
      type: DELETE_TASK_IN_LIST_SPRINT_SUCCESS,
      payload: {task, sprintId},
    };
  };
 
  export const showListSprintMessage = (message) => {
    return {
      type: LIST_SPRINT_MESSAGE,
      message,
    };
  };

  export const dragTaskInListSprint = (task,tasksInSource,tasksInDestination,sourceId,destinationId) => {
    return {
      type: DRAG_TASK_IN_LIST_SPRINT,
      payload: {task, tasksInSource, tasksInDestination, sourceId, destinationId},
    };
  };
