import {
  CREATE_TASK,
  CREATE_TASK_SUCCESS,
  DELETE_CURRENT_TASK,
  DELETE_CURRENT_TASK_SUCCESS,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DRAG_TASK,
  DRAG_TASK_SUCCESS,
  LOAD_TASK_BY_LIST,
  LOAD_TASK_BY_LIST_SUCCESS,
  SET_LABELS,
  SET_LABELS_SUCCESS,
  SET_MEMBERS,
  SET_MEMBERS_SUCCESS,
  TASK_ERROR,
  TASK_MESSAGE,
  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  SET_DESCRIPTION,
  SET_DESCRIPTION_SUCCESS,
  SET_STORYPOINT,
  SET_STORYPOINT_SUCCESS,
  LOAD_ALL_TASK,
  LOAD_ALL_TASK_SUCCESS
} from "redux/constants/manage-project/task.type";

export const loadTask = (listID) => {
  return {
    type: LOAD_TASK_BY_LIST,
    payload: { listID },
  };
};

export const loadAllTask = () => {
  return {
    type: LOAD_ALL_TASK,
    // payload: { projectId },
  };
};

export const loadAllTaskSuccess = (payload)=>{
  return {
    type: LOAD_ALL_TASK_SUCCESS,
    payload: payload,
  };
}

export const createTask = (task, sprintId) => {
  return {
    type: CREATE_TASK,
    payload: { task , sprintId},
  };
};

export const dragTask = (task) => {
  return {
    type: DRAG_TASK,
    payload: task,
  };
};

export const updateTask = (taskID, task, sprintId) => {
  return {
    type: UPDATE_TASK,
    payload: {
      task,
      taskID,
      sprintId
    },
  };
};

export const setMembers = (taskID, members, sprintId) => {
  return {
    type: SET_MEMBERS,
    payload: {
      members,
      taskID,
      sprintId
    },
  };
};

export const setLabels = (taskID, labels, sprintId) => {
  return {
    type: SET_LABELS,
    payload: {
      labels,
      taskID,
      sprintId
    },
  };
};

export const setDescription = (taskID, description, sprintId) => {
  return {
    type: SET_DESCRIPTION,
    payload: {
      description,
      taskID,
      sprintId,
    },
  };
};

export const setStoryPoint = (taskID, storyPoint, sprintId) => {
  return {
    type: SET_STORYPOINT,
    payload: {
      storyPoint,
      taskID,
      sprintId,
    },
  };
};

export const deleteTask = (taskID,sprintId) => {
  return {
    type: DELETE_TASK,
    payload: { taskID,sprintId },
  };
};

export const loadTaskSuccess = (tasks) => {
  return {
    type: LOAD_TASK_BY_LIST_SUCCESS,
    payload: tasks,
  };
};

export const deleteCurrentTask = () => {
  return {
    type: DELETE_CURRENT_TASK,
    payload: null,
  };
};

export const deleteCurrentTaskSuccess = () => {
  return {
    type: DELETE_CURRENT_TASK_SUCCESS,
  };
};

export const deleteTaskSuccess = (deleted) => {
  return {
    type: DELETE_TASK_SUCCESS,
    payload: deleted,
  };
};

export const updateTaskSuccess = (updated) => {
  return {
    type: UPDATE_TASK_SUCCESS,
    payload: updated,
  };
};

export const setMembersSuccess = (task) => {
  return {
    type: SET_MEMBERS_SUCCESS,
    payload: task,
  };
};

export const setLabelsSuccess = (task) => {
  return {
    type: SET_LABELS_SUCCESS,
    payload: task,
  };
};

export const setDescriptionSuccess = (task) => {
  return {
    type: SET_DESCRIPTION_SUCCESS,
    payload: task,
  };
};

export const setStoryPointSuccess = (task) => {
  return {
    type: SET_STORYPOINT_SUCCESS,
    payload: task,
  };
};

export const dragTaskSuccess = (updated) => {
  return {
    type: DRAG_TASK_SUCCESS,
    payload: updated,
  };
};

export const createdTaskSuccess = (created) => {
  return {
    type: CREATE_TASK_SUCCESS,
    payload: created,
  };
};

export const taskError = (msg) => {
  return {
    type: TASK_ERROR,
    payload: msg,
  };
};

export const showTaskMessage = (message) => {
  return {
    type: TASK_MESSAGE,
    message,
  };
};
