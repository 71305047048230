import {
  CREATE_LABEL,
  CREATE_LABEL_SUCCESS,
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CURRENT_PROJECT,
  CURRENT_PROJECT_SUCCESS,
  DELETE_LABEL,
  DELETE_LABEL_SUCCESS,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  LOAD_ALL_LABELS_OF_PROJECT,
  LOAD_ALL_LABELS_OF_PROJECT_SUCCESS,
  LOAD_ALL_USERS_OF_PROJECT,
  LOAD_ALL_USERS_OF_PROJECT_SUCCESS,
  LOAD_BY_OWNER,
  LOAD_BY_OWNER_SUCCESS,
  LOAD_BY_WORKSPACE_PROJECT,
  LOAD_BY_WORKSPACE_PROJECT_SUCCESS,
  BLANK_WORKSPACE,
  LOAD_PROJECT,
  LOAD_PROJECT_SUCCESS,
  PROJECT_ERROR,
  PROJECT_MESSAGE,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
} from 'redux/constants/manage-project/project.type';

export const loadProject = () => {
  return {
    type: LOAD_PROJECT,
  };
};

export const loadProjectByOwner = (userID) => {
  return {
    type: LOAD_BY_OWNER,
    payload: { userID },
  };
};

export const createProject = (project) => {
  return {
    type: CREATE_PROJECT,
    payload: { project },
  };
};

export const updateProject = (projectID, project) => {
  return {
    type: UPDATE_PROJECT,
    payload: {
      project,
      projectID,
    },
  };
};

export const deleteProject = (projectID) => {
  return {
    type: DELETE_PROJECT,
    payload: projectID,
  };
};

export const currentProject = (projectID) => {
  return {
    type: CURRENT_PROJECT,
    payload: projectID,
  };
};

export const allUsersProject = (projectID) => {
  return {
    type: LOAD_ALL_USERS_OF_PROJECT,
    payload: projectID,
  };
};

export const allUsersProjectSuccess = (members) => {
  return {
    type: LOAD_ALL_USERS_OF_PROJECT_SUCCESS,
    payload: {
      members: members,
    },
  };
};

export const allLabelsProject = (projectID) => {
  return {
    type: LOAD_ALL_LABELS_OF_PROJECT,
    payload: projectID,
  };
};

export const allLabelsProjectSuccess = (labels) => {
  return {
    type: LOAD_ALL_LABELS_OF_PROJECT_SUCCESS,
    payload: {
      labels: labels,
    },
  };
};

export const loadByWorkspace = (workspaceID) => {
  return {
    type: LOAD_BY_WORKSPACE_PROJECT,
    payload: { workspaceID },
  };
};

export const loadByWorkspaceSuccess = (projects) => {
  return {
    type: LOAD_BY_WORKSPACE_PROJECT_SUCCESS,
    payload: projects,
  };
};

export const blankWorkspace = () => {
  return {
    type: BLANK_WORKSPACE,
  };
};

export const loadProjectSuccess = (Projects) => {
  return {
    type: LOAD_PROJECT_SUCCESS,
    payload: Projects,
  };
};

export const loadProjectByOwnerSuccess = (data) => {
  return {
    type: LOAD_BY_OWNER_SUCCESS,
    payload: data,
  };
};
export const deleteProjectSuccess = (deleted) => {
  return {
    type: DELETE_PROJECT_SUCCESS,
    payload: deleted,
  };
};

export const updateProjectSuccess = (updated) => {
  return {
    type: UPDATE_PROJECT_SUCCESS,
    payload: updated,
  };
};

export const createdProjectSuccess = (created) => {
  return {
    type: CREATE_PROJECT_SUCCESS,
    payload: created,
  };
};

export const currentProjectSuccess = (data) => {
  return {
    type: CURRENT_PROJECT_SUCCESS,
    payload: data,
  };
};

export const projectError = (msg) => {
  return {
    type: PROJECT_ERROR,
    payload: msg,
  };
};

export const showProjectMessage = (message) => {
  return {
    type: PROJECT_MESSAGE,
    message,
  };
};

// LABEL
export const createLabel = (label) => {
  return {
    type: CREATE_LABEL,
    payload: label,
  };
};

// LABEL
export const createLabelSuccess = (label) => {
  return {
    type: CREATE_LABEL_SUCCESS,
    payload: { label: label },
  };
};

// DELETE LABEL
export const deleteLabel = (labelId) => {
  return {
    type: DELETE_LABEL,
    payload: labelId,
  };
};

// DELETE LABEL
export const deleteLabelSuccess = (labelId) => {
  return {
    type: DELETE_LABEL_SUCCESS,
    payload: { labelId: labelId },
  };
};
