import {
    CLEAR_SUCCESS_MESSAGE,

    CREATE_CONNEXION,
    CREATE_CONNEXION_SUCCESS,

    LIST_CONNEXION,
    LIST_CONNEXION_SUCCESS,

    UPDATE_CONNEXION,
    UPDATE_CONNEXION_SUCCESS,

    DELETE_CONNEXION,
    DELETE_CONNEXION_SUCCESS,

    TOKEN_OBTAIN_PAIR,
    TOKEN_OBTAIN_PAIR_SUCCESS,

    TOKEN_REFRESH_SUCCESS,
    TOKEN_REFRESH,

    TOKEN_REJECT,
    TOKEN_REJECT_SUCCESS,

    SYNCHRONIZE_EMAIL,
    SYNCHRONIZE_EMAIL_SUCCESS,

    ALL_MAILBOX,
    ALL_MAILBOX_SUCCESS,

    ALL_EMAILS_OF_MAILBOX,
    ALL_EMAILS_OF_MAILBOX_SUCCESS,

    RETRY_WITH_TOKEN_REFRESH,
    RETRY_WITH_TOKEN_REFRESH_SUCCESS,

    RETREIVE_EMAIL,
    RETREIVE_EMAIL_SUCCESS,

    DELETE_EMAIL,
    DELETE_EMAIL_SUCCESS,

    EMAIL_ATTACHMENT,
    EMAIL_ATTACHMENT_SUCCESS,

    SEND_EMAIL,
    SEND_EMAIL_SUCCESS,

    DRAFT_EMAIL,
    DRAFT_EMAIL_SUCCESS,

    CHECK_INBOX,
    CHECK_INBOX_SUCCESS,

    CREATE_SIGNATURE_VIA_MODEL,
    CREATE_SIGNATURE_VIA_MODEL_SUCCESS,
    VERIFY_SESSION,
    CREATE_SESSION,
    DELETE_SESSION,
    VERIFY_SESSION_SUCCESS,
    CREATE_SESSION_SUCCESS,
    DELETE_SESSION_SUCCESS,
    CHARGEMENT,
    EMPTY_TRASH,
    EMPTY_TRASH_SUCCESS,
    UPDATE_LOCAL_DATA,
    UPDATE_LOCAL_DATA_SUCCESS,
    SYNCHRONIZE_ALL_EMAIL_SUCCESS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_SUCCESS,
    RETRIEVE_CONNEXION,
    RETRIEVE_CONNEXION_SUCCESS,
    DROP_SELECTED,
    LIST_CONTACT,
    LIST_CONTACT_SUCCESS,
    CREATE_CONTACT,
    CREATE_CONTACT_SUCCESS,
    UPDATE_CONTACT,
    UPDATE_CONTACT_SUCCESS,
    DELETE_CONTACT,
    DELETE_CONTACT_SUCCESS,
    SHOW_MESSAGE_ERROR,
    LIST_MODEL_SIGNATURE,
    LIST_MODEL_SIGNATURE_SUCCESS,
    CREATE_SIGNATURE,
    CREATE_SIGNATURE_SUCCESS,
    LIST_SIGNATURE,
    LIST_SIGNATURE_SUCCESS,
    DELETE_SIGNATURE,
    DELETE_SIGNATURE_SUCCESS,
    CLEAR_MESSAGE_ERROR,
    SET_ACTION,
    DROP_ACTION,
    SPAM_EMAIL,
    SPAM_EMAIL_SUCCESS,
    UNSPAM_EMAIL,
    UNSPAM_EMAIL_SUCCESS,
    IGNORE_ALL_NOTIFICATION,
    IGNORE_ONE_NOTIFICATION,
    ACTIVATE_RH,
    DEACTIVATE_RH,
    ACTIVATE_RH_SUCCESS,
    DEACTIVATE_RH_SUCCESS
} from 'redux/constants/mail/mail.type';

// CLEAR SUCCESS MESSAGE
export const clearSuccessMessage = () => {
    return {
      type: CLEAR_SUCCESS_MESSAGE,
    };
};

// SHOW MESSAGE ERROR
export const showMessageError = (error) => {
    return {
      type: SHOW_MESSAGE_ERROR,
      payload: {error}
    };
};

// SHOW MESSAGE ERROR
export const clearErrorMessage = () => {
    return {
      type: CLEAR_MESSAGE_ERROR
    };
};

// CLEAR SUCCESS MESSAGE
export const chargement = () => {
    return {
      type: CHARGEMENT,
    };
};

export const setAction = (act) => {
    return {
        type: SET_ACTION,
        payload: { act },
    };
};

export const dropAction = () => {
    return {
        type: DROP_ACTION
    }
}

export const ignoreAllNotification = ()=>{
    return {
        type: IGNORE_ALL_NOTIFICATION
    }
}

export const ignoreOneNotification = (key) => {
    return {
        type: IGNORE_ONE_NOTIFICATION,
        payload: {
            key
        }
    }
}

// CREATE CONNEXION
export const createConnexion = (connexion) => {
    return {
        type: CREATE_CONNEXION,
        payload: {
            connexion
        },
    };
};

export const createConnexionSuccess = (newConnexion, successMessage) => {
    return {
        type: CREATE_CONNEXION_SUCCESS,
        payload: { newConnexion, successMessage },
    };
};

// UPDATE CONNEXION
export const updateConnexion = (user_id, connexion_id, connexion) => {
    return {
        type: UPDATE_CONNEXION,
        payload: { user_id, connexion_id, connexion }
    };
};

export const updateConnexionSuccess = (updatedConnexion, updateMessage) => {
    return {
        type: UPDATE_CONNEXION_SUCCESS,
        payload: { updatedConnexion, updateMessage }
    };
};

// DELETE CONNEXION
export const deleteConnexion = (user_id, connexion_id) => {
    return {
        type: DELETE_CONNEXION,
        payload: { user_id, connexion_id },
    };
};

export const deleteConnexionSuccess = (deletedConnexionId, deleteMessage) => {
    return {
        type: DELETE_CONNEXION_SUCCESS,
        payload: { deletedConnexionId, deleteMessage },
    };
};

// LIST CONNEXION
export const listConnexion = (userID) => ({
    type: LIST_CONNEXION,
    payload: { userID }
});
  
export const listConnexionSuccess = (data) => ({
    type: LIST_CONNEXION_SUCCESS,
    payload: data
});

// TOKEN OBTAIN ACTIONS
export const tokenObtainPair = (auth, access) => {
    return {
        type: TOKEN_OBTAIN_PAIR,
        payload: { auth, access },
    };
};

export const tokenObtainPairSuccess = (token, message) => {
    return {
        type: TOKEN_OBTAIN_PAIR_SUCCESS,
        payload: { token, message },
    };
};

// TOKEN REFRESH
export const refreshToken = (refresh) => ({
    type: TOKEN_REFRESH,
    payload: {refresh}
});
  
export const refreshTokenSuccess = (tokenRefresh) => ({
    type: TOKEN_REFRESH_SUCCESS,
    payload: {tokenRefresh}
});

// TOKEN REJECT
export const rejectToken = (data) => ({
    type: TOKEN_REJECT,
    payload: {data}
});

export const rejectTokenSuccess = (rejectMessage) => ({
    type: TOKEN_REJECT_SUCCESS,
    payload: {rejectMessage}
});

export const retryWithTokenRefresh = (retryAction, refreshToken) => ({
    type: RETRY_WITH_TOKEN_REFRESH,
    payload: { retryAction, refreshToken },
});

export const retryWithTokenRefreshSuccess = () => ({
    type: RETRY_WITH_TOKEN_REFRESH_SUCCESS,
});


export const synchronizeEmail = (token, auth, mail) => {
    return {
        type: SYNCHRONIZE_EMAIL,
        payload: { token, auth, mail },
    };
};

export const synchronizeEmailSucces = (mailPassword, mail, sync) => {
    return {
        type: SYNCHRONIZE_EMAIL_SUCCESS,
        payload: { mailPassword, mail, sync },
    };
};

export const synchronizeAllEmailSucces = () => {
    return {
        type: SYNCHRONIZE_ALL_EMAIL_SUCCESS
    };
};

// export const unsynchronize = (token) => {
//     return {
//         type: UNSYNCHRONIZE_EMAIL,
//         payload: { token },
//     };
// };

// RETRIEVE CONNEXION
export const retrieveConnexion = (token) => {
    return {
        type: RETRIEVE_CONNEXION,
        payload: { token }
    };
};

export const retrieveConnexionSuccess = (current) => {
    return {
        type: RETRIEVE_CONNEXION_SUCCESS,
        payload: { current }
    };
};

// UPDATE LOCAL DATA
export const updateLocalData = (token) => {
    return {
        type: UPDATE_LOCAL_DATA,
        payload: { token }
    };
};

export const updateLocalDataSuccess = (msg) => {
    return {
        type: UPDATE_LOCAL_DATA_SUCCESS,
        payload: { msg }
    };
};


// EMPTY
export const emptyTrash = (token, trashMailbox) => {
    return {
        type: EMPTY_TRASH,
        payload: { token, trashMailbox },
    };
};

export const emptyTrashSuccess = (message, trashMailbox) => {
    return {
        type: EMPTY_TRASH_SUCCESS,
        payload: { message, trashMailbox },
    };
};

// RETRIEVE MAILBOX LIST OF AN ACCOUNT
export const allMailboxes = (token) => ({
    type: ALL_MAILBOX,
    payload: {token}
});
  
export const allMailboxesSuccess = (mailboxes) => ({
    type: ALL_MAILBOX_SUCCESS,
    payload: {mailboxes}
});

// GET ALL EMAILS OF A MAILBOX
export const allEmailsOfMailbox = (token, mailboxId) => ({
    type: ALL_EMAILS_OF_MAILBOX,
    payload: {token, mailboxId}
});

export const allEmailsOfMailboxSuccess = (emails, mailbox) => ({
    type: ALL_EMAILS_OF_MAILBOX_SUCCESS,
    payload: {
        emails,
        mailbox
    }
});

export const retrieveEmail = (token, mailId) => {
    return {
        type: RETREIVE_EMAIL,
        payload: { token, mailId },
    };
};

export const retrieveEmailSuccess = (email) => {
    return {
        type: RETREIVE_EMAIL_SUCCESS,
        payload: { email },
    };
};

// DROP SELECTED
export const dropSelected = () => {
    return {
        type: DROP_SELECTED
    }
}

// UPDATE EMAIL
export const updateEmail = (token, mailId, data) => {
    return {
        type: UPDATE_EMAIL,
        payload: { token, mailId, data },
    };
};

// UPDATE EMAIL
export const updateEmailSuccess = (mailId, updateMail) => {
    return {
        type: UPDATE_EMAIL_SUCCESS,
        payload: { mailId, updateMail },
    };
};

// SPAM EMAIL
export const spamEmail = (token, mailId, data) => {
    return {
        type: SPAM_EMAIL,
        payload: { token, mailId, data },
    };
};

export const spamEmailSuccess = (mailId, updateMail) => {
    return {
        type: SPAM_EMAIL_SUCCESS,
        payload: { mailId, updateMail },
    };
};

// UNSPAM EMAIL
export const unspamEmail = (token, mailId, data) => {
    return {
        type: UNSPAM_EMAIL,
        payload: { token, mailId, data },
    };
};

export const unspamEmailSuccess = (mailId, updateMail) => {
    return {
        type: UNSPAM_EMAIL_SUCCESS,
        payload: { mailId, updateMail },
    };
};

// DELETE EMAIL
export const deleteEmail = (token, mailId, mailbox) => {
    return {
        type: DELETE_EMAIL,
        payload: { token, mailId, mailbox },
    };
};

export const deleteEmailSuccess = (mailId, mailbox_name) => {
    return {
        type: DELETE_EMAIL_SUCCESS,
        payload: { mailId, mailbox_name },
    };
};

// DOWNLOAD ATTACHMENTS
export const emailAttachment = (token, attachmentId) => {
    return {
        type: EMAIL_ATTACHMENT,
        payload: { token, attachmentId },
    };
};

export const emailAttachmentSuccess = (attachment) => {
    return {
        type: EMAIL_ATTACHMENT_SUCCESS,
        payload: { attachment },
    };
};

// SEND EMAIL
export const sendEmail = (token, email, sign_id) => {
    return {
        type: SEND_EMAIL,
        payload: { token, email, sign_id },
    };
};

export const sendEmailSucess = (email) => {
    return {
        type: SEND_EMAIL_SUCCESS,
        payload: { email },
    };
};

// DRAFT EMAIL
export const draftEmail = (token, email) => {
    return {
        type: DRAFT_EMAIL,
        payload: { token, email },
    };
};

export const draftEmailSuccess = (email) => {
    return {
        type: DRAFT_EMAIL_SUCCESS,
        payload: { email },
    };
};

// CHECK IBOX
export const checkInbox = (token) => {
    return {
        type: CHECK_INBOX,
        payload: { token },
    };
};

export const checkInboxSuccess = (newEmails, spamEmails) => {
    return {
        type: CHECK_INBOX_SUCCESS,
        payload: { newEmails, spamEmails },
    };
};

export const verifySession = (token) => {
    return {
        type: VERIFY_SESSION,
        payload: {token}
    }
}

export const verifySessionSuccess = (connexion) => {
    return {
        type: VERIFY_SESSION_SUCCESS,
        payload: {connexion}
    }
}

export const createSession = (token, auth) => {
    return {
        type: CREATE_SESSION,
        payload: {token, auth}
    }
}

export const createSessionSuccess = (connexion) => {
    return {
        type: CREATE_SESSION_SUCCESS,
        payload: {connexion}
    }
}

export const deleteSession = (token) => {
    return {
        type: DELETE_SESSION,
        payload: {token}
    }
}

export const deleteSessionSuccess = (message) => {
    return {
        type: DELETE_SESSION_SUCCESS,
        payload: {message}
    }
}

// CONTACT LIST
export const allContacts = (token) => {
    return {
        type: LIST_CONTACT,
        payload: {token}
    }
}

export const allContactSuccess = (contacts) => {
    return {
        type: LIST_CONTACT_SUCCESS,
        payload: {contacts}
    }
}

// CREATE CONTACT
export const createContact = (token, data) => {
    return {
        type: CREATE_CONTACT,
        payload: {token, data}
    }
}

export const createContactSuccess = (newContact) => {
    return {
        type: CREATE_CONTACT_SUCCESS,
        payload: {newContact}
    }
}

// RETRIEVE CONTACT

// UPDATE CONTACT
export const updateContact = (token, contact_id, data) => {
    return {
        type: UPDATE_CONTACT,
        payload: {token, contact_id, data}
    }
}

export const updateContactSuccess = (updated_id, updatedContact) => {
    return {
        type: UPDATE_CONTACT_SUCCESS,
        payload: {updated_id, updatedContact}
    }
}

// DELETE CONTACT
export const deleteContact = (token, contact_id) => {
    return {
        type: DELETE_CONTACT,
        payload: {token, contact_id}
    }
}

export const deleteContactSuccess = (deleted_id) => {
    return {
        type: DELETE_CONTACT_SUCCESS,
        payload: {deleted_id}
    }
}

// ================================================================| RH | ==========================================================================//
export const activateRH = (token) => {
    return {
        type: ACTIVATE_RH,
        payload: {token}
    }
}

export const activateRHSuccess = (connexion) => {
    return {
        type: ACTIVATE_RH_SUCCESS,
        payload: {connexion}
    }
}

export const deactivateRH = (token) => {
    return {
        type: DEACTIVATE_RH,
        payload: {token}
    }
}

export const deactivateRHSuccess = (connexion) => {
    return {
        type: DEACTIVATE_RH_SUCCESS,
        payload: {connexion}
    }
}
// ===============================================================|SIGNATURE|====================================================================== //
// GET LIST MODEL
export const listModelSignature = (token) => {
    return  {
        type: LIST_MODEL_SIGNATURE,
        payload: {token}
    }
}

export const listModelSignatureSuccess = (models) => {
    return  {
        type: LIST_MODEL_SIGNATURE_SUCCESS,
        payload: {models}
    }
}

// CREATE SIGNITURE VIA MODEL
export const createSignatureViaModel = (token, model_id, signature) => {
    return {
        type: CREATE_SIGNATURE_VIA_MODEL,
        payload: { token, model_id, signature },
    };
};

export const createSignatureViaModelSuccess = (newSignatureModel) => {
    return {
        type: CREATE_SIGNATURE_VIA_MODEL_SUCCESS,
        payload: { newSignatureModel },
    };
};

// CREATE SIGNATURE
export const createSignature = (token, signature) => {
    return {
        type: CREATE_SIGNATURE,
        payload: { token, signature },
    };
};

export const createSignatureSuccess = (newSignature) => {
    return {
        type: CREATE_SIGNATURE_SUCCESS,
        payload: { newSignature  },
    };
};

// LIST SIGNATURE
export const listSignature = (token) => {
    return {
        type: LIST_SIGNATURE,
        payload: { token },
    };
};

export const listSignatureSuccess = (signatures) => {
    return {
        type: LIST_SIGNATURE_SUCCESS,
        payload: { signatures },
    };
};

// DELETE SIGNATURE
export const deleteSignature = (token, signature_id) => {
    return {
        type: DELETE_SIGNATURE,
        payload: { token, signature_id },
    };
};

export const deleteSignatureSuccess = (signature_id) => {
    return {
        type: DELETE_SIGNATURE_SUCCESS,
        payload: { signature_id },
    };
};

// export const retrieveSignature = (token, signature_id) => {
//     return {
//         type: RETREIVE_SIGNATURE,
//         payload: { token, signature_id },
//     };
// };