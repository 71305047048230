import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Badge, List } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import notificationData from 'assets/data/notification.data.json';
import Flex from 'components/shared-components/Flex';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadInvitationReceived } from 'redux/actions/chat/channel';
import moment from 'moment';

const getNotificationBody = (list, navigateRequest) => {
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item onClick={() => navigateRequest()} className="list-clickable">
          <Flex alignItems="center">
            <div className="mr-3">
              <span className="font-weight-bold text-dark">
                {item._invitedBy.firstName} {item._invitedBy.lastName}
              </span>
              <span className="text-gray-light">
                {' vous invite à rejoindre le channel '}
              </span>
              <span className="font-weight-bold text-dark">
                {item._channel.channelName}
              </span>
            </div>
            <small className="ml-auto">
              {moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
            </small>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">Pas de notification</p>
    </div>
  );
};

export const NavNotification = (props) => {
  const { current, loadInvitationReceived, received } = props;
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(notificationData);
  const history = useHistory();
  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };
  useEffect(() => {
    if (current) {
      loadInvitationReceived(current._id);
    }
  }, [current]);

  function navigateRequest() {
    history.push('/workspace/apps/request');
  }
  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(received, navigateRequest)}
      </div>
      {data.length > 0 ? (
        <div className="nav-notification-footer">
          <a
            href={'#/'}
            className="d-block"
            onClick={(e) => {
              e.preventDefault();
              navigateRequest();
            }}
          >
            Voir tout
          </a>
        </div>
      ) : null}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={received.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth, channels }) => {
  const { current } = auth;
  const { received } = channels;
  return { current, received };
};
const mapDispatchToProps = {
  loadInvitationReceived,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavNotification)
);
