import {
  CREATE_WORKSPACE_SUCCESS,
  CURRENT_WORKSPACE_SUCCESS,
  DELETE_WORKSPACE_SUCCESS,
  LOAD_DETAILS_WORKSPACE_SUCCESS,
  LOAD_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE_SUCCESS,
  WORKSPACE_MESSAGE,
} from 'redux/constants/manage-project/workspace.type';

const initialState = {
  lists: [],
  loading: false,
  message: '',
  currentWorkspaceData: null,
  details: null,
};

function WorkspaceReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_WORKSPACE_SUCCESS:
      return { ...state, loading: false, lists: action.payload };

    case LOAD_DETAILS_WORKSPACE_SUCCESS:
      return { ...state, loading: false, details: action.payload };
    case CREATE_WORKSPACE_SUCCESS:
      return { ...state, lists: [...state.lists, action.payload] };
    case UPDATE_WORKSPACE_SUCCESS:
      const updated = state.lists.map((w) =>
        w._id === action.payload._id ? action.payload : w
      );
      return {
        ...state,
        loading: false,
        lists: updated,
        currentWorkspaceData: action.payload,
      };
    case DELETE_WORKSPACE_SUCCESS:
      const deleted = state.lists.filter((w) => w._id !== action.payload._id);
      return {
        ...state,
        loading: false,
        lists: deleted,
        currentWorkspaceData: action.payload,
      };

    case CURRENT_WORKSPACE_SUCCESS:
      return { ...state, loading: false, currentWorkspaceData: action.payload };

    case WORKSPACE_MESSAGE:
      return {
        ...state,
        message: action.message,
        loading: false,
      };
    default:
      return state;
  }
}

export default WorkspaceReducer;
