import { LOAD_PROJECT_SUCCESS } from 'redux/constants/manage-project/project.type';
import {
  CREATE_ROLE,
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  LOAD_BY_PROJECT_ROLE,
  LOAD_BY_PROJECT_ROLE_SUCCESS,
  ROLE_MESSAGE,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
} from 'redux/constants/manage-project/role.type';

export const loadRole = (projectID) => {
  return {
    type: LOAD_BY_PROJECT_ROLE,
    payload: { projectID },
  };
};

export const createRole = (role) => {
  return {
    type: CREATE_ROLE,
    payload: { role },
  };
};

export const updateRole = (roleID, role) => {
  return {
    type: UPDATE_ROLE,
    payload: {
      role,
      roleID,
    },
  };
};

export const deleteRole = (roleID) => {
  return {
    type: DELETE_ROLE,
    payload: roleID,
  };
};

export const loadRoleSuccess = (roles) => {
  return {
    type: LOAD_BY_PROJECT_ROLE_SUCCESS,
    payload: roles,
  };
};

export const deleteRoleSuccess = (deleted) => {
  return {
    type: DELETE_ROLE_SUCCESS,
    payload: deleted,
  };
};

export const updateRoleSuccess = (updated) => {
  return {
    type: UPDATE_ROLE_SUCCESS,
    payload: updated,
  };
};

export const createdRoleSuccess = (created) => {
  return {
    type: CREATE_ROLE_SUCCESS,
    payload: created,
  };
};

export const roleError = (msg) => {
  return {
    type: ROLE_MESSAGE,
    payload: msg,
  };
};

export const showRoleMessage = (message) => {
  return {
    type: ROLE_MESSAGE,
    message,
  };
};
