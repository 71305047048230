import {
    LIST_SPRINT_MESSAGE,
    LOAD_LIST_SPRINT_SUCCESS,
    ADD_TASK_TO_LIST_SPRINT,
    ADD_NEW_TASK_TO_LIST_SPRINT,
    DELETE_TASK_IN_LIST_SPRINT_SUCCESS,
    DRAG_TASK_IN_LIST_SPRINT
  } from "redux/constants/manage-project/listSprint.type";
  
  const initialState = {
    data: [],
    loading: false,
    message: "",
  };
  
  function ListSprintReducer(state = initialState, action) {
    switch (action.type) {
      case LOAD_LIST_SPRINT_SUCCESS:
        return { ...state, loading: false, data: action.payload.map(lS=>({...lS,tasks:[]})) };
      case LIST_SPRINT_MESSAGE:
        return {
          ...state,
          message: action.message,
          loading: false,
        };
      case ADD_TASK_TO_LIST_SPRINT:
        state = {...state, data: state.data.map(lS=> {
          if( lS._id === action.payload.listSprintId ){
            if(lS.tasks.some(t=>t._id === action.payload._id)){
              return {...lS, tasks: lS.tasks.map(t=>t._id === action.payload._id? action.payload : t)}  
            }else{
              return{...lS, tasks: [...lS.tasks, action.payload]}  
            }
            
          }
          return lS
        })}
        return state
      case DELETE_TASK_IN_LIST_SPRINT_SUCCESS:
      state = state = {...state, data: state.data.map(lS=>{
        if(lS._id === action.payload.task.listSprintId){
          return {...lS, tasks: lS.tasks.filter(task=>task._id !== action.payload.task._id)}
        }
        return lS
      })}
      return state;
      case DRAG_TASK_IN_LIST_SPRINT:
        const {task, tasksInSource, tasksInDestination, sourceId, destinationId} = action.payload
        if(sourceId === destinationId){
          state = state = {...state, data: state.data.map(lS=>{
          if(lS._id === destinationId){
              return {...lS, tasks: tasksInDestination}
            }
            return lS
          })}
        }else{
          state = state = {...state, data: state.data.map(lS=>{
            if(lS._id === destinationId){
                return {...lS, tasks: tasksInDestination}
            }else if(lS._id === sourceId){
                return {...lS, tasks: tasksInSource}
            }
              return lS
            })}
        }
      return state;
    default:
        return state;
    }
  }
  
  export default ListSprintReducer;