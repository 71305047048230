export const LOAD_SPRINT = "LOAD_SPRINT";
export const CREATE_SPRINT = "CREATE_SPRINT";
export const UPDATE_SPRINT = "UPDATE_SPRINT";
export const DELETE_SPRINT = "DELETE_SPRINT";
export const SPRINT_MESSAGE = "SPRINT_MESSAGE";
export const SPRINT_ERROR = "SPRINT_ERROR";
export const LOAD_BY_PROJECT_SPRINT = "LOAD_BY_PROJECT_SPRINT";
export const CREATE_TASK_IN_SPRINT = "CREATE_TASK_IN_SPRINT"

export const LOAD_SPRINT_SUCCESS = "LOAD_SPRINT_SUCCESS";
export const CREATE_SPRINT_SUCCESS = "CREATE_SPRINT_SUCCESS";
export const UPDATE_SPRINT_SUCCESS = "UPDATE_SPRINT_SUCCESS";
export const DELETE_SPRINT_SUCCESS = "DELETE_SPRINT_SUCCESS";
export const LOAD_BY_PROJECT_SPRINT_SUCCESS = "LOAD_BY_PROJECT_SPRINT_SUCCESS";
export const CREATE_TASK_IN_SPRINT_SUCCESS = "CREATE_TASK_IN_SPRINT_SUCCESS";
export const UPDATE_TASK_IN_SPRINT_SUCCESS = "UPDATE_TASK_IN_SPRINT_SUCCESS";
export const DELETE_TASK_IN_SPRINT_SUCCESS = "DELETE_TASK_IN_SPRINT_SUCCESS";
