import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  createdChecklistSuccess,
  deleteChecklistSuccess,
  loadChecklistByTaskSuccess,
  showChecklistMessage,
  updateChecklistSuccess,
} from 'redux/actions/manage-project/checklist';
import {
  CREATE_CHECKLIST,
  DELETE_CHECKLIST,
  LOAD_CHECKLIST_BY_TASK,
  UPDATE_CHECKLIST,
} from 'redux/constants/manage-project/checklist.type';
import {
  createChecklist,
  deleteChecklist,
  loadChecklistByTask,
  updateChecklist,
} from 'services/ChecklistService';

export function* addCheck() {
  yield takeEvery(CREATE_CHECKLIST, function* ({ payload }) {
    const { checklist } = payload;
    try {
      const response = yield call(createChecklist, checklist);
      if (response.data.message) {
        yield put(showChecklistMessage(response.data.message));
      } else {
        yield put(createdChecklistSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChecklistMessage(err.response ? err.response.data : err));
    }
  });
}

export function* updateCheck() {
  yield takeEvery(UPDATE_CHECKLIST, function* ({ payload }) {
    const { checklist, checklistID } = payload;
    try {
      const response = yield call(updateChecklist, checklistID, checklist);
      if (response.data.message) {
        yield put(showChecklistMessage(response.data.message));
      } else {
        yield put(updateChecklistSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChecklistMessage(err.response ? err.response.data : err));
    }
  });
}

export function* deleteCheck() {
  yield takeEvery(DELETE_CHECKLIST, function* ({ payload }) {
    try {
      const response = yield call(deleteChecklist, payload);
      if (response.data.message) {
        yield put(showChecklistMessage(response.data.message));
      } else {
        yield put(deleteChecklistSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChecklistMessage(err.response ? err.response.data : err));
    }
  });
}

export function* loadChecklist() {
  yield takeEvery(LOAD_CHECKLIST_BY_TASK, function* ({ payload }) {
    const { taskID } = payload;
    try {
      const response = yield call(loadChecklistByTask, taskID);
      if (response.data.message) {
        yield put(showChecklistMessage(response.data.message));
      } else {
        yield put(loadChecklistByTaskSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChecklistMessage(err.response ? err.response.data : err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addCheck),
    fork(updateCheck),
    fork(deleteCheck),
    fork(loadChecklist),
  ]);
}
