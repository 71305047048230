import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  createdListSuccess,
  deleteListSuccess,
  loadListByProjectSuccess,
  loadListSuccess,
  showListMessage,
  updateListSuccess,
} from "redux/actions/manage-project/list";
import {
  CREATE_LIST,
  DELETE_LIST,
  LOAD_BY_PROJECT_LIST,
  LOAD_LIST,
  UPDATE_LIST,
} from "redux/constants/manage-project/list.type";

import {
  created,
  deleted,
  loadByproject,
  loaded,
  updated,
} from "services/listService";

export function* add() {
  yield takeEvery(CREATE_LIST, function* ({ payload }) {
    const { list } = payload;
    try {
      const response = yield call(created, list);
      if (response.data.message) {
        yield put(showListMessage(response.data.message));
      } else {
        yield put(createdListSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showListMessage(err.response ? err.response.data : err));
    }
  });
}

export function* allList() {
  yield takeEvery(LOAD_LIST, function* () {
    try {
      const response = yield call(loaded);
      if (response.data.message) {
        yield put(showListMessage(response.data.message));
      } else {
        yield put(loadListSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showListMessage(err.response ? err.response.data : err));
    }
  });
}

export function* update() {
  yield takeEvery(UPDATE_LIST, function* ({ payload }) {
    const { list, listID } = payload;
    try {
      const response = yield call(updated, listID, list);
      if (response.data.message) {
        yield put(showListMessage(response.data.message));
      } else {
        yield put(updateListSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showListMessage(err.response ? err.response.data : err));
    }
  });
}

export function* del() {
  yield takeEvery(DELETE_LIST, function* ({ payload }) {
    try {
      const response = yield call(deleted, payload);
      if (response.data.message) {
        yield put(showListMessage(response.data.message));
      } else {
        yield put(deleteListSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showListMessage(err.response ? err.response.data : err));
    }
  });
}

export function* byProject() {
  yield takeEvery(LOAD_BY_PROJECT_LIST, function* ({ payload }) {
    const { projectID } = payload;
    try {
      const response = yield call(loadByproject, projectID);
      if (response.data.message) {
        yield put(showListMessage(response.data.message));
      } else {
        yield put(loadListByProjectSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showListMessage(err.response ? err.response.data : err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(add),
    fork(update),
    fork(del),
    fork(byProject),
    fork(allList),
  ]);
}
