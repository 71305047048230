import {
  CREATE_CHANNEL_SUCCESS,
  CURRENT_CHANNEL_SUCCESS,
  DELETE_CHANNEL_SUCCESS,
  DELETE_INVITATION_SUCCESS,
  INVITATION_RECEIVED_SUCCESS,
  INVIT_USER_SUCCESS,
  LOAD_CHANNEL_SUCCESS,
  LOAD_INVITATION_BY_ME_SUCCESS,
  LOAD_USER_CHANNEL_SUCCESS,
  UPDATE_CHANNEL_SUCCESS,
} from 'redux/constants/chat/channel.type';

const initialState = {
  data: [],
  current: null,
  users: [],
  sent: [],
  received: [],
  channels: [],
};

function ChannelReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CHANNEL_SUCCESS:
      return { ...state, data: action.payload };
    case LOAD_INVITATION_BY_ME_SUCCESS:
      return { ...state, sent: action.payload };
    case INVITATION_RECEIVED_SUCCESS:
      return { ...state, received: action.payload };
    case INVIT_USER_SUCCESS:
      return {
        ...state,
        sent: [...state.sent, action.payload],
      };
    case LOAD_USER_CHANNEL_SUCCESS:
      return { ...state, users: action.payload };
    case CURRENT_CHANNEL_SUCCESS:
      return { ...state, current: action.payload };
    case CREATE_CHANNEL_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case UPDATE_CHANNEL_SUCCESS:
      const updated = state.data.map((channel) =>
        channel._id === action.payload._id ? action.payload : channel
      );
      return {
        ...state,
        data: updated,
      };
    case DELETE_CHANNEL_SUCCESS:
      const filtered = state.data.filter(
        (channel) => channel._id !== action.payload._id
      );
      return { ...state, data: filtered };
    case DELETE_INVITATION_SUCCESS:
      const filteredReceived = state.received.filter(
        (channel) => channel._id !== action.payload._id
      );
      return { ...state, received: filteredReceived };
    case UPDATE_CHANNEL_SUCCESS:
      const updatedReceived = state.received.map((received) =>
        received._id === action.payload._id ? action.payload : received
      );
      return {
        ...state,
        received: updatedReceived,
      };
    default:
      return state;
  }
}

export default ChannelReducer;
