import axios from 'axios';
import { API_BASE_URL } from 'constants/ApiConstant';

const url = API_BASE_URL + 'role/';

export const addRole = (data) => {
  return axios.post(`${url}`, data);
};

export const editRole = (id, data) => {
  return axios.put(`${url}${id}`, data);
};

export const removeRole = (id) => {
  return axios.delete(`${url}${id}`);
};

export const getRole = (projectID) => {
  return axios.get(`${url}project/${projectID}`);
};
