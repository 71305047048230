export const LOAD_PROJECT = 'LOAD_PROJECT';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const LOAD_BY_WORKSPACE_PROJECT = 'LOAD_BY_WORKSPACE_PROJECT';
export const LOAD_BY_OWNER = 'LOAD_BY_OWNER';
export const LOAD_BY_ID = 'LOAD_BY_ID';
export const PROJECT_ERROR = 'PROJECT_ERROR';
export const CURRENT_PROJECT = 'CURRENT_PROJECT';
export const PROJECT_MESSAGE = 'PROJECT_MESSAGE';

export const LOAD_PROJECT_SUCCESS = 'LOAD_PROJECT_SUCCESS';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const LOAD_BY_WORKSPACE_PROJECT_SUCCESS =
  'LOAD_BY_WORKSPACE_PROJECT_SUCCESS';
export const BLANK_WORKSPACE = 'BLANK_WORKSPACE';
export const LOAD_BY_OWNER_SUCCESS = 'LOAD_BY_OWNER_SUCCESS';
export const LOAD_BY_ID_SUCCESS = 'LOAD_BY_ID_SUCCESS';
export const CURRENT_PROJECT_SUCCESS = 'CURRENT_PROJECT_SUCCESS';

export const LOAD_ALL_USERS_OF_PROJECT = 'LOAD_ALL_USERS_OF_PROJECT';
export const LOAD_ALL_USERS_OF_PROJECT_SUCCESS =
  'LOAD_ALL_USERS_OF_PROJECT_SUCCESS';

export const LOAD_ALL_LABELS_OF_PROJECT = 'LOAD_ALL_LABELS_OF_PROJECT';
export const LOAD_ALL_LABELS_OF_PROJECT_SUCCESS =
  'LOAD_ALL_LABELS_OF_PROJECT_SUCCESS';

export const CREATE_LABEL = 'CREATE_LABEL';
export const CREATE_LABEL_SUCCESS = 'CREATE_LABEL_SUCCESS';

export const DELETE_LABEL = 'DELETE_LABEL';
export const DELETE_LABEL_SUCCESS = 'DELETE_LABEL_SUCCESS';
