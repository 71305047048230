import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  loadListSprintSuccess,
  showListSprintMessage,
} from "redux/actions/manage-project/listSprint";
import {
  LOAD_LIST_SPRINT,
} from "redux/constants/manage-project/listSprint.type";

import {
  loaded,
} from "services/ListSprintService";


export function* allListSprint() {
  yield takeEvery(LOAD_LIST_SPRINT, function* () {
    try {
      const response = yield call(loaded);
      if (response.data.message) {
        yield put(showListSprintMessage(response.data.message));
      } else {
        yield put(loadListSprintSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showListSprintMessage(err.response ? err.response.data : err));
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(allListSprint),
  ]);
}
