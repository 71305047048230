import axios from 'axios';
import { API_BASE_URL_AUTH } from 'constants/ApiConstant';

export const authLogin = (data) => {
  return axios.post(`${API_BASE_URL_AUTH}login`, data);
};

export const authSignup = (data) => {
  return axios.post(`${API_BASE_URL_AUTH}signup`, data);
};

export const validateT = (data) => {
  return axios.get(`${API_BASE_URL_AUTH}validate?token=${data}`);
};

export const getByEmail = (email) => {
  return axios.get(`${API_BASE_URL_AUTH}email/${email}`);
};

export const getAll = () => {
  return axios.get(`${API_BASE_URL_AUTH}`);
};
