import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { THEME_CONFIG } from './configs/AppConfig';
import {
  chatSocket,
  socket,
  SocketContext,
  SocketContextChat,
  SocketContextTracking,
  trackingSocket,
} from 'utils/socket';

import notifMp3 from 'assets/audio/notifications-sound-127856.mp3';
import eventMp3 from 'assets/audio/second-hand-149907.mp3';
import DesktopAppProvider from 'providers/DesktopAppProvider';
import RouteProvider from 'providers/RouteProvider';
import TimerProvider from 'providers/TimerProvider';
import NotificationProvider from 'providers/NotificationProvider';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      <SocketContext.Provider value={socket}>
        <SocketContextChat.Provider value={chatSocket}>
          {/* <SocketContextTracking.Provider value={trackingSocket}> */}
          <Provider store={store}>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={THEME_CONFIG.currentTheme}
              insertionPoint="styles-insertion-point"
            >
              <Router>
                <NotificationProvider>
                  <TimerProvider>
                    <RouteProvider>
                      <DesktopAppProvider>
                        <Switch>
                          <Route path="/" component={Views} />
                        </Switch>
                      </DesktopAppProvider>
                    </RouteProvider>
                  </TimerProvider>
                </NotificationProvider>
              </Router>
            </ThemeSwitcherProvider>
          </Provider>
          {/* </SocketContextTracking.Provider> */}
        </SocketContextChat.Provider>
      </SocketContext.Provider>
      <audio id="notif-message-mp3">
        <source src={notifMp3} type="audio/mpeg" />
      </audio>
      <audio id="notif-event-mp3">
        <source src={eventMp3} type="audio/mpeg" />
      </audio>
    </div>
  );
}

export default App;
