import {
    CLEAR_SUCCESS_MESSAGE,
    CREATE_CONNEXION_SUCCESS, 
    LIST_CONNEXION_SUCCESS, 
    LIST_CONNEXION,
    UPDATE_CONNEXION_SUCCESS,
    DELETE_CONNEXION_SUCCESS,
    TOKEN_OBTAIN_PAIR_SUCCESS,
    TOKEN_REFRESH_SUCCESS,
    TOKEN_REJECT_SUCCESS,
    SYNCHRONIZE_EMAIL_SUCCESS,
    ALL_MAILBOX_SUCCESS,
    ALL_EMAILS_OF_MAILBOX_SUCCESS,
    RETRY_WITH_TOKEN_REFRESH,
    RETRY_WITH_TOKEN_REFRESH_SUCCESS,
    RETREIVE_EMAIL_SUCCESS,
    DELETE_EMAIL_SUCCESS,
    EMAIL_ATTACHMENT_SUCCESS,
    SEND_EMAIL_SUCCESS,
    VERIFY_SESSION_SUCCESS,
    CREATE_SESSION_SUCCESS,
    DELETE_SESSION_SUCCESS,
    DRAFT_EMAIL_SUCCESS,
    CHECK_INBOX_SUCCESS,
    CHARGEMENT,
    EMPTY_TRASH_SUCCESS,
    SYNCHRONIZE_ALL_EMAIL_SUCCESS,
    UPDATE_EMAIL_SUCCESS,
    RETRIEVE_CONNEXION_SUCCESS,
    DROP_SELECTED,
    SHOW_MESSAGE_ERROR,
    LIST_CONTACT_SUCCESS,
    CREATE_CONTACT_SUCCESS,
    UPDATE_CONTACT_SUCCESS,
    DELETE_CONTACT_SUCCESS,
    LIST_MODEL_SIGNATURE_SUCCESS,
    CREATE_SIGNATURE_VIA_MODEL_SUCCESS,
    CREATE_SIGNATURE_SUCCESS,
    LIST_SIGNATURE_SUCCESS,
    DELETE_SIGNATURE_SUCCESS,
    CLEAR_MESSAGE_ERROR,
    SET_ACTION,
    DROP_ACTION,
    SPAM_EMAIL_SUCCESS,
    UNSPAM_EMAIL_SUCCESS,
    IGNORE_ALL_NOTIFICATION,
    IGNORE_ONE_NOTIFICATION,
    ACTIVATE_RH_SUCCESS,
    DEACTIVATE_RH_SUCCESS
} from 'redux/constants/mail/mail.type';

import {  notification } from 'antd';
import { formatDate } from 'utils/functions';
import { 
	MailOutlined
} from '@ant-design/icons';

const initialState = {
    connexions: [],
    loading: false,
    error: null,
    success: null,
    notification: null,
    current: null,
    pwd: null,
    accessToken: null,
    refreshToken: null,
    mailboxes: [],
    emails: {},
    selectedEmail: {},
    attachment: null,
    openSession: true,
    chargement: false,
    syncAll: false,
    contacts: [],
    models: [],
    signatures: [],
    counts: {},
    inboxName: null,
    spamboxName: null,
    action: null,
    notifications: [],
    sync: {
        count_remote: 0,
        count_local: 0,
        in_progress: false
    }
};

function MailReducer(state=initialState, action) {
    switch (action.type) {
        case CLEAR_SUCCESS_MESSAGE:
            return {
                ...state,
                success: null,
            };

        case CLEAR_MESSAGE_ERROR:
            return {
                ...state,
                error: null
            };

        case SHOW_MESSAGE_ERROR:
            const {error} = action.payload;

            return {
                ...state,
                error: error,
                loading: false,
                chargement: false
            };

        case CHARGEMENT:
            return {
                ...state,
                chargement: true
            };

        case SET_ACTION:
            const {act} = action.payload;
            return {
                ...state,
                action: act
            };

        case DROP_ACTION:
            return {
                ...state,
                action: null
            };
        case IGNORE_ALL_NOTIFICATION:
            return {
                ...state,
                notifications: []
            };

        case IGNORE_ONE_NOTIFICATION:
            const {key} = action.payload;
            const updatedNotifications = state.notifications.filter((notif) => notif.key !== key);
            console.log(updatedNotifications);
            return {
                ...state,
                notifications: updatedNotifications
            };
        
        // CONNEXION
        case CREATE_CONNEXION_SUCCESS:
            const { newConnexion, successMessage } = action.payload;
            return {
                ...state,
                connexions: [...state.connexions, newConnexion],
                loading: false,
                error: null,
                success: successMessage
            };
        
        // CONNEXION
        case RETRIEVE_CONNEXION_SUCCESS:
            const { current } = action.payload;
            return {
                ...state,
                current: current
            };

        case LIST_CONNEXION:
            return {
                ...state,
                loading: true,
                error: null
            };
        
        case LIST_CONNEXION_SUCCESS:
            return {
                ...state,
                connexions: action.payload,
                loading: false
            };
        
        case UPDATE_CONNEXION_SUCCESS:
            const {updatedConnexion, updateMessage} = action.payload; // updated connection object
            const updatedConnexions = state.connexions.map((connexion) =>
                connexion.id === updatedConnexion.id ? updatedConnexion : connexion
            );
            return {
                ...state,
                connexions: updatedConnexions,
                loading: false,
                error: null,
                success: updateMessage
            };
        
        case DELETE_CONNEXION_SUCCESS:
            const {deletedConnexionId, deleteMessage} = action.payload; // id of the deleted connection
            const updatedList = state.connexions.filter((connexion) => connexion.id !== deletedConnexionId);
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('refreshToken')
            return {
                ...initialState,
                connexions: updatedList,
                success: deleteMessage
            };
        
        // AUTHENTICATION
        case TOKEN_OBTAIN_PAIR_SUCCESS:
            const { token, message } = action.payload
            return {
                ...state,
                accessToken: token.access,
                refreshToken: token.refresh,
                success: message,
                mailboxes: [],
                emails: {},
                sync: {
                    ...state.sync,
                    in_progress: true
                }
            };
        
        case TOKEN_REFRESH_SUCCESS:
            const { tokenRefresh } = action.payload
            return {
                ...state,
                accessToken: tokenRefresh,
                success: "Your access token has been updated."
            };

        case TOKEN_REJECT_SUCCESS:
            const {rejectMessage} = action.payload
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
                success: rejectMessage
            };

        case RETRY_WITH_TOKEN_REFRESH:
            return {
                ...state,
                isRefreshingToken: true,
            };
        case RETRY_WITH_TOKEN_REFRESH_SUCCESS:
            return {
                ...state,
                isRefreshingToken: false,
                error: null,
            };
        
        // SYNCRONIZATION
        case SYNCHRONIZE_EMAIL_SUCCESS:
            const { mailPassword, mail, sync } = action.payload
            const currentConnexion = state.connexions.find(connexion => connexion.mail_address === mail);
            let in_progress = true;
            let msgSyncSuccess = null;
            if(sync.count_all_local === sync.count_all_remote) {
                in_progress = false;
                msgSyncSuccess = sync.msgSyncSuccess;
            }
            return {
                ...state,
                loading: true,
                current: currentConnexion,
                success: msgSyncSuccess,
                pwd: mailPassword,
                sync: {
                    count_remote: sync.count_all_remote,
                    count_local: sync.count_all_local,
                    in_progress: in_progress
                }
            };

        case SYNCHRONIZE_ALL_EMAIL_SUCCESS:
            return {
                ...state,
                syncAll: true,
                success: "All emails synchronized successfuly."
            };

        // MAILBOXES
        case ALL_MAILBOX_SUCCESS:
            const {mailboxes} = action.payload;
            const spamboxNames = ['spam', 'spams', 'junk', 'junks', 'blocked'];
            let spambox = mailboxes.find(mailbox => spamboxNames.includes(mailbox.name.toLowerCase()));
            let inbox = mailboxes.find(mailbox => mailbox.name.toLowerCase() === 'inbox');
            let spamboxName = '';
            let inboxName = '';
            if(spambox) {
                spamboxName = spambox.name;
            }
            if(inbox) {
                inboxName = inbox.name;
            }
            return {
                ...state,
                mailboxes: mailboxes,
                spamboxName: spamboxName,
                inboxName: inboxName
            };
        
        // EMAILS OF A MALBOX
        case ALL_EMAILS_OF_MAILBOX_SUCCESS:
            const {emails, mailbox} = action.payload;
            let counts = {...state.counts, [mailbox]: emails.length }
            if(mailbox === state.inboxName) {
                const unread = emails.filter((email) => email.is_read === false).length;
                const starred = emails.filter((email) => email.is_starred === true).length;
                const works = emails.filter((email) => email.flag === 'works').length;
                const important = emails.filter((email)=> email.flag === 'important').length;
                const private_ = emails.filter((email) => email.flag === 'private').length;
                counts = {...counts, unread: unread, starred: starred, works: works, important: important, private: private_}
            }
            return {
                ...state,
                loading: false,
                emails: {
                    ...state.emails,
                    [mailbox]: emails
                },
                counts: {
                    ...state.counts,
                    ...counts
                }
            };
        
        // RETRIEVE EMAIL
        case RETREIVE_EMAIL_SUCCESS:
            const { email } = action.payload;
            let unread = state.counts.unread;
            const newMailsList = state.emails[state.inboxName].map(e => {
                if (e.id === email.id) {
                    if(!e.is_read){
                        unread = unread -1;
                    }
                    const updatedEmail = { ...e, is_read: true };
                    return updatedEmail;
                }
                return e;
            });

            const updatedNotifs = state.notifications.filter((notif) => notif.key !== email.id);
            return {
                ...state,
                selectedEmail: email,
                emails: {
                    ...state.emails,
                    [state.inboxName]: newMailsList
                },
                counts: {
                    ...state.counts,
                    unread: unread
                },
                notifications: updatedNotifs
            };

        case DROP_SELECTED:
            return {
                ...state,
                selectedEmail: {}
            };
        
        // DELETE EMAIL
        case DELETE_EMAIL_SUCCESS:
            const { mailId, mailbox_name } = action.payload;
            const updatedMailbox = state.emails[mailbox_name];
            const updatedEmails = updatedMailbox.filter(email => email.id !== mailId);

            const isTrashMailboxExists = state.mailboxes.some(mailbox => mailbox.name.toLowerCase() === "trash");
            const trashOrDeletedMailbox = isTrashMailboxExists ? "Trash" : "Deleted";
            const deletedEmail = state.emails[mailbox_name].find(email => email.id === mailId);

            const updatedTrashOrDeleted = [...state.emails[trashOrDeletedMailbox], deletedEmail];
            let un1 = state.counts.unread;
            if(!deletedEmail.is_read) {
                un1 = un1-1;
            }
            return {
                ...state,
                success: "Email moved to trash.",
                chargement: false,
                emails: {
                  ...state.emails,
                  [mailbox_name]: updatedEmails,
                  [trashOrDeletedMailbox]: updatedTrashOrDeleted,
                },
                counts: {
                    ...state.counts,
                    [mailbox_name]: state.counts[mailbox_name] -1,
                    [trashOrDeletedMailbox]: state.counts[trashOrDeletedMailbox] + 1,
                    uread: un1

                }
            };

        // UPDATE EMAIL
        case UPDATE_EMAIL_SUCCESS:
            const mailIdUpdated = action.payload.mailId;
            const mailUpdated = action.payload.updateMail;
            let newCounts = {
                ...state.counts,
            }
            
            const updatedEmailsInbox = state.emails[state.inboxName].map(email => {
                if (email.id === mailIdUpdated) {
                    if(mailUpdated.is_starred && mailUpdated.is_starred !== email.is_starred) {
                        newCounts = {
                            ...newCounts,
                            starred: newCounts.starred + 1
                        }
                    }
                    if(mailUpdated.flag !== email.flag) {
                        newCounts = {
                            ...newCounts,
                            [email.flag]: newCounts[email.flag] -1,
                            [mailUpdated.flag]: newCounts[mailUpdated.flag] + 1
                        }
                    }
                    return mailUpdated;
                }

                return email;
            });

            return {
                ...state,
                chargement: false,
                emails: {
                  ...state.emails,
                  [state.inboxName]: updatedEmailsInbox,
                },
                counts:{
                    ...newCounts
                }
            };

        // SPAM EMAIL
        case SPAM_EMAIL_SUCCESS:
            const mailIdSpamed = action.payload.mailId;
            const mailUpdatedSpam = action.payload.updateMail;
            const updatedMailbox_ = state.emails[state.inboxName]
            const updatedEmails_ = updatedMailbox_.filter(email => email.id !== mailIdSpamed);
            return {
                ...state,
                chargement: false,
                emails: {
                  ...state.emails,
                  [state.inboxName]: updatedEmails_,
                  [state.spamboxName]: [mailUpdatedSpam, ...state.emails[state.spamboxName]]
                },
                counts:{
                    ...state.counts,
                    [state.inboxName]: state.counts[state.inboxName] -1,
                    [state.spamboxName]: state.counts[state.spamboxName] +1
                },
                success: "Mail marked as spam"
            };
        
        // UNSPAM EMAIL
        case UNSPAM_EMAIL_SUCCESS:
            const mailIdSpamed_ = action.payload.mailId;
            const mailUpdatedSpam_ = action.payload.updateMail;
            const updatedMailbox__ = state.emails[state.spamboxName]
            const updatedEmails__ = updatedMailbox__.filter(email => email.id !== mailIdSpamed_);
            return {
                ...state,
                chargement: false,
                emails: {
                  ...state.emails,
                  [state.inboxName]: [mailUpdatedSpam_, ...state.emails[state.inboxName]],
                  [state.spamboxName]: updatedEmails__
                },
                counts:{
                    ...state.counts,
                    [state.inboxName]: state.counts[state.inboxName] +1,
                    [state.spamboxName]: state.counts[state.spamboxName] -1,
                    unread: state.counts.unread + 1
                },
                success: "Mail marked as no spam"
            };
        
        
        // DOWNLOAD ATTACHEMENT
        case EMAIL_ATTACHMENT_SUCCESS:
            const { attachment } = action.payload;
            return {
                ...state,
                success: "Link for downloading attachment.",
                attachment: attachment
            };

        // SEND EMAIL
        case SEND_EMAIL_SUCCESS:
            return {
                ...state,
                success: "Email sent successfuly."
            };

        // SEND EMAIL
        case DRAFT_EMAIL_SUCCESS:
            return {
                ...state,
                success: "Email saved to drafts successfuly."
            };

        case VERIFY_SESSION_SUCCESS:
            const connexionSession = action.payload.connexion;
            return {
                ...state,
                openSession: true,
                current: connexionSession
            };

        case CREATE_SESSION_SUCCESS:
            const {connexion} = action.payload;
            return {
                ...state,
                openSession: true,
                current: connexion
            };

        case DELETE_SESSION_SUCCESS:
            const msgDS = action.payload.message;
            return {
                ...state,
                openSession: false,
                success: msgDS
            };

        case CHECK_INBOX_SUCCESS:
            const {newEmails, spamEmails} = action.payload
            let nb = newEmails.length === 0 ? null : newEmails.length;
            let senders = "";
            let nb_important = 0;
            let type = "#1677ff";
            let duration = 10;
            let notifications = []
            newEmails.forEach((mail)=> {
                senders += `${mail.sender.mail_address} (${mail.flag})-${formatDate(mail.date)}\n`;
                const notification = {'key': mail.id, 'name': mail.sender.name, 'email': mail.sender.mail_address, 'date': formatDate(mail.date), 'subject': mail.subject}
                notifications.push(notification);
                if(mail.importance >0 && mail.importance < 3) {
                    nb_important += 1;
                    type = "#faad14";
                    duration = 20;
                }
            });
            if(nb) {
                const key = `open${Date.now()}`;
                notification.open({
                    message: 'New email(s)',
                    description:
                    `You have (${nb}) new mails in your INBOX. ${formatDate(Date.now())}\n (${nb_important}) Importants \n ${senders}`,
                    key,
                    icon: <MailOutlined style={{color: type}} />,
                    placement: "topRight",
                    duration: duration
                });
            }
            const updatedINBOX = newEmails.length === 0 ? state.emails[state.inboxName] : [...newEmails, ...state.emails[state.inboxName]];
            
            return {
                ...state,
                emails: {
                    ...state.emails,
                    [state.inboxName]: updatedINBOX,
                    [state.spamboxName]: [...spamEmails, ...state.emails[state.spamboxName]]
                },
                counts: {
                    ...state.counts,
                    [state.inboxName]: state.counts[state.inboxName] + newEmails.length,
                    [state.spamboxName]: state.counts[state.spamboxName] + spamEmails.length,
                    unread: state.counts.unread + newEmails.length
                },
                notifications: [...notifications, ...state.notifications]
            };
    
        case EMPTY_TRASH_SUCCESS:
            const msgET = action.payload.message;
            const {trashMailbox} = action.payload;
            return {
                ...state,
                success: msgET,
                chargement: false,
                emails: {
                    ...state.emails,
                    [trashMailbox]: [],
                },
                counts: {
                    ...state.counts,
                    [trashMailbox]: 0
                }
            };
        
        // CONTACT 'LIST OF USER
        case LIST_CONTACT_SUCCESS:
            const {contacts} = action.payload;
            return {
                ...state,
                contacts: contacts
            };
        
        // CREATE CONTACT
        case CREATE_CONTACT_SUCCESS:
            const {newContact} = action.payload;
            return {
                ...state,
                chargement: false,
                success: 'Contact created successfuly.',
                contacts: [newContact, ...state.contacts]
            };

        // UPDATE CONTACT
        case UPDATE_CONTACT_SUCCESS:
            const {updated_id, updatedContact} = action.payload;

            const updatedContacts = state.contacts.map((contact) =>
                contact.id === updated_id ? updatedContact : contact
            );

            return {
                ...state,
                contacts: updatedContacts,
                chargement: false,
                error: null
            };
            
        // DELETE CONTACT
        case DELETE_CONTACT_SUCCESS:
            const {deleted_id} = action.payload;
            const currentContacts = state.contacts.filter((contact) => contact.id !== deleted_id);

            return {
                ...state,
                contacts: currentContacts,
                chargement: false,
                error: null
            };

        case LIST_MODEL_SIGNATURE_SUCCESS:
            const {models} = action.payload;
            return {
                ...state,
                models: models
            };

        case CREATE_SIGNATURE_VIA_MODEL_SUCCESS:
            const { newSignatureModel } = action.payload;
            return {
                ...state,
                success: "Signature created successfuly.",
                signatures: [...state.signatures, newSignatureModel]
            };

        case CREATE_SIGNATURE_SUCCESS:
            const { newSignature } = action.payload;
            return {
                ...state,
                success: "Signature created successfuly.",
                signatures: [...state.signatures, newSignature]
            };

        case LIST_SIGNATURE_SUCCESS:
            const { signatures } = action.payload;
            return {
                ...state,
                signatures: signatures
            };

        case DELETE_SIGNATURE_SUCCESS:
            const { signature_id } = action.payload;
            const newSignatures = state.signatures.filter((signature) => signature.id !== signature_id);
            return {
                ...state,
                success: 'Signature deleted successfuly',
                signatures: newSignatures
            };

        case ACTIVATE_RH_SUCCESS:
            const nex = action.payload.connexion
            const updatedConnexions_ = state.connexions.map((con) =>
                con.id === nex.id ? nex : con
            );

            return {
                ...state,
                connexions: updatedConnexions_,
                chargement: false,
                current: nex,
                success: 'Mode RH activated successfully.',
                error: null
            };

        case DEACTIVATE_RH_SUCCESS:
            const nex_ = action.payload.connexion
            const updatedConnexions__ = state.connexions.map((con) =>
                con.id === nex_.id ? nex_ : con
            );

            return {
                ...state,
                connexions: updatedConnexions__,
                chargement: false,
                current: nex_,
                success: 'Mode RH deactivated successfully.',
                error: null
            };

        default:
            return state
    }
}

export default MailReducer;