export const LOAD_LIST_SPRINT = "LOAD_LIST_SPRINT";
export const LIST_SPRINT_MESSAGE = "LIST_SPRINT_MESSAGE";
export const LIST_SPRINT_ERROR = "LIST_SPRINT_ERROR";
export const CREATE_TASK_IN_LIST_SPRINT = "CREATE_TASK_IN_LIST_SPRINT"

export const LOAD_LIST_SPRINT_SUCCESS = "LOAD_LIST_SPRINT_SUCCESS";
export const CREATE_TASK_IN_LIST_SPRINT_SUCCESS = "CREATE_TASK_IN_LIST_SPRINT_SUCCESS";
export const UPDATE_TASK_IN_LIST_SPRINT_SUCCESS = "UPDATE_TASK_IN_LIST_SPRINT_SUCCESS";
export const DELETE_TASK_IN_LIST_SPRINT_SUCCESS = "DELETE_TASK_IN_LIST_SPRINT_SUCCESS";
export const ADD_TASK_TO_LIST_SPRINT = "ADD_TASK_TO_LIST_SPRINT"
export const ADD_NEW_TASK_TO_LIST_SPRINT = "ADD_NEW_TASK_TO_LIST_SPRINT"
export const DRAG_TASK_IN_LIST_SPRINT = "DRAG_TASK_IN_LIST_SPRINT"
