import {
  CREATE_PROJECT_SUCCESS,
  CURRENT_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  LOAD_BY_OWNER_SUCCESS,
  LOAD_BY_WORKSPACE_PROJECT_SUCCESS,
  LOAD_PROJECT_SUCCESS,
  PROJECT_MESSAGE,
  UPDATE_PROJECT_SUCCESS,
  LOAD_ALL_USERS_OF_PROJECT_SUCCESS,
  LOAD_ALL_LABELS_OF_PROJECT_SUCCESS,
  CREATE_LABEL_SUCCESS,
  DELETE_LABEL_SUCCESS,
  BLANK_WORKSPACE,
} from 'redux/constants/manage-project/project.type';

const initialState = {
  byWorkspaces: [],
  all: [],
  byOwner: [],
  loading: false,
  message: '',
  current: null,
  members: [],
  labels: [],
};

function ProjectReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PROJECT_SUCCESS:
      return { ...state, all: action.payload };

    case LOAD_BY_OWNER_SUCCESS:
      return { ...state, byOwner: action.payload };

    case LOAD_BY_WORKSPACE_PROJECT_SUCCESS:
      return { ...state, byWorkspaces: action.payload };

    case BLANK_WORKSPACE:
      return { ...state, byWorkspaces: [], current: null };

    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        byWorkspaces: [...state.byWorkspaces, action.payload],
        all: [...state.all, action.payload],
        byOwner: [...state.byOwner, action.payload],
      };

    case CURRENT_PROJECT_SUCCESS:
      return { ...state, loading: false, current: action.payload };

    case UPDATE_PROJECT_SUCCESS:
      const all = state.all.map((p) =>
        p._id === action.payload._id ? action.payload : p
      );
      const byOwner = state.byOwner.map((p) =>
        p._id === action.payload._id ? action.payload : p
      );
      const byWorkspaces = state.byWorkspaces.map((p) =>
        p._id === action.payload._id ? action.payload : p
      );
      return {
        ...state,
        loading: false,
        byWorkspaces: byWorkspaces,
        all: all,
        byOwner: byOwner,
        current: action.payload,
      };

    case DELETE_PROJECT_SUCCESS:
      const alled = state.all.filter((p) => p._id !== action.payload._id);
      const byOwnered = state.byOwner.filter(
        (p) => p._id !== action.payload._id
      );
      const byWorkspace = state.byWorkspaces.filter(
        (p) => p._id !== action.payload._id
      );
      return {
        ...state,
        loading: false,
        byWorkspaces: byWorkspace,
        all: alled,
        byOwner: byOwnered,
        current: action.payload,
      };
    case PROJECT_MESSAGE:
      return {
        ...state,
        message: action.message,
        loading: false,
      };

    case LOAD_ALL_USERS_OF_PROJECT_SUCCESS:
      const { members } = action.payload;
      return {
        ...state,
        members: members,
      };

    case LOAD_ALL_LABELS_OF_PROJECT_SUCCESS:
      const { labels } = action.payload;
      return {
        ...state,
        labels: labels,
      };

    case CREATE_LABEL_SUCCESS:
      const { label } = action.payload;
      return {
        ...state,
        labels: [...state.labels, label],
      };

    case DELETE_LABEL_SUCCESS:
      const { labelId } = action.payload;
      const newLabels = state.labels.filter((label) => label._id !== labelId);
      return {
        ...state,
        labels: newLabels,
      };

    default:
      return state;
  }
}

export default ProjectReducer;
