import {
  DashboardOutlined,
  AppstoreOutlined,
  DotChartOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  FundOutlined,
  UserOutlined,
  SafetyOutlined,
} from '@ant-design/icons';
import { WORKSPACE_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [
  {
    key: 'dashboards',
    path: `${WORKSPACE_PREFIX_PATH}/dashboards`,
    title: 'sidenav.dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboards-default',
        path: `${WORKSPACE_PREFIX_PATH}/dashboards/default`,
        title: 'sidenav.dashboard.default',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: 'dashboards-analytic',
      //   path: `${WORKSPACE_PREFIX_PATH}/dashboards/analytic`,
      //   title: 'sidenav.dashboard.analytic',
      //   icon: DotChartOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      // {
      //   key: 'dashboards-sales',
      //   path: `${WORKSPACE_PREFIX_PATH}/dashboards/sales`,
      //   title: 'sidenav.dashboard.sales',
      //   icon: FundOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];

const appsNavTree = [
  {
    key: 'apps',
    path: `${WORKSPACE_PREFIX_PATH}/apps`,
    title: 'sidenav.apps',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'apps-project',
        path: `${WORKSPACE_PREFIX_PATH}/apps/project`,
        title: 'sidenav.apps.project',
        icon: BulbOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'apps-mail',
        path: `${WORKSPACE_PREFIX_PATH}/apps/mail`,
        title: 'sidenav.apps.mail',
        icon: MailOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'apps-chat',
        path: `${WORKSPACE_PREFIX_PATH}/apps/chat`,
        title: 'sidenav.apps.chat',
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'apps-calendar',
        path: `${WORKSPACE_PREFIX_PATH}/apps/calendar`,
        title: 'sidenav.apps.calendar',
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'apps-user',
        path: `${WORKSPACE_PREFIX_PATH}/apps/user`,
        title: 'sidenav.apps.user',
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'apps-tracking',
        path: `${WORKSPACE_PREFIX_PATH}/apps/tracking`,
        title: 'sidenav.apps.tracking',
        icon: DotChartOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfigWorkspace = [...dashBoardNavTree, ...appsNavTree];

export default navigationConfigWorkspace;
