export const LOAD_CHANNEL = 'LOAD_CHANNEL';
export const CURRENT_CHANNEL = 'CURRENT_CHANNEL';
export const CREATE_CHANNEL = 'CREATE_CHANNEL';
export const UPDATE_CHANNEL = 'UPDATE_CHANNEL';
export const DELETE_CHANNEL = 'DELETE_CHANNEL';
export const LOAD_USER_CHANNEL = 'LOAD_USER_CHANNEL';
export const INVIT_USER = 'INVIT_USER';
export const DELETE_INVITATION = 'DELETE_INVITATION';
export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';

export const CHANNEL_ERROR = 'CHANNEL_ERROR';
export const CHANNEL_MESSAGE = 'CHANNEL_MESSAGE';

export const LOAD_CHANNEL_SUCCESS = 'LOAD_CHANNEL_SUCCESS';
export const LOAD_INVITATION_BY_ME = 'LOAD_INVITATION_BY_ME';
export const LOAD_INVITATION_BY_ME_SUCCESS = 'LOAD_INVITATION_BY_ME_SUCCESS';
export const LOAD_USER_CHANNEL_SUCCESS = 'LOAD_USER_CHANNEL_SUCCESS';
export const CREATE_CHANNEL_SUCCESS = 'CREATE_CHANNEL_SUCCESS';
export const UPDATE_CHANNEL_SUCCESS = 'UPDATE_CHANNEL_SUCCESS';
export const DELETE_CHANNEL_SUCCESS = 'DELETE_CHANNEL_SUCCESS';
export const CURRENT_CHANNEL_SUCCESS = 'CURRENT_CHANNEL_SUCCESS';
export const INVIT_USER_SUCCESS = 'INVIT_USER_SUCCESS';
export const INVITATION_RECEIVED = 'INVITATION_RECEIVED';
export const INVITATION_RECEIVED_SUCCESS = 'INVITATION_RECEIVED_SUCCESS';
export const DELETE_INVITATION_SUCCESS = 'DELETE_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
