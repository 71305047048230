export const LOAD_WORKSPACE = 'LOAD_WORKSPACE';
export const LOAD_DETAILS_WORKSPACE = 'LOAD_DETAILS_WORKSPACE';
export const LOAD_DETAILS_WORKSPACE_SUCCESS = 'LOAD_DETAILS_WORKSPACE_SUCCESS';
export const LOAD_WORKSPACE_SUCCESS = 'LOAD_WORKSPACE_SUCCESS';
export const CREATE_WORKSPACE = 'CREATE_WORKSPACE';
export const CREATE_WORKSPACE_SUCCESS = 'CREATE_WORKSPACE_SUCCESS';
export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE';
export const UPDATE_WORKSPACE_SUCCESS = 'UPDATE_WORKSPACE_SUCCESS';
export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';
export const DELETE_WORKSPACE_SUCCESS = 'DELETE_WORKSPACE_SUCCESS';
export const GET_BY_LIST_WORKSPACE = 'GET_BY_LIST_WORKSPACE';
export const WORKSPACE_ERROR = 'WORKSPACE_ERROR';
export const WORKSPACE_MESSAGE = 'WORKSPACE_MESSAGE';
export const CURRENT_WORKSPACE = 'CURRENT_WORKSPACE';
export const CURRENT_WORKSPACE_SUCCESS = 'CURRENT_WORKSPACE_SUCCESS';
