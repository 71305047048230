import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  AUTH_TOKEN,
  LOAD_ALL_USER,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
} from '../constants/Auth';
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  validateSuccess,
  loadAllUserSuccess,
} from '../actions/Auth';

import { authLogin, authSignup, getAll, validateT } from 'services/AuthService';
import { VALIDATE_TOKEN } from 'redux/constants/user/auth.type';

export function* signIn() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const response = yield call(authLogin, { email, password });
      if (response.data.message) {
        yield put(showAuthMessage(response.data.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, response.data.data);
        yield put(authenticated(response.data.data));
      }
    } catch (err) {
      yield put(showAuthMessage(err.response ? err.response.data : err));
    }
  });
}

export function* validateToken() {
  yield takeEvery(VALIDATE_TOKEN, function* ({ payload }) {
    try {
      const response = yield call(validateT, payload);
      if (response.data.message) {
        yield put(showAuthMessage(response.data.message));
      } else {
        yield put(validateSuccess(response.data.data.user));
      }
    } catch (err) {
      yield put(showAuthMessage(err.response ? err.response.data : err));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      localStorage.removeItem(AUTH_TOKEN);
      yield put(signOutSuccess(undefined));
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUp() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    try {
      const response = yield call(authSignup, payload);
      if (response.data.message) {
        yield put(showAuthMessage(response.data.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, response.data.data);
        yield put(signUpSuccess(response.data.data));
      }
    } catch (error) {
      yield put(
        showAuthMessage(error.response ? error.response.data.data : error)
      );
    }
  });
}

export function* allUsers() {
  yield takeEvery(LOAD_ALL_USER, function* () {
    try {
      const response = yield call(getAll);
      if (response.data.message) {
        yield put(showAuthMessage(response.data.message));
      } else {
        yield put(loadAllUserSuccess(response.data.data));
      }
    } catch (error) {
      yield put(
        showAuthMessage(error.response ? error.response.data.data : error)
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signIn),
    fork(signOut),
    fork(signUp),
    fork(validateToken),
    fork(allUsers),
  ]);
}
