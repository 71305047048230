import {
  CREATE_LIST_SUCCESS,
  DELETE_LIST_SUCCESS,
  DRAG_TASK_IN_LIST,
  LIST_MESSAGE,
  LOAD_BY_PROJECT_LIST_SUCCESS,
  LOAD_LIST_SUCCESS,
  UPDATE_LIST_SUCCESS,
  UPDATE_TASK_IN_LIST,
  DELETE_TASK_IN_LIST,
  ADD_TASK_IN_LIST,
} from 'redux/constants/manage-project/list.type';

const initialState = {
  data: [],
  loading: false,
  message: '',
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_LIST_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case CREATE_LIST_SUCCESS:
      return {
        ...state,
        data: [...state.data, { ...action.payload, tasks: [] }],
      };
    case UPDATE_LIST_SUCCESS:
      const updated = state.data.map((l) =>
        l._id === action.payload._id ? action.payload : l
      );
      return { ...state, loading: false, data: updated };
    case DELETE_LIST_SUCCESS:
      const deleted = state.data.filter((l) => l._id !== action.payload._id);
      return { ...state, loading: false, data: deleted };

    case LOAD_BY_PROJECT_LIST_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case DRAG_TASK_IN_LIST:
      return {
        ...state,
        loading: false,
        data: state.data.map((l) => {
          if (l._id === action.payload.destData._id) {
            return action.payload.destData;
          } else if (l._id === action.payload.sourceData._id) {
            return action.payload.sourceData;
          }
          return l;
        }),
      };
    case ADD_TASK_IN_LIST:
      return {
        ...state,
        loading: false,
        data: state.data.map((l) => {
          if (l._id === action.payload.listId) {
            return { ...l, tasks: [...l.tasks, action.payload] };
          }
          return l;
        }),
      };
    case UPDATE_TASK_IN_LIST:
      return {
        ...state,
        loading: false,
        data: state.data.map((l) => {
          if (l._id === action.payload.listId) {
            return {
              ...l,
              tasks: l.tasks.map((t) =>
                t._id === action.payload._id ? action.payload : t
              ),
            };
          }
          return { ...l, tasks: l.tasks.filter(t=>t._id !== action.payload._id)};
        }),
      };
    case DELETE_TASK_IN_LIST:
      return {
        ...state,
        loading: false,
        data: state.data.map((l) => {
          if (l._id === action.payload.listId) {
            return {
              ...l,
              tasks: l.tasks.filter((t) => t._id !== action.payload._id),
            };
          }
          return l;
        }),
      };
    case LIST_MESSAGE:
      return {
        ...state,
        message: action.message,
        loading: false,
      };
    default:
      return state;
  }
}

export default ListReducer;
