import { all, takeEvery, put, fork, call, delay, select } from 'redux-saga/effects';
import { isAccessTokenExpiredError } from 'utils/functions'
import {
    clearSuccessMessage,
    clearErrorMessage,
    createConnexionSuccess,
    updateConnexionSuccess,
    deleteConnexionSuccess,
    listConnexionSuccess,
    tokenObtainPairSuccess,
    refreshTokenSuccess,
    rejectTokenSuccess,
    synchronizeAllEmailSucces,
    synchronizeEmailSucces,
    allMailboxesSuccess,
    allEmailsOfMailboxSuccess,
    retryWithTokenRefresh,
    retrieveEmailSuccess,
    deleteEmailSuccess,
    emailAttachmentSuccess,
    sendEmailSucess,
    verifySessionSuccess,
    createSessionSuccess,
    deleteSessionSuccess,
    draftEmailSuccess,
    checkInboxSuccess,
    checkInbox,
    chargement,
    emptyTrashSuccess,
    updateEmailSuccess,
    allContactSuccess,
    createContactSuccess,
    updateContactSuccess,
    showMessageError,
    listModelSignatureSuccess,
    createSignatureViaModelSuccess,
    createSignatureSuccess,
    listSignatureSuccess,
    deleteContactSuccess,
    deleteSignatureSuccess,
    spamEmailSuccess,
    unspamEmailSuccess,
    activateRHSuccess,
    deactivateRHSuccess
} from 'redux/actions/mail/mail.action';

import {
    CREATE_CONNEXION,
    LIST_CONNEXION,
    UPDATE_CONNEXION,
    DELETE_CONNEXION,
    TOKEN_OBTAIN_PAIR,
    TOKEN_REFRESH,
    TOKEN_REJECT,
    SYNCHRONIZE_EMAIL,
    ALL_MAILBOX,
    ALL_EMAILS_OF_MAILBOX,
    RETREIVE_EMAIL,
    DELETE_EMAIL,
    EMAIL_ATTACHMENT,
    SEND_EMAIL,
    VERIFY_SESSION,
    CREATE_SESSION,
    DELETE_SESSION,
    DRAFT_EMAIL,
    CHECK_INBOX,
    EMPTY_TRASH,
    UPDATE_EMAIL,
    LIST_CONTACT,
    CREATE_CONTACT,
    UPDATE_CONTACT,
    DELETE_CONTACT,
    LIST_MODEL_SIGNATURE,
    CREATE_SIGNATURE_VIA_MODEL,
    CREATE_SIGNATURE,
    LIST_SIGNATURE,
    DELETE_SIGNATURE,
    SPAM_EMAIL,
    UNSPAM_EMAIL,
    ACTIVATE_RH,
    DEACTIVATE_RH
} from 'redux/constants/mail/mail.type';

import { 
    createConnexionRequest,
    listConnexionRequest,
    updateConnexionRequest,
    deleteConnexionRequest,
    tokenObtainPairRequest,
    tokenRefreshRequest,
    tokenRejectRequest,
    synchronizeEmailRequest,
    allMailboxRequest,
    allEmailsOfMailboxRequest,
    retreiveEmailRequest,
    deleteEmailsRequest,
    emailAttachmentRequest,
    sendEmailRequest,
    verifySessionRequest,
    addSessionRequest,
    deleteSessionRequest,
    draftEmailRequest,
    checkInboxRequest,
    emptyTrashRequest,
    updateLocalDataRequest,
    updateEmailsRequest,
    allContactsRequest,
    updateContactRequest,
    listModelSignaturesRequest,
    createSignatureViaModelRequest,
    createSignatureRequest,
    listSignatureRequest,
    deleteSignatureRequest,
    deleteContactRequest,
    createContactRequest,
    activateRHRequest,
    deactivateRHRequest,
    // retrieveConnexionRequest
} from 'services/MailService';

import { filtrerContacts } from 'utils/functions';

/**
 * FUNCTIONS SAGA
 */
// WORK
function* createConnexion(action) {
    try {
        const { connexion } = action.payload;
        const response = yield call(createConnexionRequest, connexion);
        yield put(createConnexionSuccess(response.data, "Connection created successfully"));
        yield delay(3000);
        yield put(clearSuccessMessage());
        
    } catch (error) {
        if (error.response && error.response.data) {
            const errorData = error.response.data;
            const errorMessage = Object.values(errorData)[0][0];
            yield put(showMessageError(errorMessage));
            yield delay(3000);
            yield put(clearErrorMessage());
        } else {
            yield put(showMessageError("An error occurred while creating the connection."));
            yield delay(3000);
            yield put(clearErrorMessage());
        }
    }
}

function* updateConnexion(action) {
    try {
        const { user_id, connexion_id, connexion } = action.payload;
        const response = yield call(updateConnexionRequest, user_id, connexion_id, connexion);
        yield put(updateConnexionSuccess(response.data, "Connection updated successfully"));
        yield delay(5000);
        yield put(clearSuccessMessage());
    } catch (error) {
        if (error.response && error.response.data) {
            const errorData = error.response.data;
            const errorMessage = Object.values(errorData)[0][0];
            yield put(showMessageError(errorMessage));
            yield delay(3000);
            yield put(clearErrorMessage());
        } else {
            yield put(showMessageError("An error occurred while updating the connection."));
            yield delay(3000);
            yield put(clearErrorMessage());
        }
    }
}

function* deleteConnexion(action) {
    try {
        const { user_id, connexion_id } = action.payload;
        yield call(deleteConnexionRequest, user_id, connexion_id);
        yield put(deleteConnexionSuccess(connexion_id ,"Connection deleted successfully"));
        yield delay(5000);
        yield put(clearSuccessMessage());
    } catch (error) {
        if (error.response && error.response.data) {
            console.log(error.response)
            const errorData = error.response.data;
            const errorMessage = Object.values(errorData)[0][0];
            yield put(showMessageError(errorMessage));
        } else {
            yield put(showMessageError("An error occurred while deleting the connection."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

function* allConnexion(action) {
    try {
      const { userID } = action.payload;
      const response = yield call(listConnexionRequest, userID);
      yield put(listConnexionSuccess(response.data));
    } catch (error) {
      yield put(showMessageError(error));
      yield delay(3000);
      yield put(clearErrorMessage());
    }
}

// WORK
function* obtainAccessToken(action) {
    const {auth, access} = action.payload;
    try {
        const token = yield call(tokenObtainPairRequest, auth);
        yield put(tokenObtainPairSuccess(token.data, 'Connection success!'));
        // Stockage du token d'accès dans sessionStorage
        yield delay(5000);
        yield put(clearSuccessMessage());
        // Synchronize email
        try {
            const res = yield call(addSessionRequest, token.data.access, access);
            yield put(createSessionSuccess(res.data));
            yield delay(3000);
            yield put(clearSuccessMessage());
            localStorage.setItem('accessToken', token.data.access);
            localStorage.setItem('refreshToken', token.data.refresh)
            const sync = yield call(synchronizeEmailRequest, token.data.access, access);
            yield put(synchronizeEmailSucces(access.mail_password, auth.mail_address, sync.data));
            yield delay(3000);
            const mailboxes = yield call(allMailboxRequest, token.data.access);
            yield put(allMailboxesSuccess(mailboxes.data));
            yield delay(5000);
            for(let i =0; i<mailboxes.data.length; i++){
                const emails = yield call(allEmailsOfMailboxRequest, token.data.access, mailboxes.data[i].id);
                yield put(allEmailsOfMailboxSuccess(emails.data, mailboxes.data[i].name));
                if (i === mailboxes.data.length - 1) {
                    if (sync.data.count_all_remote > sync.data.count_all_local) {
                        yield delay(5000);
                        yield call(synchronizeEmail, token, access, auth);
                    } else {
                        yield put(synchronizeAllEmailSucces);
                    }
                }
            }
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data) {
                if(error.response.data.message){
                    const errorMessage = error.response.data.message;
                    yield put(showMessageError(errorMessage));
                } else {
                    const errorData = error.response.data;
                    const errorMessage = Object.values(errorData)[0][0];
                    yield put(showMessageError(errorMessage));
                }
            } else {
                yield put(showMessageError("An error occurred while creating the connection."));
            }
            yield delay(3000);
            yield put(clearErrorMessage());
        }
    } catch (error) {
        yield put(showMessageError(`An error occurred while getting access the connection. ${error.message}`));
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// SYNCHRONIZE EMAIL
function* synchronizeEmail(token, access, auth) {
    try{
        const mailboxes = yield call(allMailboxRequest, token.data.access);
        yield put(allMailboxesSuccess(mailboxes.data));
        try{
            const sync = yield call(synchronizeEmailRequest, token.data.access, access);

            yield put(synchronizeEmailSucces(access.mail_password, auth.mail_address, sync.data));
            yield delay(5000);
            for(let i =0; i<mailboxes.data.length; i++){
                try {
                    const emails = yield call(allEmailsOfMailboxRequest, token.data.access, mailboxes.data[i].id);
                    yield put(allEmailsOfMailboxSuccess(emails.data, mailboxes.data[i].name));
                    if (i === mailboxes.data.length - 1) {
                        if (sync.data.count_all_remote > sync.data.count_all_local) {
                            yield delay(5000);
                            yield call(synchronizeEmail, token, access, auth);
                        } else {
                            yield put(synchronizeAllEmailSucces);
                        }
                    }
                } catch(error) {
                    console.log(error);
                }
            }
        } catch(error) {
            console.log(error)
        }
    } catch (error) {
        console.log(error)
    }
}

// WORK
function* refreshToken(action) {
    try {
        const {refresh} = action.payload;
        const token = yield call(tokenRefreshRequest, refresh);
        yield put(refreshTokenSuccess(token.data.access));
        localStorage.setItem('accessToken', token.data.access);
        try {
            const mailboxes = yield call(allMailboxRequest, token.data.access);
            yield put(allMailboxesSuccess(mailboxes.data));
            // Stockage du token d'accès dans sessionStorage
            yield delay(3000);
            yield put(clearSuccessMessage());
            if(mailboxes.data.length === 0) {

            }
            // ALL EMAILS
            try {
                for(let i =0; i<mailboxes.data.length; i++){
                    // yield call(updateLocalDataRequest, token.data.access, mailboxes.data[i].id);
                    const emails = yield call(allEmailsOfMailboxRequest, token.data.access, mailboxes.data[i].id);
                    yield put(allEmailsOfMailboxSuccess(emails.data, mailboxes.data[i].name));
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    if(error.response.data.message){
                        const errorMessage = error.response.data.message;
                        yield put(showMessageError(errorMessage));
                    } else {
                        const errorData = error.response.data;
                        const errorMessage = Object.values(errorData)[0][0];
                        yield put(showMessageError(errorMessage));
                    }
                } else {
                    yield put(showMessageError("An error occurred while fetching mailboxes."));
                }
                yield delay(3000);
                yield put(clearErrorMessage());
            }
        } catch (error) {
            if (error.response && error.response.data) {
                if(error.response.data.message){
                    const errorMessage = error.response.data.message;
                    yield put(showMessageError(errorMessage));
                } else {
                    const errorData = error.response.data;
                    const errorMessage = Object.values(errorData)[0][0];
                    yield put(showMessageError(errorMessage));
                }
            } else {
                yield put(showMessageError("An error occurred while fetching mailboxes."));
            }
            yield delay(3000);
            yield put(clearErrorMessage());
        }
    } catch (error) {
        yield put(showMessageError("An error occurred while refreshing the access token."));
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

function* rejectToken(action) {
    try {
      yield call(tokenRejectRequest, action.payload);
      yield put(rejectTokenSuccess('Session removed'));
      // Suppression du token d'accès dans sessionStorage
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    } catch (error) {
      yield put(showMessageError(`An error occured while removing access. ${error.message}`));
      yield delay(3000);
      yield put(clearErrorMessage());
    }
}

// SYNCHRONIZE EMAIL
function* allMailboxes(action) {
    try {
        const response = yield call(allMailboxRequest, action.payload.token);
        yield put(allMailboxesSuccess(response.data));
        // Stockage du token d'accès dans sessionStorage
        yield delay(5000);
        yield put(clearSuccessMessage());

    } catch (error) {
        if (isAccessTokenExpiredError(error)) {
            // Access token expired, refresh the token and retry the synchronization
            const refreshToken = yield select(state => state.auth.refreshToken);
            yield put(retryWithTokenRefresh(allMailboxes(action.payload), refreshToken));
        } else {
            if (error.response && error.response.data) {
                if(error.response.data.message){
                    const errorMessage = error.response.data.message;
                    yield put(showMessageError(errorMessage));
                } else {
                    const errorData = error.response.data;
                    const errorMessage = Object.values(errorData)[0][0];
                    yield put(showMessageError(errorMessage));
                }
            } else {
                yield put(showMessageError("An error occurred while fetching mailboxes."));
            }
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

function* allEmailsOfMailbox(action) {
    try {
        const response = yield call(allEmailsOfMailboxRequest, action.payload.token, action.payload.mailboxId);
        yield put(allEmailsOfMailboxSuccess(response.data, action.payload.mailboxId));
        // Stockage du token d'accès dans sessionStorage
        yield delay(5000);
        yield put(clearSuccessMessage());
        yield call(watchObtainTokenPair);
    } catch (error) {
        if (isAccessTokenExpiredError(error)) {
            // Access token expired, refresh the token and retry the synchronization
            const refreshToken = yield select(state => state.auth.refreshToken);
            yield put(retryWithTokenRefresh(allEmailsOfMailbox(action.payload), refreshToken));
        } else {
            if (error.response && error.response.data) {
                if(error.response.data.message){
                    const errorMessage = error.response.data.message;
                    yield put(showMessageError(errorMessage));
                } else {
                    const errorData = error.response.data;
                    const errorMessage = Object.values(errorData)[0][0];
                    yield put(showMessageError(errorMessage));
                }
            } else {
                yield put(showMessageError("An error occurred while fetching mailboxes."));
            }
        }
    }
    yield delay(3000);
    yield put(clearErrorMessage());
}

// WORK
function* retrieveEmail(action){
    try {
        const response = yield call(retreiveEmailRequest, action.payload.token, action.payload.mailId);
        yield put(retrieveEmailSuccess(response.data));
        yield delay(3000);
        yield put(clearSuccessMessage());
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while fetching email."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// DELETE EMAIL
function* deleteEmailSaga(action) {
    const { token, mailId, mailbox } = action.payload;
    yield put(chargement())
    try {
        yield call(deleteEmailsRequest, token, mailId);
        yield put(deleteEmailSuccess(mailId, mailbox));
        // Stockage du token d'accès dans sessionStorage
        yield delay(5000);
        yield put(clearSuccessMessage());
    } catch (error) {
        console.log(error.response)
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while deleting email."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// UPDATE EMAIL
function* updateEmailSaga(action) {
    const { token, mailId, data } = action.payload;
    yield put(chargement())
    try {
        const updatedMail = yield call(updateEmailsRequest, token, mailId, data);
        // console.log(updatedMail)
        yield put(updateEmailSuccess(mailId, updatedMail.data));
    } catch (error) {
        // console.log(error.response)
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while updating email."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// UPDATE EMAIL
function* spamEmailSaga(action) {
    const { token, mailId, data } = action.payload;
    yield put(chargement())
    try {
        const updatedMail = yield call(updateEmailsRequest, token, mailId, data);
        // console.log(updatedMail)
        yield put(spamEmailSuccess(mailId, updatedMail.data));
    } catch (error) {
        // console.log(error.response)
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while updating email."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// UPDATE EMAIL
function* unspamEmailSaga(action) {
    const { token, mailId, data } = action.payload;
    yield put(chargement())
    try {
        const updatedMail = yield call(updateEmailsRequest, token, mailId, data);
        // console.log(updatedMail)
        yield put(unspamEmailSuccess(mailId, updatedMail.data));
    } catch (error) {
        // console.log(error.response)
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while updating email."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

function* emptyTrashSaga(action) {
    const { token, trashMailbox } = action.payload;
    yield put(chargement())
    try {
        const res = yield call(emptyTrashRequest, token);
        yield put(emptyTrashSuccess(res.data.message, trashMailbox));
        // Stockage du token d'accès dans sessionStorage
        yield delay(5000);
        yield put(clearSuccessMessage());
    } catch (error) {
        console.log(error.response)
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while empting email."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// DOWNLOAD ATTACHMENT
function* downloadAttachmentSaga(action) {
    try {
        const response = yield call(emailAttachmentRequest, action.payload.token, action.payload.attachmentId);
        yield put(emailAttachmentSuccess(response.data));
        // Stockage du token d'accès dans sessionStorage
        yield delay(5000);
        yield put(clearSuccessMessage());
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while geting file."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// SESSION VERIFICATION
function* verifySessionSaga(action) {
    const token = action.payload.token;
    try {
        const res = yield call(verifySessionRequest, token);
        yield put(verifySessionSuccess(res.data));
        // Stockage du token d'accès dans sessionStorage
        yield delay(3000);
        yield put(clearSuccessMessage());
        try {
            const mailboxes = yield call(allMailboxRequest, token);
            yield put(allMailboxesSuccess(mailboxes.data));
            // Stockage du token d'accès dans sessionStorage
            yield delay(3000);
            yield put(clearSuccessMessage());
            // ALL EMAILS
            try {
                for(let i =0; i<mailboxes.data.length; i++){
                    // yield call(updateLocalDataRequest, token.data.access, mailboxes.data[i].id);
                    const emails = yield call(allEmailsOfMailboxRequest, token, mailboxes.data[i].id);
                    yield put(allEmailsOfMailboxSuccess(emails.data, mailboxes.data[i].name));
                }
            } catch (error) {
                console.log(error.response)
                if (error.response && error.response.data) {
                    if(error.response.data.message){
                        const errorMessage = error.response.data.message;
                        yield put(showMessageError(errorMessage));
                    } else {
                        const errorData = error.response.data;
                        const errorMessage = Object.values(errorData)[0][0];
                        yield put(showMessageError(errorMessage));
                    }
                } else {
                    yield put(showMessageError("An error occurred while fetching mailboxes."));
                }
                yield delay(3000);
                yield put(clearErrorMessage());
            }
        } catch (error) {
            console.log(error.response)
            if (error.response && error.response.data) {
                if(error.response.data.message){
                    const errorMessage = error.response.data.message;
                    yield put(showMessageError(errorMessage));
                } else {
                    const errorData = error.response.data;
                    const errorMessage = Object.values(errorData)[0][0];
                    yield put(showMessageError(errorMessage));
                }
            } else {
                yield put(showMessageError("An error occurred while fetching mailboxes."));
            }
            yield delay(3000);
            yield put(clearErrorMessage());
        }
    } catch (error) {
        console.log(error.response)
        if (error.response && error.response.data) {
            yield put(showMessageError("Session expired."));
            try{
                const data = {
                    refresh: localStorage.getItem('refreshToken')
                }
                yield call(refreshToken, data);
            } catch(error) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
            }
            // recall this fonction saga
        } else {
            yield put(showMessageError("An error occurred while verifing session."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// SESSION CREATING
function* createSessionSaga(action) {
    const {token, auth} = action.payload;
    try {
        const res = yield call(addSessionRequest, token, auth);
        yield put(createSessionSuccess(res.data.message));
        // Stockage du token d'accès dans sessionStorage
        yield delay(5000);
        yield put(clearSuccessMessage());
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while creating file."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// SESSION DELETING
function* deleteSessionSaga(action) {
    try {
        const res = yield call(deleteSessionRequest, action.payload.token);
        yield put(deleteSessionSuccess(res.data));
        // Stockage du token d'accès dans sessionStorage
        yield delay(5000);
        yield put(clearSuccessMessage());
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while creating file."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// SESSION DELETING
function* checkInboxSaga(action) {
    try {
        const res = yield call(checkInboxRequest, action.payload.token);
        if(res.data.status === 'warning'){
            yield put(checkInboxSuccess(res.data.new_mails, res.data.spam_emails));
        } else if(res.data.status === 'error') {
            const mailboxes = yield select(state => state.mail.mailboxes);
            const inboxName = yield select(state => state.mail.inboxName);
            const mailbox = mailboxes.find(mb => mb.name === inboxName);
            yield call(updateLocalDataRequest, action.payload.token, mailbox.id);
        }
        // Stockage du token d'accès dans sessionStorage
        yield delay(5000);
        yield put(clearSuccessMessage());
    } catch (error) {
        console.log(error);
    }
}

// CHECK INBOX FOR EACH 20s
function* periodicCheckInbox() {
    while (true) {
        const accessToken = localStorage.getItem('accessToken');
        const mailboxes = yield select(state => state.mail.mailboxes);
        if (accessToken && mailboxes.length !== 0) {
            yield put(checkInbox(accessToken));
            yield delay(20000);
        } else {
            yield delay(60000);
        }
        
    }
}

// SENDING EMAIL
function* sendEmailSaga(action) {
    try {
        const response = yield call(sendEmailRequest, action.payload.token, action.payload.email, action.payload.sign_id);
        yield put(sendEmailSucess(response.data.message));
        const mailboxes = yield select(state => state.mail.mailboxes);
        const sentMailbox = mailboxes.find(mailbox => mailbox.name === 'Sent');
        const mailboxId = sentMailbox ? sentMailbox.id : null;
        if(mailboxId) {
            const emailSents = yield call(allEmailsOfMailboxRequest, action.payload.token, mailboxId);
            yield put(allEmailsOfMailboxSuccess(emailSents.data, sentMailbox.name));
        }
        // Stockage du token d'accès dans sessionStorage
        yield delay(1000);
        yield put(clearSuccessMessage());
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while sending email."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// DRAFT EMAIL
function* draftEmailSaga(action) {
    try {
        const response = yield call(draftEmailRequest, action.payload.token, action.payload.email);
        // console.log(response.data)
        yield put(draftEmailSuccess(response.data.message));

        const mailboxes = yield select(state => state.mail.mailboxes);
        const draftMailbox = mailboxes.find(mailbox => mailbox.name === 'Drafts');
        const mailboxId = draftMailbox ? draftMailbox.id : null;
    
        if(mailboxId) {
            const emailDrafts = yield call(allEmailsOfMailboxRequest, action.payload.token, mailboxId);
            yield put(allEmailsOfMailboxSuccess(emailDrafts.data, draftMailbox.name));
        }
        // Stockage du token d'accès dans sessionStorage
        yield delay(1000);
        yield put(clearSuccessMessage());
    } catch (error) {
        console.log(error.response.data)
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while drafting email."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// ALL CONTACT
function* allContactSaga(action) {
    const {token} = action.payload;
    try {
        const res = yield call(allContactsRequest, token);

        yield put(allContactSuccess(filtrerContacts(res.data)));
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while fetching user's contacts."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// CREATE CONTACT
function* createContactSaga(action) {
    const {token, data} = action.payload;
    yield put(chargement())
    try {
        const res = yield call(createContactRequest, token, data);
        yield put(createContactSuccess(res.data));
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while creating user's contacts."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
    
}

// UPDATE CONTACT
function* updateContactSaga(action) {
    const {token, contact_id, data} = action.payload;
    yield put(chargement())
    try {
        const res = yield call(updateContactRequest, token, contact_id, data);
        yield put(updateContactSuccess(res.data));
    } catch (error) {
        console.log(error.response);
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while updating user's contacts."));
        } yield delay(3000);
        yield put(clearErrorMessage());
    }
   
}

// DELETE CONTACT
function* deleteContactSaga(action) {
    const {token, contact_id} = action.payload;
    yield put(chargement())
    try {
        yield call(deleteContactRequest, token, contact_id);
        yield put(deleteContactSuccess(contact_id));
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while deleting user's contacts."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// ALL CONTACT
function* listModelSaga(action) {
    const {token} = action.payload;
    try {
        const res = yield call(listModelSignaturesRequest, token);
        // console.log(res.data)
        yield put(listModelSignatureSuccess(res.data));
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while fetching user's contacts."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// CREATE SIGNATURE VIA MODEL
function* createSignatureViaModelSaga(action) {
    const {token, model_id, signature} = action.payload;
    try {
        const res = yield call(createSignatureViaModelRequest, token, model_id, signature);
        yield put(createSignatureViaModelSuccess(res.data));
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while fetching user's contacts."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// CREATE SIGNATURE VIA MODEL
function* createSignatureSaga(action) {
    const {token, signature} = action.payload;
    try {
        const res = yield call(createSignatureRequest, token, signature);
        yield put(createSignatureSuccess(res.data));
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while fetching user's contacts."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// CREATE SIGNATURE VIA MODEL
function* listSignatureSaga(action) {
    const {token} = action.payload;
    try {
        const res = yield call(listSignatureRequest, token);
        yield put(listSignatureSuccess(res.data));
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while fetching user's contacts."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// CREATE SIGNATURE VIA MODEL
function* deleteSignatureSaga(action) {
    const {token, signature_id} = action.payload;
    try {
        const del = yield call(deleteSignatureRequest, token, signature_id);
        console.log(del)
        yield put(deleteSignatureSuccess(signature_id));
    } catch (error) {
        if (error.response && error.response.data) {
            if(error.response.data.message){
                const errorMessage = error.response.data.message;
                yield put(showMessageError(errorMessage));
            } else {
                const errorData = error.response.data;
                const errorMessage = Object.values(errorData)[0][0];
                yield put(showMessageError(errorMessage));
            }
        } else {
            yield put(showMessageError("An error occurred while fetching user's contacts."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

// ============================================================ | RH | ===================================================================//
function* activateRHSaga(action) {
    const token = action.payload.token;
    try {
        const res = yield call(activateRHRequest, token);
        yield put(activateRHSuccess(res.data.user));
        // Stockage du token d'accès dans sessionStorage
        yield delay(3000);
        yield put(clearSuccessMessage());
        try {
            const mailboxes = yield call(allMailboxRequest, token);
            yield put(allMailboxesSuccess(mailboxes.data));
            // Stockage du token d'accès dans sessionStorage
            yield delay(3000);
            yield put(clearSuccessMessage());
            // ALL EMAILS
            try {
                for(let i =0; i<mailboxes.data.length; i++){
                    // yield call(updateLocalDataRequest, token.data.access, mailboxes.data[i].id);
                    const emails = yield call(allEmailsOfMailboxRequest, token, mailboxes.data[i].id);
                    yield put(allEmailsOfMailboxSuccess(emails.data, mailboxes.data[i].name));
                }
            } catch (error) {
                console.log(error.response)
                if (error.response && error.response.data) {
                    if(error.response.data.message){
                        const errorMessage = error.response.data.message;
                        yield put(showMessageError(errorMessage));
                    } else {
                        const errorData = error.response.data;
                        const errorMessage = Object.values(errorData)[0][0];
                        yield put(showMessageError(errorMessage));
                    }
                } else {
                    yield put(showMessageError("An error occurred while fetching mailboxes."));
                }
                yield delay(3000);
                yield put(clearErrorMessage());
            }
        } catch (error) {
            console.log(error.response)
            if (error.response && error.response.data) {
                if(error.response.data.message){
                    const errorMessage = error.response.data.message;
                    yield put(showMessageError(errorMessage));
                } else {
                    const errorData = error.response.data;
                    const errorMessage = Object.values(errorData)[0][0];
                    yield put(showMessageError(errorMessage));
                }
            } else {
                yield put(showMessageError("An error occurred while fetching mailboxes."));
            }
            yield delay(3000);
            yield put(clearErrorMessage());
        }
    } catch (error) {
        console.log(error.response)
        if (error.response && error.response.data) {
            yield put(showMessageError("Session expired."));
            try{
                const data = {
                    refresh: localStorage.getItem('refreshToken')
                }
                yield call(refreshToken, data);
            } catch(error) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
            }
            // recall this fonction saga
        } else {
            yield put(showMessageError("An error occurred while verifing session."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

function* deactivateRHSaga(action) {
    const token = action.payload.token;
    try {
        const res = yield call(deactivateRHRequest, token);
        yield put(deactivateRHSuccess(res.data.user));
        // Stockage du token d'accès dans sessionStorage
        yield delay(3000);
        yield put(clearSuccessMessage());
        try {
            const mailboxes = yield call(allMailboxRequest, token);
            yield put(allMailboxesSuccess(mailboxes.data));
            // Stockage du token d'accès dans sessionStorage
            yield delay(3000);
            yield put(clearSuccessMessage());
            // ALL EMAILS
            try {
                for(let i =0; i<mailboxes.data.length; i++){
                    // yield call(updateLocalDataRequest, token.data.access, mailboxes.data[i].id);
                    const emails = yield call(allEmailsOfMailboxRequest, token, mailboxes.data[i].id);
                    yield put(allEmailsOfMailboxSuccess(emails.data, mailboxes.data[i].name));
                }
            } catch (error) {
                console.log(error.response)
                if (error.response && error.response.data) {
                    if(error.response.data.message){
                        const errorMessage = error.response.data.message;
                        yield put(showMessageError(errorMessage));
                    } else {
                        const errorData = error.response.data;
                        const errorMessage = Object.values(errorData)[0][0];
                        yield put(showMessageError(errorMessage));
                    }
                } else {
                    yield put(showMessageError("An error occurred while fetching mailboxes."));
                }
                yield delay(3000);
                yield put(clearErrorMessage());
            }
        } catch (error) {
            console.log(error.response)
            if (error.response && error.response.data) {
                if(error.response.data.message){
                    const errorMessage = error.response.data.message;
                    yield put(showMessageError(errorMessage));
                } else {
                    const errorData = error.response.data;
                    const errorMessage = Object.values(errorData)[0][0];
                    yield put(showMessageError(errorMessage));
                }
            } else {
                yield put(showMessageError("An error occurred while fetching mailboxes."));
            }
            yield delay(3000);
            yield put(clearErrorMessage());
        }
    } catch (error) {
        console.log(error.response)
        if (error.response && error.response.data) {
            yield put(showMessageError("Session expired."));
            try{
                const data = {
                    refresh: localStorage.getItem('refreshToken')
                }
                yield call(refreshToken, data);
            } catch(error) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
            }
            // recall this fonction saga
        } else {
            yield put(showMessageError("An error occurred while verifing session."));
        }
        yield delay(3000);
        yield put(clearErrorMessage());
    }
}

/**
 * WATCHER FOR ALL FONCTIONS SAGA
 */
// WATCHER SAGA
export function* watchListConnexion() {
    yield takeEvery(LIST_CONNEXION, allConnexion);
}

export function* watchCreateConnexion() {
    yield takeEvery(CREATE_CONNEXION, createConnexion);
}

export function* watchUpdateConnexion() {
    yield takeEvery(UPDATE_CONNEXION, updateConnexion)
}

export function* watchDeleteConnexion() {
    yield takeEvery(DELETE_CONNEXION, deleteConnexion)
}

export function* watchObtainTokenPair(){
    yield takeEvery(TOKEN_OBTAIN_PAIR, obtainAccessToken)
}

export function* watchRefreshToken() {
    yield takeEvery(TOKEN_REFRESH, refreshToken)
}

export function* watchRejectToken() {
    yield takeEvery(TOKEN_REJECT, rejectToken)
}

export function* watchSynchronize() {
    yield takeEvery(SYNCHRONIZE_EMAIL, synchronizeEmail)
}

export function* watchAllMailboxes() {
    yield takeEvery(ALL_MAILBOX, allMailboxes)
}

export function* watchAllEmailsOfMailbox() {
    yield takeEvery(ALL_EMAILS_OF_MAILBOX, allEmailsOfMailbox)
}

export function* watchRetrieveEmail() {
    yield takeEvery(RETREIVE_EMAIL, retrieveEmail)
}

export function* watchUpdateEmail()  {
    yield takeEvery(UPDATE_EMAIL, updateEmailSaga)
}

export function* watchSpamEmail()  {
    yield takeEvery(SPAM_EMAIL, spamEmailSaga)
}

export function* watchUnspamEmail()  {
    yield takeEvery(UNSPAM_EMAIL, unspamEmailSaga)
}

export function* watchDeleteEmail()  {
    yield takeEvery(DELETE_EMAIL, deleteEmailSaga)
}

export function* watchDownloadAttachment() {
    yield takeEvery(EMAIL_ATTACHMENT, downloadAttachmentSaga)
}

export function* watchSendEmail() {
    yield takeEvery(SEND_EMAIL, sendEmailSaga)
}

export function* watchDraftEmail() {
    yield takeEvery(DRAFT_EMAIL, draftEmailSaga)
}

export function* watchVerifySession() {
    yield takeEvery(VERIFY_SESSION, verifySessionSaga)
}

export function* watchCreateSession() {
    yield takeEvery(CREATE_SESSION, createSessionSaga)
}

export function* watchDeleteSession() {
    yield takeEvery(DELETE_SESSION, deleteSessionSaga)
}

export function* watchCheckInboxSaga() {
    yield takeEvery(CHECK_INBOX, checkInboxSaga)
}

export function* watchEmptyTrash() {
    yield takeEvery(EMPTY_TRASH, emptyTrashSaga)
}

export function* watchAllContact() {
    yield takeEvery(LIST_CONTACT, allContactSaga)
}

export function* watchCreateContact() {
    yield takeEvery(CREATE_CONTACT, createContactSaga)
}

export function* watchUpdateContact() {
    yield takeEvery(UPDATE_CONTACT, updateContactSaga)
}

export function* watchDeleteContact() {
    yield takeEvery(DELETE_CONTACT, deleteContactSaga)
}

// =========================================================================|SIGNATURE|===================================================//
export function* watchListModel() {
    yield takeEvery(LIST_MODEL_SIGNATURE, listModelSaga)
}

export function* watchCreateSignatureViaModel() {
    yield takeEvery(CREATE_SIGNATURE_VIA_MODEL, createSignatureViaModelSaga);
}

export function* watchCreateSignature() {
    yield takeEvery(CREATE_SIGNATURE, createSignatureSaga);
}

export function* watchListSignature() {
    yield takeEvery(LIST_SIGNATURE, listSignatureSaga);
}

export function* watchDeleteSignature() {
    yield takeEvery(DELETE_SIGNATURE, deleteSignatureSaga);
}

// =========================================================== | RH | ============================================================================//
export function* watchActivateRHSaga() {
    yield takeEvery(ACTIVATE_RH, activateRHSaga);
}

export function* watchDeactivateRHSaga() {
    yield takeEvery(DEACTIVATE_RH, deactivateRHSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateConnexion),
    fork(watchListConnexion),
    fork(watchUpdateConnexion),
    fork(watchDeleteConnexion),
    fork(watchObtainTokenPair),
    fork(watchRefreshToken),
    fork(watchRejectToken),
    fork(watchSynchronize),
    fork(watchAllMailboxes),
    fork(watchAllEmailsOfMailbox),
    fork(watchRetrieveEmail),
    fork(watchDeleteEmail),
    fork(watchUpdateEmail),
    fork(watchSpamEmail),
    fork(watchUnspamEmail),
    fork(watchDownloadAttachment),
    fork(watchSendEmail),
    fork(watchDraftEmail),
    fork(watchVerifySession),
    fork(watchCreateSession),
    fork(watchDeleteSession),
    fork(watchCheckInboxSaga),
    fork(periodicCheckInbox),
    fork(watchEmptyTrash),
    fork(watchAllContact),
    fork(watchCreateContact),
    fork(watchUpdateContact),
    fork(watchDeleteContact),
    fork(watchListModel),
    fork(watchCreateSignatureViaModel),
    fork(watchCreateSignature),
    fork(watchListSignature),
    fork(watchDeleteSignature),
    fork(watchActivateRHSaga),
    fork(watchDeactivateRHSaga)
  ]);
}