import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { currentProject } from 'redux/actions/manage-project/project.action';
import { loadRole } from 'redux/actions/manage-project/role';
import { getTimersAction } from 'redux/actions/timer';

const AppViewsComponent = ({
  current,
  currentProject,
  getTimersAction,
  loadRole,
}) => {
  const currentProjectId = localStorage.getItem('__CURRENT_PROJECT__');

  useEffect(() => {
    if (currentProjectId) {
      currentProject(currentProjectId);
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (current) {
      getTimersAction({ projectId: current._id });
      loadRole(current._id);
    }
  }, [current]);

  if (current)
    return (
      <Suspense fallback={<Loading cover="content" />}>
        <Switch>
          {/* <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        /> */}
          <Route
            path={`${APP_PREFIX_PATH}/apps`}
            component={lazy(() => import(`./apps`))}
          />
          <Redirect
            from={`${APP_PREFIX_PATH}`}
            to={`${APP_PREFIX_PATH}/apps/dashboard`}
          />
        </Switch>
      </Suspense>
    );
  return <Loading cover="content" />;
};
const mapStateToProps = ({ project }) => {
  const { current } = project;
  return { current };
};
const mapDispatchToProps = {
  currentProject,
  getTimersAction,
  loadRole,
};

export const AppViews = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppViewsComponent)
);
export default React.memo(AppViews);
