import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ExtraViews from 'views/extra-views';
import Loading from 'components/shared-components/Loading';
import { useThemeSwitcher } from 'react-css-theme-switcher';

export const ExtraLayout = () => {
  const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  return (
    <div className="auth-container">
      <Switch>
        <Route path="" component={ExtraViews} />
      </Switch>
    </div>
  );
};

export default ExtraLayout;
