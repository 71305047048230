import {
  AGENDA_ERROR,
  AGENDA_MESSAGE,
  CREATE_AGENDA,
  CREATE_AGENDA_SUCCESS,
  DELETE_AGENDA,
  DELETE_AGENDA_SUCCESS,
  GET_BY_PROJECT_AGENDA_SUCCESS,
  LOAD_AGENDA_SUCCESS,
  UPDATE_AGENDA,
  UPDATE_AGENDA_SUCCESS,
  GET_BY_USER_AGENDA_SUCCESS,
  LOAD_AGENDA,
  IS_NOTIFIED,
} from 'redux/constants/manage-project/agenda.type';

export const notifAgenda = (value) => {
  return {
    type: IS_NOTIFIED,
    payload: value,
  };
};

export const loadAgenda = (userId, email) => {
  return {
    type: LOAD_AGENDA,
    payload: { userId, email },
  };
};

export const createAgenda = (agenda) => {
  return {
    type: CREATE_AGENDA,
    payload: { agenda },
  };
};

export const updateAgenda = (agendaID, agenda) => {
  return {
    type: UPDATE_AGENDA,
    payload: {
      agenda,
      agendaID,
    },
  };
};

export const deleteAgenda = (agendaID) => {
  return {
    type: DELETE_AGENDA,
    payload: agendaID,
  };
};

export const loadAgendaSuccess = (agendas) => {
  return {
    type: LOAD_AGENDA_SUCCESS,
    payload: agendas,
  };
};

export const deleteAgendaSuccess = (deleted) => {
  return {
    type: DELETE_AGENDA_SUCCESS,
    payload: deleted,
  };
};

export const updateAgendaSuccess = (updated) => {
  return {
    type: UPDATE_AGENDA_SUCCESS,
    payload: updated,
  };
};

export const createdAgendaSuccess = (created) => {
  return {
    type: CREATE_AGENDA_SUCCESS,
    payload: created,
  };
};

export const AgendaError = (msg) => {
  return {
    type: AGENDA_ERROR,
    payload: msg,
  };
};

export const showAgendaMessage = (message) => {
  return {
    type: AGENDA_MESSAGE,
    message,
  };
};

export const loadAgendaByUserSuccess = (agendas) => {
  return {
    type: GET_BY_USER_AGENDA_SUCCESS,
    payload: {
      agendas: agendas,
    },
  };
};
