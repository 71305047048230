export const CREATE_ROLE = 'CREATE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const LOAD_BY_PROJECT_ROLE = 'LOAD_BY_PROJECT_ROLE';
export const LOAD_ROLE_BY_ID = 'LOAD_ROLE_BY_ID';

export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const LOAD_BY_PROJECT_ROLE_SUCCESS = 'LOAD_BY_PROJECT_ROLE_SUCCESS';
export const LOAD_ROLE_BY_ID_SUCCESS = 'LOAD_ROLE_BY_ID_SUCCESS';

export const ROLE_ERROR = 'ROLE_ERROR';
export const ROLE_MESSAGE = 'ROLE_MESSAGE';
