import {
  AGENDA_MESSAGE,
  CREATE_AGENDA_SUCCESS,
  DELETE_AGENDA_SUCCESS,
  GET_BY_USER_AGENDA_SUCCESS,
  IS_NOTIFIED,
  LOAD_AGENDA_SUCCESS,
  UPDATE_AGENDA_SUCCESS,
} from 'redux/constants/manage-project/agenda.type';

const initialState = {
  agendaData: [],
  loading: false,
  message: '',
  isNotified: false,
};

function AgendaReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_AGENDA_SUCCESS:
      return { ...state, loading: false, agendaData: action.payload };
    case CREATE_AGENDA_SUCCESS:
      return {
        ...state,
        isNotified: false,
        agendaData: [action.payload, ...state.agendaData],
      };
    case UPDATE_AGENDA_SUCCESS:
      const updated = state.agendaData.map((l) =>
        l._id === action.payload._id ? action.payload : l
      );
      return {
        ...state,
        loading: false,
        isNotified: false,
        agendaData: updated,
      };
    case DELETE_AGENDA_SUCCESS:
      const deleted = state.agendaData.filter(
        (l) => l._id !== action.payload._id
      );
      return { ...state, loading: false, agendaData: deleted };

    case AGENDA_MESSAGE:
      return {
        ...state,
        message: action.message,
        loading: false,
      };
    case IS_NOTIFIED:
      return {
        ...state,
        isNotified: action.payload,
      };

    case GET_BY_USER_AGENDA_SUCCESS:
      let { agendas } = action.payload;
      return state;

    default:
      return state;
  }
}

export default AgendaReducer;
