import axios from 'axios';
import { API_BASE_URL_MAIL } from 'constants/ApiConstant';

// axios.defaults.withCredentials = true;
// BASE URL
const url = API_BASE_URL_MAIL + "api/mail_clients";

// CONTENT-TYPE
const json = 'application/json';
const multipart = 'multipart/form-data';

// GENEREATE HEADER FOR REQUEST
const generateHeader = (ct, token=undefined) => {
    let headers = {
        'Content-Type': ct,
    };
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return {
        headers: headers
    };
};

// CREATE CONNEXION
export const createConnexionRequest = (data) => {
    const config = generateHeader(json)
    config.withCredentials = true;
    return axios.post(`${url}/create-connexion/`, data, config)
}

// LIST CONNEXIONS
export const listConnexionRequest = (user_id) => {
    return axios.get(`${url}/connexions/${user_id}/`)
}

// UPDATE CONNEXION
export const updateConnexionRequest = (user_id, connexion_id, data) => {
    const config = generateHeader(json)
    config.withCredentials = true;
    return axios.put(`${url}/users/${user_id}/update-connexion/${connexion_id}/`, data, config)
}

// DELETE CONNEXION
export const deleteConnexionRequest = (user_id, connexion_id) => {
    return axios.delete(`${url}/users/${user_id}/delete-connexion/${connexion_id}/`);
};

// GET ACCESS TOKEN FOR EXISTING ACCOUNT
export const tokenObtainPairRequest = (data) => {
    const config = generateHeader(json)
    config.withCredentials = true;
    return axios.post(`${url}/auth-token/`, data, config);
};

// UPDATE TOKEN IF EXPIRED
export const tokenRefreshRequest = (data) => {
    const config = generateHeader(json)
    config.withCredentials = true;
    return axios.post(`${url}/auth-token/refresh/`, data, config);
};

// REJECT A REFRESH TOKEN
export const tokenRejectRequest = (data) => {
    const config = generateHeader(json)
    config.withCredentials = true;
    return axios.post(`${url}/auth-token/reject/`, data, config);
};

// SYNCHONIZE EMAIL
export const synchronizeEmailRequest = (token, data) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.post(`${url}/users/synchronize/`, data, config);
}

// SESSION VERIFICATION
export const retrieveConnexionRequest = (token) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/retrieve-connexion/`, config);
}

// SESSION VERIFICATION
export const verifySessionRequest = (token) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/user-sessions/`, config);
}

// ADD VERIFICATION
export const addSessionRequest = (token, auth) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.post(`${url}/user-sessions/`, auth, config);
}

// DELETE VERIFICATION
export const deleteSessionRequest = (token) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.delete(`${url}/user-sessions/`, config);
}

// // UNSYNCHONIZE EMAIL
// export const unsynchronize_email = (token) => {
//     const config = generateHeader(json, token)
//     return axios.delete(`${url}/users/unsynchronize/`, config);
// }

// GET LIST OF MAILBOX
export const allMailboxRequest = (token) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/users/mailbox/`, config);
}

// GET LIST OF MAILBOX
export const allEmailsOfMailboxRequest = (token, box_id) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/users/mailbox/${box_id}/emails/`, config);
}

// RETRIEVE AN EMAIL
export const retreiveEmailRequest = (token, mailId) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/users/emails/${mailId}/`, config);
}
// UNSYNCHONIZE EMAIL
export const unsynchronizeEmailRequest = (token) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.delete(`${url}/users/unsynchronize/`, config);
}

// UPDATE LOCAL DATA FOR A MAILBOX
export const updateLocalDataRequest = (token, box_id) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/users/update-local-data/mailbox/${box_id}/`, config);
}

// EMPTY TRASH
export const emptyTrashRequest = (token) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.delete(`${url}/users/empty-trash/`, config);
}

// DELETE AN EMAIL
export const deleteEmailsRequest = (token, email_id) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.delete(`${url}/users/emails/${email_id}/`, config);
}

// DELETE AN EMAIL
export const updateEmailsRequest = (token, email_id, data) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.put(`${url}/users/emails/${email_id}/`, data, config);
}

// GET ATTACHEMENTS DOWNLOAD LINK
export const emailAttachmentRequest = (token, attachment_id) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/users/attachment/${attachment_id}/`, config);
}

// SEND EMAIL
export const sendEmailRequest = (token, data, sign_id) => {
    let query = sign_id ? `?signature=${sign_id}` : '';
    const config = generateHeader(multipart, token)
    config.withCredentials = true;
    return axios.post(`${url}/users/send_emails/${query}`, data, config);
}

// DRAFT EMAIL
export const draftEmailRequest = (token, data) => {
    const config = generateHeader(multipart, token)
    config.withCredentials = true;
    return axios.post(`${url}/users/draft_emails/`, data, config);
}

// CHECK NEW MAIL AT INBOX
export const checkInboxRequest = (token) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/users/inbox/email_check/`, config);
}

// CONTACT
export const allContactsRequest = (token) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/users/contacts/`, config);
}

// CONTACT CREATE
export const createContactRequest = (token, data) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.post(`${url}/users/create-contacts/`, data, config);
}

// CONTACT RETRIEVE
export const retrieveContactRequest = (token, id) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/users/contacts/${id}`, config);
}

// CONTACT UPDATE
export const updateContactRequest = (token, id, data) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.put(`${url}/users/contacts/${id}/`, data, config);
}

// CONTACT DELETE
export const deleteContactRequest = (token, id) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.delete(`${url}/users/contacts/${id}/`, config);
}

// ======================================================================================| RH | ====================================================================//
export const activateRHRequest = (token) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/users/activate-rh/`, config);
}

export const deactivateRHRequest = (token) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/users/deactivate-rh/`, config);
}
// =======================================================================================|SIGNATURE|============================================================== //
// GET LIST OF MODEL SIGNATURE
export const listModelSignaturesRequest = (token) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/models_signatures/`, config);
}

// CREATE NEW MAIL SIGNATURE VIA PREDEFINED MODEL
export const createSignatureViaModelRequest = (token, model_id, data) => {
    const config = generateHeader(multipart, token)
    config.withCredentials = true;
    return axios.post(`${url}/users/create-signatures/models_signatures/${model_id}/`, data, config);
}

// CREATE NEW MAIL SIGNATURE
export const createSignatureRequest = (token, data) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.post(`${url}/users/create-signatures/`, data, config);
}

// LIST OF USER SIGNATURES
export const listSignatureRequest = (token) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/users/signatures/`, config);
}

// DELETE AN USER SIGNATURE
export const deleteSignatureRequest = (token, signature_id) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.delete(`${url}/users/signatures/${signature_id}/`, config);
}

// RETREIVE AN USER SIGNATURE
export const retrieveSignatureRequest = (token, signature_id) => {
    const config = generateHeader(json, token)
    config.withCredentials = true;
    return axios.get(`${url}/users/signatures/${signature_id}/`, config);
}