import {
  ADD_MESSAGE_SUCCESS,
  LOAD_MESSAGE_SUCCESS,
} from 'redux/constants/chat/message';

const initialState = [];

function MessageReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_MESSAGE_SUCCESS:
      return action.payload;

    case ADD_MESSAGE_SUCCESS:
      return [...state, action.payload];
    default:
      return state;
  }
}

export default MessageReducer;
