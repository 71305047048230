import axios from 'axios';
import { API_BASE_URL } from 'constants/ApiConstant';

const url = API_BASE_URL + 'checklist/';

export const createChecklist = (data) => {
  return axios.post(`${url}`, data);
};

export const updateChecklist = (id, data) => {
  return axios.put(`${url}${id}`, data);
};

export const deleteChecklist = (id) => {
  return axios.delete(`${url}${id}`);
};

export const loadChecklistByTask = (taskId) => {
  return axios.get(`${url}${taskId}`);
};
