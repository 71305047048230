import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { getTimersActionSuccess, showTimerMessage } from 'redux/actions/timer';
import { GET_TIMERS } from 'redux/constants/timer';
import { getTimersAPI } from 'services/TimerService';

export function* getTimers() {
  yield takeEvery(GET_TIMERS, function* ({ payload }) {
    const { projectId } = payload;
    try {
      if (projectId) {
        const response = yield call(getTimersAPI, projectId);
        if (response.data?.data) {
          yield put(getTimersActionSuccess(response.data.data));
        }
      }
    } catch (error) {
      yield put(showTimerMessage(error.response ? error.response.data : error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getTimers)]);
}
