import axios from "axios";
import { API_BASE_URL } from "constants/ApiConstant";

const url = API_BASE_URL + "task/";

export const created = (data, sprintId) => {
  return axios.post(`${url}?sprint=${sprintId}`, data);
};

export const updated = (id, data) => {
  return axios.put(`${url}${id}`, data);
};

export const setMembersReuqest = (id, data) => {
  return axios.put(`${url}${id}/members`, data);
};

export const setLabelsReuqest = (id, data) => {
  return axios.put(`${url}${id}/labels`, data);
};

export const setDescriptionReuqest = (id, data) => {
  return axios.put(`${url}${id}/description`, data);
};

export const setStoryPointRequest = (id, data) => {
  return axios.put(`${url}${id}/storyPoint`, data);
};

export const deleted = (id,sprintId) => {
  return axios.delete(`${url}${id}?sprint=${sprintId}`);
};

export const loaded = () => {
  return axios.get(`${url}`);
};

export const loadByList = (id) => {
  return axios.get(`${url}${id}`);
};

export const loadByID = (id) => {
  return axios.get(`${url}id/${id}`);
};
