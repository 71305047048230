export const LOAD_AGENDA = 'LOAD_AGENDA';
export const CREATE_AGENDA = 'CREATE_AGENDA';
export const UPDATE_AGENDA = 'UPDATE_AGENDA';
export const DELETE_AGENDA = 'DELETE_AGENDA';

export const GET_BY_USER_AGENDA = 'GET_BY_USER_AGENDA';

export const AGENDA_MESSAGE = 'AGENDA_MESSAGE';
export const AGENDA_ERROR = 'AGENDA_ERROR';

export const LOAD_AGENDA_SUCCESS = 'LOAD_AGENDA_SUCCESS';
export const CREATE_AGENDA_SUCCESS = 'CREATE_AGENDA_SUCCESS';
export const UPDATE_AGENDA_SUCCESS = 'UPDATE_AGENDA_SUCCESS';
export const DELETE_AGENDA_SUCCESS = 'DELETE_AGENDA_SUCCESS';
export const GET_BY_USER_AGENDA_SUCCESS = 'GET_BY_USER_AGENDA_SUCCESS';

export const IS_NOTIFIED = 'IS_NOTIFIED';
