import {
  ACCEPT_INVITATION,
  ACCEPT_INVITATION_SUCCESS,
  CHANNEL_ERROR,
  CHANNEL_MESSAGE,
  CREATE_CHANNEL,
  CREATE_CHANNEL_SUCCESS,
  CURRENT_CHANNEL,
  CURRENT_CHANNEL_SUCCESS,
  DELETE_CHANNEL,
  DELETE_CHANNEL_SUCCESS,
  DELETE_INVITATION,
  DELETE_INVITATION_SUCCESS,
  INVITATION_RECEIVED,
  INVITATION_RECEIVED_SUCCESS,
  INVIT_USER,
  INVIT_USER_SUCCESS,
  LOAD_CHANNEL,
  LOAD_CHANNEL_SUCCESS,
  LOAD_INVITATION_BY_ME,
  LOAD_INVITATION_BY_ME_SUCCESS,
  LOAD_USER_CHANNEL,
  LOAD_USER_CHANNEL_SUCCESS,
  UPDATE_CHANNEL,
  UPDATE_CHANNEL_SUCCESS,
} from 'redux/constants/chat/channel.type';

export const loadMyChannel = (wID, uId, pId) => {
  return {
    type: LOAD_CHANNEL,
    payload: { wID, uId , pId},
  };
};

export const acceptInvitation = (joinId) => {
  return {
    type: ACCEPT_INVITATION,
    payload: { joinId },
  };
};

export const cancelInvitation = (joinId) => {
  return {
    type: DELETE_INVITATION,
    payload: { joinId },
  };
};

export const acceptInvitationSuccess = (data) => {
  return {
    type: ACCEPT_INVITATION_SUCCESS,
    payload: data,
  };
};

export const cancelInvitationSuccess = (data) => {
  return {
    type: DELETE_INVITATION_SUCCESS,
    payload: data,
  };
};

export const loadUserChannel = (channelID) => {
  return {
    type: LOAD_USER_CHANNEL,
    payload: { channelID },
  };
};

export const loadInvitationByMe = (userId) => {
  return {
    type: LOAD_INVITATION_BY_ME,
    payload: { userId },
  };
};

export const loadInvitationReceived = (userId) => {
  return {
    type: INVITATION_RECEIVED,
    payload: { userId },
  };
};

export const loadInvitationByMeSuccess = (data) => {
  return {
    type: LOAD_INVITATION_BY_ME_SUCCESS,
    payload: data,
  };
};

export const loadInvitationReceivedSuccess = (data) => {
  return {
    type: INVITATION_RECEIVED_SUCCESS,
    payload: data,
  };
};

export const sendInvitation = (channelID, data) => {
  return {
    type: INVIT_USER,
    payload: { channelID, data },
  };
};

export const sendInvitationSuccess = (data) => {
  return {
    type: INVIT_USER_SUCCESS,
    payload: data,
  };
};

export const currentChannel = (channelID) => {
  return {
    type: CURRENT_CHANNEL,
    payload: { channelID },
  };
};

export const createChannel = (wID, channel) => {
  return {
    type: CREATE_CHANNEL,
    payload: { channel, wID },
  };
};

export const updateChannel = (channelID, channel) => {
  return {
    type: UPDATE_CHANNEL,
    payload: {
      channel,
      channelID,
    },
  };
};

export const deleteChannel = (channelID) => {
  return {
    type: DELETE_CHANNEL,
    payload: channelID,
  };
};

export const loadChannelSuccess = (channels) => {
  return {
    type: LOAD_CHANNEL_SUCCESS,
    payload: channels,
  };
};

export const loadUserChannelSuccess = (users) => {
  return {
    type: LOAD_USER_CHANNEL_SUCCESS,
    payload: users,
  };
};

export const currentChannelSuccess = (channel) => {
  return {
    type: CURRENT_CHANNEL_SUCCESS,
    payload: channel,
  };
};

export const deleteChannelSuccess = (deleted) => {
  return {
    type: DELETE_CHANNEL_SUCCESS,
    payload: deleted,
  };
};

export const updateChannelSuccess = (updated) => {
  return {
    type: UPDATE_CHANNEL_SUCCESS,
    payload: updated,
  };
};

export const createdChannelSuccess = (created) => {
  return {
    type: CREATE_CHANNEL_SUCCESS,
    payload: created,
  };
};

export const ChannelError = (msg) => {
  return {
    type: CHANNEL_ERROR,
    payload: msg,
  };
};

export const showChannelMessage = (message) => {
  return {
    type: CHANNEL_MESSAGE,
    message,
  };
};
