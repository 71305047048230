import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  createdProjectSuccess,
  currentProjectSuccess,
  deleteProjectSuccess,
  loadByWorkspaceSuccess,
  loadProjectByOwner,
  loadProjectByOwnerSuccess,
  loadProjectSuccess,
  showProjectMessage,
  updateProjectSuccess,
  allUsersProjectSuccess,
  allLabelsProjectSuccess,
  createLabelSuccess,
  deleteLabelSuccess
} from 'redux/actions/manage-project/project.action';
import {
  CREATE_LABEL,
  CREATE_PROJECT,
  CURRENT_PROJECT,
  DELETE_LABEL,
  DELETE_PROJECT,
  LOAD_ALL_LABELS_OF_PROJECT,
  LOAD_ALL_USERS_OF_PROJECT,
  LOAD_BY_OWNER,
  LOAD_BY_WORKSPACE_PROJECT,
  LOAD_PROJECT,
  UPDATE_PROJECT,
} from 'redux/constants/manage-project/project.type';
import {
  created,
  deleted,
  loadByID,
  loaded,
  loadProjectByWorkspace,
  updated,
  allUsers,
  allLabels,
  createdLabelRequest,
  deletedLabelRequest
} from 'services/ProjectService';

export function* add() {
  yield takeEvery(CREATE_PROJECT, function* ({ payload }) {
    const { project } = payload;
    try {
      const response = yield call(created, project);
      if (response.data.message) {
        yield put(showProjectMessage(response.data.message));
      } else {
        yield put(createdProjectSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showProjectMessage(err.response ? err.response.data : err));
    }
  });
}

export function* allProject() {
  yield takeEvery(LOAD_PROJECT, function* () {
    try {
      const response = yield call(loaded);
      if (response.data.message) {
        yield put(showProjectMessage(response.data.message));
      } else {
        yield put(loadProjectSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showProjectMessage(err.response ? err.response.data : err));
    }
  });
}

export function* update() {
  yield takeEvery(UPDATE_PROJECT, function* ({ payload }) {
    const { project, projectID } = payload;
    try {
      const response = yield call(updated, projectID, project);
      if (response.data.message) {
        yield put(showProjectMessage(response.data.message));
      } else {
        yield put(updateProjectSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showProjectMessage(err.response ? err.response.data : err));
    }
  });
}

export function* del() {
  yield takeEvery(DELETE_PROJECT, function* ({ payload }) {
    try {
      const response = yield call(deleted, payload);
      if (response.data.message) {
        yield put(showProjectMessage(response.data.message));
      } else {
        yield put(deleteProjectSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showProjectMessage(err.response ? err.response.data : err));
    }
  });
}

export function* loadByOwner() {
  yield takeEvery(LOAD_BY_OWNER, function* ({ payload }) {
    const { userID } = payload;
    try {
      const response = yield call(loadProjectByOwner, userID);
      if (response.data.message) {
        yield put(showProjectMessage(response.data.message));
      } else {
        yield put(loadProjectByOwnerSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showProjectMessage(err.response ? err.response.data : err));
    }
  });
}

export function* loadByWorkspace() {
  yield takeEvery(LOAD_BY_WORKSPACE_PROJECT, function* ({ payload }) {
    const { workspaceID } = payload;
    try {
      const response = yield call(loadProjectByWorkspace, workspaceID);
      if (response.data.message) {
        yield put(showProjectMessage(response.data.message));
      } else {
        yield put(loadByWorkspaceSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showProjectMessage(err.response ? err.response.data : err));
    }
  });
}

export function* current() {
  yield takeEvery(CURRENT_PROJECT, function* ({ payload }) {
    try {
      const response = yield call(loadByID, payload);
      if (response.data.message) {
        yield put(showProjectMessage(response.data.message));
      } else {
        yield put(currentProjectSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showProjectMessage(err.response ? err.response.data : err));
    }
  });
}

export function* allUsersSaga() {
  yield takeEvery(LOAD_ALL_USERS_OF_PROJECT, function* ({ payload }) {
    try {
      const response = yield call(allUsers, payload);
      yield put(allUsersProjectSuccess(response.data.data));
    } catch (err) {
      yield put(showProjectMessage(err.response ? err.response.data : err));
    }
  });
}

export function* allLabelsSaga() {
  yield takeEvery(LOAD_ALL_LABELS_OF_PROJECT, function* ({ payload }) {
    try {
      const response = yield call(allLabels, payload);
      yield put(allLabelsProjectSuccess(response.data.data));
    } catch (err) {
      yield put(showProjectMessage(err.response ? err.response.data : err));
    }
  });
}

export function* createLabelSaga() {
  yield takeEvery(CREATE_LABEL, function* ({ payload }) {
    try {
      const response = yield call(createdLabelRequest, payload);
      yield put(createLabelSuccess(response.data.data));
    } catch (err) {
      yield put(showProjectMessage(err.response ? err.response.data : err));
    }
  });
}

export function* deleteLabelSaga() {
  yield takeEvery(DELETE_LABEL, function* ({ payload }) {
    try {
      yield call(deletedLabelRequest, payload);
      yield put(deleteLabelSuccess(payload));
    } catch (err) {
      yield put(showProjectMessage(err.response ? err.response.data : err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(add),
    fork(update),
    fork(del),
    fork(loadByOwner),
    fork(current),
    fork(loadByWorkspace),
    fork(allProject),
    fork(allUsersSaga),
    fork(allLabelsSaga),
    fork(createLabelSaga),
    fork(deleteLabelSaga)
  ]);
}
