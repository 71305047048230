import {
  CREATE_WORKSPACE,
  CREATE_WORKSPACE_SUCCESS,
  CURRENT_WORKSPACE,
  CURRENT_WORKSPACE_SUCCESS,
  DELETE_WORKSPACE,
  DELETE_WORKSPACE_SUCCESS,
  LOAD_DETAILS_WORKSPACE,
  LOAD_DETAILS_WORKSPACE_SUCCESS,
  LOAD_WORKSPACE,
  LOAD_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE,
  UPDATE_WORKSPACE_SUCCESS,
  WORKSPACE_ERROR,
  WORKSPACE_MESSAGE,
} from 'redux/constants/manage-project/workspace.type';

export const loadWorkspace = (userID = null, email = null) => {
  return {
    type: LOAD_WORKSPACE,
    payload: { userID, email },
  };
};

export const loadWorkspaceDetails = (workspaceID) => {
  return {
    type: LOAD_DETAILS_WORKSPACE,
    payload: { workspaceID },
  };
};

export const createWorkspace = (workspace) => {
  return {
    type: CREATE_WORKSPACE,
    payload: { workspace },
  };
};

export const updateWorkspace = (workspaceID, workspace) => {
  return {
    type: UPDATE_WORKSPACE,
    payload: {
      workspace,
      workspaceID,
    },
  };
};

export const deleteWorkspace = (workspaceID) => {
  return {
    type: DELETE_WORKSPACE,
    payload: workspaceID,
  };
};

export const currentWorkspace = (workspaceID) => {
  return {
    type: CURRENT_WORKSPACE,
    payload: workspaceID,
  };
};

export const loadWorkspaceSuccess = (workspaces) => {
  return {
    type: LOAD_WORKSPACE_SUCCESS,
    payload: workspaces,
  };
};

export const loadWorkspaceDetailsSuccess = (data) => {
  return {
    type: LOAD_DETAILS_WORKSPACE_SUCCESS,
    payload: data,
  };
};

export const deleteWorkspaceSuccess = (deleted) => {
  return {
    type: DELETE_WORKSPACE_SUCCESS,
    payload: deleted,
  };
};

export const updateWorkspaceSuccess = (updated) => {
  return {
    type: UPDATE_WORKSPACE_SUCCESS,
    payload: updated,
  };
};

export const createdWorkspaceSuccess = (created) => {
  return {
    type: CREATE_WORKSPACE_SUCCESS,
    payload: created,
  };
};

export const currentWorkspaceSuccess = (data) => {
  return {
    type: CURRENT_WORKSPACE_SUCCESS,
    payload: data,
  };
};

export const workspaceError = (msg) => {
  return {
    type: WORKSPACE_ERROR,
    payload: msg,
  };
};

export const showWorkspaceMessage = (message) => {
  return {
    type: WORKSPACE_MESSAGE,
    message,
  };
};
