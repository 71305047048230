import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  acceptInvitationSuccess,
  cancelInvitationSuccess,
  createdChannelSuccess,
  currentChannelSuccess,
  deleteChannelSuccess,
  loadChannelSuccess,
  loadInvitationByMeSuccess,
  loadInvitationReceivedSuccess,
  loadUserChannelSuccess,
  sendInvitationSuccess,
  showChannelMessage,
  updateChannelSuccess,
} from 'redux/actions/chat/channel';
import {
  ACCEPT_INVITATION,
  CREATE_CHANNEL,
  CURRENT_CHANNEL,
  DELETE_CHANNEL,
  DELETE_INVITATION,
  INVITATION_RECEIVED,
  INVIT_USER,
  LOAD_CHANNEL,
  LOAD_INVITATION_BY_ME,
  LOAD_USER_CHANNEL,
  UPDATE_CHANNEL,
} from 'redux/constants/chat/channel.type';
import {
  acceptInvitation,
  addChannel,
  cancelInvitation,
  currentChannel,
  editChannel,
  getReceivedInvitation,
  listChannel,
  removeChannel,
  sendInvitation,
  sentInvitation,
  userInChannel,
} from 'services/ChatService';

export function* addCh() {
  yield takeEvery(CREATE_CHANNEL, function* ({ payload }) {
    const { channel, wID } = payload;
    try {
      const response = yield call(addChannel, wID, channel);
      if (response.data.message) {
        yield put(showChannelMessage(response.data.message));
      } else {
        yield put(createdChannelSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChannelMessage(err.response ? err.response.data : err));
    }
  });
}

export function* updateCh() {
  yield takeEvery(UPDATE_CHANNEL, function* ({ payload }) {
    const { channel, channelID } = payload;
    try {
      const response = yield call(editChannel, channelID, channel);
      if (response.data.message) {
        yield put(showChannelMessage(response.data.message));
      } else {
        yield put(updateChannelSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChannelMessage(err.response ? err.response.data : err));
    }
  });
}

export function* deleteCh() {
  yield takeEvery(DELETE_CHANNEL, function* ({ payload }) {
    try {
      const response = yield call(removeChannel, payload);
      if (response.data.message) {
        yield put(showChannelMessage(response.data.message));
      } else {
        yield put(deleteChannelSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChannelMessage(err.response ? err.response.data : err));
    }
  });
}

export function* listCh() {
  yield takeEvery(LOAD_CHANNEL, function* ({ payload }) {
    const { wID, uId , pId} = payload;
    try {
      const response = yield call(listChannel, wID, uId, pId);
      if (response.data.message) {
        yield put(showChannelMessage(response.data.message));
      } else {
        yield put(loadChannelSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChannelMessage(err.response ? err.response.data : err));
    }
  });
}

export function* currentCh() {
  yield takeEvery(CURRENT_CHANNEL, function* ({ payload }) {
    const { channelID } = payload;
    try {
      const response = yield call(currentChannel, channelID);
      if (response.data.message) {
        yield put(showChannelMessage(response.data.message));
      } else {
        yield put(currentChannelSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChannelMessage(err.response ? err.response.data : err));
    }
  });
}

export function* getUserCh() {
  yield takeEvery(LOAD_USER_CHANNEL, function* ({ payload }) {
    const { channelID } = payload;
    try {
      const response = yield call(userInChannel, channelID);
      if (response.data.message) {
        yield put(showChannelMessage(response.data.message));
      } else {
        yield put(loadUserChannelSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChannelMessage(err.response ? err.response.data : err));
    }
  });
}

export function* send() {
  yield takeEvery(INVIT_USER, function* ({ payload }) {
    const { channelID, data } = payload;
    try {
      const response = yield call(sendInvitation, channelID, data);
      if (response.data.message) {
        yield put(showChannelMessage(response.data.message));
      } else {
        yield put(sendInvitationSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChannelMessage(err.response ? err.response.data : err));
    }
  });
}

export function* invitationByMe() {
  yield takeEvery(LOAD_INVITATION_BY_ME, function* ({ payload }) {
    const { userId } = payload;
    try {
      const response = yield call(sentInvitation, userId);
      if (response.data.message) {
        yield put(showChannelMessage(response.data.message));
      } else {
        yield put(loadInvitationByMeSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChannelMessage(err.response ? err.response.data : err));
    }
  });
}

export function* receivedInvitation() {
  yield takeEvery(INVITATION_RECEIVED, function* ({ payload }) {
    const { userId } = payload;
    try {
      const response = yield call(getReceivedInvitation, userId);
      if (response.data.message) {
        yield put(showChannelMessage(response.data.message));
      } else {
        yield put(loadInvitationReceivedSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChannelMessage(err.response ? err.response.data : err));
    }
  });
}

export function* accept() {
  yield takeEvery(ACCEPT_INVITATION, function* ({ payload }) {
    const { joinId } = payload;
    try {
      const response = yield call(acceptInvitation, joinId);
      if (response.data.message) {
        yield put(showChannelMessage(response.data.message));
      } else {
        yield put(acceptInvitationSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChannelMessage(err.response ? err.response.data : err));
    }
  });
}

export function* cancel() {
  yield takeEvery(DELETE_INVITATION, function* ({ payload }) {
    const { joinId } = payload;
    try {
      const response = yield call(cancelInvitation, joinId);
      if (response.data.message) {
        yield put(showChannelMessage(response.data.message));
      } else {
        yield put(cancelInvitationSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showChannelMessage(err.response ? err.response.data : err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addCh),
    fork(updateCh),
    fork(deleteCh),
    fork(listCh),
    fork(currentCh),
    fork(getUserCh),
    fork(send),
    fork(invitationByMe),
    fork(receivedInvitation),
    fork(accept),
    fork(cancel),
  ]);
}
