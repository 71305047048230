import { combineReducers } from 'redux';
import Auth from './Auth';
import ChannelReducer from './chat/channel';
import MessageReducer from './chat/message';
import AgendaReducer from './manage-project/agenda';
import ChecklistReducer from './manage-project/checklist';
import CommentReducer from './manage-project/comment';
import ListReducer from './manage-project/list';
import ProjectReducer from './manage-project/project';
import RoleReducer from './manage-project/role';
import TaskReducer from './manage-project/task';
import WorkspaceReducer from './manage-project/workspace.';
import Theme from './Theme';
import TrackingReducer from './tracking/tracking';
import MailReducer from './mail/mail';
import TimerReducer from './timer';
import SprintReducer from './manage-project/sprint';
import ListSprintReducer from './manage-project/listSprint';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  workspaces: WorkspaceReducer,
  project: ProjectReducer,
  task: TaskReducer,
  list: ListReducer,
  agenda: AgendaReducer,
  comments: CommentReducer,
  roles: RoleReducer,
  channels: ChannelReducer,
  messages: MessageReducer,
  tracking: TrackingReducer,
  checklist: ChecklistReducer,
  timer: TimerReducer,
  mail: MailReducer,
  sprint: SprintReducer,
  listSprint: ListSprintReducer,
});

export default reducers;
