import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { EXT_PREFIX_PATH } from 'configs/AppConfig';

export const ExtViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${EXT_PREFIX_PATH}/w`}
          component={lazy(() => import(`./workspace/workspace`))}
        />
        <Route
          path={`${EXT_PREFIX_PATH}/validate/email`}
          component={lazy(() => import(`./validate-email`))}
        />
        <Route
          path={`${EXT_PREFIX_PATH}/join/workspace/:token`}
          component={lazy(() => import(`./join-workspace`))}
        />
      </Switch>
    </Suspense>
  );
};

export default ExtViews;
