import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  createdRoleSuccess,
  deleteRoleSuccess,
  loadRoleSuccess,
  showRoleMessage,
  updateRoleSuccess,
} from 'redux/actions/manage-project/role';
import {
  CREATE_ROLE,
  DELETE_ROLE,
  LOAD_BY_PROJECT_ROLE,
  UPDATE_ROLE,
} from 'redux/constants/manage-project/role.type';
import { addRole, editRole, getRole, removeRole } from 'services/RoleService';

export function* add() {
  yield takeEvery(CREATE_ROLE, function* ({ payload }) {
    const { role } = payload;
    try {
      const response = yield call(addRole, role);
      if (response.data.message) {
        yield put(showRoleMessage(response.data.message));
      } else {
        yield put(createdRoleSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showRoleMessage(err.response ? err.response.data : err));
    }
  });
}

export function* update() {
  yield takeEvery(UPDATE_ROLE, function* ({ payload }) {
    const { role, roleID } = payload;
    try {
      const response = yield call(editRole, roleID, role);
      if (response.data.message) {
        yield put(showRoleMessage(response.data.message));
      } else {
        yield put(updateRoleSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showRoleMessage(err.response ? err.response.data : err));
    }
  });
}

export function* del() {
  yield takeEvery(DELETE_ROLE, function* ({ payload }) {
    try {
      const response = yield call(removeRole, payload);
      if (response.data.message) {
        yield put(showRoleMessage(response.data.message));
      } else {
        yield put(deleteRoleSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showRoleMessage(err.response ? err.response.data : err));
    }
  });
}

export function* byProject() {
  yield takeEvery(LOAD_BY_PROJECT_ROLE, function* ({ payload }) {
    const { projectID } = payload;
    try {
      const response = yield call(getRole, projectID);
      if (response.data.message) {
        yield put(showRoleMessage(response.data.message));
      } else {
        yield put(loadRoleSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showRoleMessage(err.response ? err.response.data : err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(add), fork(update), fork(del), fork(byProject)]);
}
