export const LOAD_COMMENT = 'LOAD_COMMENT';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const COMMENT_ERROR = 'COMMENT_ERROR';
export const COMMENT_MESSAGE = 'COMMENT_MESSAGE';

export const LOAD_COMMENT_SUCCESS = 'LOAD_COMMENT_SUCCESS';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
