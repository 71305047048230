import {
  CREATE_LIST,
  CREATE_LIST_SUCCESS,
  DELETE_LIST,
  DELETE_LIST_SUCCESS,
  LIST_ERROR,
  LIST_MESSAGE,
  LOAD_BY_PROJECT_LIST,
  LOAD_BY_PROJECT_LIST_SUCCESS,
  LOAD_LIST,
  LOAD_LIST_SUCCESS,
  UPDATE_LIST,
  UPDATE_LIST_SUCCESS,
  DRAG_TASK_IN_LIST,
  UPDATE_TASK_IN_LIST,
  DELETE_TASK_IN_LIST,
  ADD_TASK_IN_LIST,
} from 'redux/constants/manage-project/list.type';

export const loadList = () => {
  return {
    type: LOAD_LIST,
  };
};

export const loadListByProject = (projectID) => {
  return {
    type: LOAD_BY_PROJECT_LIST,
    payload: { projectID },
  };
};

export const createList = (list) => {
  return {
    type: CREATE_LIST,
    payload: { list },
  };
};

export const updateList = (listID, list) => {
  return {
    type: UPDATE_LIST,
    payload: {
      listID,
      list,
    },
  };
};

export const dragTaskInList = ({ destData, sourceData, dragData }) => {
  return {
    type: DRAG_TASK_IN_LIST,
    payload: {
      destData,
      sourceData,
      dragData,
    },
  };
};

export const addTaskInList = (payload) => {
  return {
    type: ADD_TASK_IN_LIST,
    payload: payload,
  };
};

export const updateTaskInList = (payload) => {
  return {
    type: UPDATE_TASK_IN_LIST,
    payload: payload,
  };
};

export const deleteTaskInList = (payload)=>{
  return{
    type: DELETE_TASK_IN_LIST,
    payload: payload
  }
}

export const deleteList = (listID) => {
  return {
    type: DELETE_LIST,
    payload: listID,
  };
};

export const loadListSuccess = (lists) => {
  return {
    type: LOAD_LIST_SUCCESS,
    payload: lists,
  };
};

export const loadListByProjectSuccess = (data) => {
  return {
    type: LOAD_BY_PROJECT_LIST_SUCCESS,
    payload: data,
  };
};
export const deleteListSuccess = (deleted) => {
  return {
    type: DELETE_LIST_SUCCESS,
    payload: deleted,
  };
};

export const updateListSuccess = (updated) => {
  return {
    type: UPDATE_LIST_SUCCESS,
    payload: updated,
  };
};

export const createdListSuccess = (created) => {
  return {
    type: CREATE_LIST_SUCCESS,
    payload: created,
  };
};

export const ListError = (msg) => {
  return {
    type: LIST_ERROR,
    payload: msg,
  };
};

export const showListMessage = (message) => {
  return {
    type: LIST_MESSAGE,
    message,
  };
};
