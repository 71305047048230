import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { WORKSPACE_PREFIX_PATH } from 'configs/AppConfig';

export const WorkspaceViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${WORKSPACE_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${WORKSPACE_PREFIX_PATH}/apps`}
          component={lazy(() => import(`./apps`))}
        />
        <Redirect
          from={`${WORKSPACE_PREFIX_PATH}`}
          to={`${WORKSPACE_PREFIX_PATH}/apps/project`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(WorkspaceViews);
