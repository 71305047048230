import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useHistory,
} from 'react-router-dom';
import { connect } from 'react-redux';
import AppLayout from 'layouts/app-layout';
import AuthLayout from 'layouts/auth-layout';
import AppLocale from 'lang';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  EXT_PREFIX_PATH,
  WORKSPACE_PREFIX_PATH,
} from 'configs/AppConfig';
import useBodyClass from 'hooks/useBodyClass';
import ExtraLayout from 'layouts/extra-layout';
import WorkspaceLayout from 'layouts/workspace-layout';
import { chatSocket } from 'utils/socket';
import { notification } from 'antd';
import { MessageFilled } from '@ant-design/icons';
import { validateT } from 'services/AuthService';

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, token, location, direction } = props;
  const history = useHistory();
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  useEffect(() => {
    chatSocket.on('NEW_MESSAGE', (data) => {
      if (data) {
        const body = data.content;
        const title = data._sender_name;
        if (!('Notification' in window)) {
          console.log('Browser does not support desktop notification');
        } else {
          Notification.requestPermission();
        }

        let options = {
          body: body,
          icon: 'https://icons.iconarchive.com/icons/dtafalonso/android-lollipop/512/calendar-icon.png',
          dir: 'ltr',
        };

        const key = `open${Date.now()}`;
        notification.open({
          message: title,
          description: body,
          key,
          icon: <MessageFilled style={{ color: '#0dcaf0' }} />,
          placement: 'topRight',
          duration: 10,
        });
        const notif = document.getElementById('notif-message-mp3');
        notif.play();

        new Notification(title, options);
      }
    });
  }, []);

  function verifyToken(token) {
    validateT(token)
      .then((res) => {
        if (!res.data) {
          localStorage.removeItem('auth_token');
          window.location.href = AUTH_PREFIX_PATH;
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('auth_token');
          window.location.href = AUTH_PREFIX_PATH;
        }
      });
  }

  if (
    history.location.pathname.includes(APP_PREFIX_PATH) ||
    history.location.pathname.includes(EXT_PREFIX_PATH) ||
    history.location.pathname.includes(WORKSPACE_PREFIX_PATH)
  ) {
    const token = window.localStorage.getItem('auth_token');
    verifyToken(token);
  }

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            {token ? (
              <Redirect to={EXT_PREFIX_PATH + '/w'} />
            ) : (
              <Redirect to={AUTH_PREFIX_PATH} />
            )}
          </Route>
          <Route path={AUTH_PREFIX_PATH + '/register'}>
            {token ? (
              <Redirect to={EXT_PREFIX_PATH + '/w'} />
            ) : (
              <AuthLayout direction={direction} />
            )}
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          {/* juste pour corriger l'erreur du route auth/register */}
          <Route path={EXT_PREFIX_PATH}>
            {token ? (
              <ExtraLayout direction={direction} />
            ) : (
              <Redirect to={AUTH_PREFIX_PATH} />
            )}
          </Route>
          {/* <Route path={WORKSPACE_PREFIX_PATH}>
            <WorkspaceLayout direction={direction} />
          </Route> */}
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
          <RouteInterceptor
            path={WORKSPACE_PREFIX_PATH}
            isAuthenticated={token}
          >
            <WorkspaceLayout direction={direction} location={location} />
          </RouteInterceptor>
          <RouteInterceptor path={EXT_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, direction, token };
};

export default withRouter(connect(mapStateToProps)(Views));
