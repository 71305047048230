import React, { useEffect } from 'react';
import { Menu, Dropdown, Avatar, Tooltip } from 'antd';
import { connect } from 'react-redux';
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { currentUser, signOut } from 'redux/actions/Auth';
import { AUTH_TOKEN } from 'redux/constants/Auth';
import Utils from 'utils';
import { currentWorkspace } from 'redux/actions/manage-project/workspace.action';
import { loadByWorkspace } from 'redux/actions/manage-project/project.action';

const menuItem = [
  {
    title: 'Edit Profile',
    icon: EditOutlined,
    path: '/',
  },

  {
    title: 'Account Setting',
    icon: SettingOutlined,
    path: '/',
  },
  {
    title: 'Billing',
    icon: ShopOutlined,
    path: '/',
  },
  {
    title: 'Help Center',
    icon: QuestionCircleOutlined,
    path: '/',
  },
];

export const NavProfile = ({
  signOut,
  current,
  currentUser,
  currentWorkspace,
  loadByWorkspace,
}) => {
  useEffect(() => {
    currentUser(localStorage.getItem(AUTH_TOKEN));
    if (localStorage.getItem('___WORKSPACE___')) {
      currentWorkspace(localStorage.getItem('___WORKSPACE___'));
      loadByWorkspace(localStorage.getItem('___WORKSPACE___'));
    }
  }, []);
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {/* <Avatar size={45} src={profileImg} /> */}
          <div className="pl-3">
            <h4 className="mb-0">
              {current?.lastName + ' ' + current?.firstName}
            </h4>
            <span className="text-muted">{current?.accountType}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={() => signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Tooltip
            title={current?.firstName + ' ' + current?.lastName}
            key={`avatar-${1}`}
          >
            <Avatar
              size="small"
              className={`ml-1 cursor-pointer ant-avatar-${
                current?.firstName + ' ' + current?.lastName
              }`}
              src={''}
            >
              <span className="font-weight-semibold font-size-sm">
                {Utils.getNameInitial(
                  current
                    ? current?.firstName + ' ' + current?.lastName
                    : 'null'
                )}
              </span>
            </Avatar>
          </Tooltip>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth }) => {
  const { current } = auth;
  return {
    current,
  };
};

export default connect(mapStateToProps, {
  signOut,
  currentUser,
  currentWorkspace,
  loadByWorkspace,
})(NavProfile);
