import {
  COMMENT_ERROR,
  COMMENT_MESSAGE,
  CREATE_COMMENT,
  CREATE_COMMENT_SUCCESS,
  DELETE_COMMENT,
  DELETE_COMMENT_SUCCESS,
  LOAD_COMMENT,
  LOAD_COMMENT_SUCCESS,
  UPDATE_COMMENT,
  UPDATE_COMMENT_SUCCESS,
} from 'redux/constants/manage-project/comment.type';

export const loadComment = (taskID) => {
  return {
    type: LOAD_COMMENT,
    payload: { taskID },
  };
};

export const createComment = (comment) => {
  return {
    type: CREATE_COMMENT,
    payload: { comment },
  };
};

export const updateComment = (commentID, comment) => {
  return {
    type: UPDATE_COMMENT,
    payload: {
      comment,
      commentID,
    },
  };
};

export const deleteComment = (commentID) => {
  return {
    type: DELETE_COMMENT,
    payload: commentID,
  };
};

export const loadCommentSuccess = (comments) => {
  return {
    type: LOAD_COMMENT_SUCCESS,
    payload: comments,
  };
};

export const deleteCommentSuccess = (deleted) => {
  return {
    type: DELETE_COMMENT_SUCCESS,
    payload: deleted,
  };
};

export const updateCommentSuccess = (updated) => {
  return {
    type: UPDATE_COMMENT_SUCCESS,
    payload: updated,
  };
};

export const createdCommentSuccess = (created) => {
  return {
    type: CREATE_COMMENT_SUCCESS,
    payload: created,
  };
};

export const commentError = (msg) => {
  return {
    type: COMMENT_ERROR,
    payload: msg,
  };
};

export const showCommentMessage = (message) => {
  return {
    type: COMMENT_MESSAGE,
    message,
  };
};
