import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  createdWorkspaceSuccess,
  currentWorkspaceSuccess,
  deleteWorkspaceSuccess,
  loadWorkspaceDetailsSuccess,
  loadWorkspaceSuccess,
  showWorkspaceMessage,
  updateWorkspaceSuccess,
} from 'redux/actions/manage-project/workspace.action';
import {
  CREATE_WORKSPACE,
  CURRENT_WORKSPACE,
  DELETE_WORKSPACE,
  LOAD_DETAILS_WORKSPACE,
  LOAD_WORKSPACE,
  UPDATE_WORKSPACE,
} from 'redux/constants/manage-project/workspace.type';
import {
  created,
  currentW,
  deleted,
  loaded,
  loadedDetails,
  updated,
} from 'services/workspaceService';

export function* add() {
  yield takeEvery(CREATE_WORKSPACE, function* ({ payload }) {
    const { workspace } = payload;
    try {
      const response = yield call(created, workspace);
      if (response.data.message) {
        yield put(showWorkspaceMessage(response.data.message));
      } else {
        yield put(createdWorkspaceSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showWorkspaceMessage(err.response ? err.response.data : err));
    }
  });
}

export function* update() {
  yield takeEvery(UPDATE_WORKSPACE, function* ({ payload }) {
    const { workspace, workspaceID } = payload;
    try {
      const response = yield call(updated, workspaceID, workspace);
      if (response.data.message) {
        yield put(showWorkspaceMessage(response.data.message));
      } else {
        yield put(updateWorkspaceSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showWorkspaceMessage(err.response ? err.response.data : err));
    }
  });
}

export function* del() {
  yield takeEvery(DELETE_WORKSPACE, function* ({ payload }) {
    try {
      const response = yield call(deleted, payload);
      if (response.data.message) {
        yield put(showWorkspaceMessage(response.data.message));
      } else {
        yield put(deleteWorkspaceSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showWorkspaceMessage(err.response ? err.response.data : err));
    }
  });
}

export function* load() {
  yield takeEvery(LOAD_WORKSPACE, function* ({ payload }) {
    const { userID, email } = payload;
    try {
      const response = yield call(loaded, userID, email);
      if (response.data.message) {
        yield put(showWorkspaceMessage(response.data.message));
      } else {
        yield put(loadWorkspaceSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showWorkspaceMessage(err.response ? err.response.data : err));
    }
  });
}

export function* loadDetails() {
  yield takeEvery(LOAD_DETAILS_WORKSPACE, function* ({ payload }) {
    const { workspaceID } = payload;
    try {
      const response = yield call(loadedDetails, workspaceID);
      if (response.data.message) {
        yield put(showWorkspaceMessage(response.data.message));
      } else {
        yield put(loadWorkspaceDetailsSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showWorkspaceMessage(err.response ? err.response.data : err));
    }
  });
}

export function* current() {
  yield takeEvery(CURRENT_WORKSPACE, function* ({ payload }) {
    try {
      const response = yield call(currentW, payload);
      if (response.data.message) {
        yield put(showWorkspaceMessage(response.data.message));
      } else {
        yield put(currentWorkspaceSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showWorkspaceMessage(err.response ? err.response.data : err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(add),
    fork(update),
    fork(del),
    fork(load),
    fork(current),
    fork(loadDetails),
  ]);
}
