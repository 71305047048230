import {
  ADD_COMPANY_CONFIGURATION_SUCCESS,
  LOAD_COMPANY_CONFIGURATION_SUCCESS,
  LOAD_TRACKING_SUCCESS,
  UPDATE_COMPANY_CONFIGURATION_SUCCESS,
} from 'redux/constants/tracking/tracking';

const initialState = {
  configuration: null,
  timer: 0,
  current: null,
  trackings: [],
};

function TrackingReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_COMPANY_CONFIGURATION_SUCCESS:
      return { ...state, configuration: action.payload };

    case ADD_COMPANY_CONFIGURATION_SUCCESS:
      return { ...state, configuration: action.payload };

    case UPDATE_COMPANY_CONFIGURATION_SUCCESS:
      return { ...state, configuration: action.payload };
    case LOAD_TRACKING_SUCCESS:
      return { ...state, trackings: action.payload };
    default:
      return state;
  }
}

export default TrackingReducer;
