import {
  ADD_MESSAGE,
  ADD_MESSAGE_SUCCESS,
  LOAD_MESSAGE,
  LOAD_MESSAGE_SUCCESS,
  MESSAGE_ERROR,
  MESSAGE_MESSAGE,
} from 'redux/constants/chat/message';

export const loadMessage = (channelID, conversationID) => {
  return {
    type: LOAD_MESSAGE,
    payload: { channelID , conversationID},
  };
};

export const addMessage = (data) => {
  return {
    type: ADD_MESSAGE,
    payload: { data },
  };
};

export const loadMessageSuccess = (messages) => {
  return {
    type: LOAD_MESSAGE_SUCCESS,
    payload: messages,
  };
};
export const addMessageSuccess = (messages) => {
  return {
    type: ADD_MESSAGE_SUCCESS,
    payload: messages,
  };
};

export const messageError = (msg) => {
  return {
    type: MESSAGE_ERROR,
    payload: msg,
  };
};

export const showMessageMessage = (message) => {
  return {
    type: MESSAGE_MESSAGE,
    message,
  };
};
