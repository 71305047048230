import React from 'react';
import navigationConfigWorkspace from 'configs/NavigationConfigWorkspace';

export const RouteContext = React.createContext();

export default function RouteProvider({ children }) {
  const workspaces = navigationConfigWorkspace.find(
    (item) => item.key === 'apps'
  );
  const actualRoute = workspaces.submenu.find(
    (item) => item.key === 'apps-project'
  );

  return (
    <RouteContext.Provider value={{ actualRoute }}>
      {children}
    </RouteContext.Provider>
  );
}
