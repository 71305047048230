import axios from 'axios';
import { API_BASE_URL } from 'constants/ApiConstant';

const url = API_BASE_URL + 'listSprint/';


export const loaded = () => {
  return axios.get(`${url}`);
};

