import axios from 'axios';
import { API_BASE_URL_CHAT } from 'constants/ApiConstant';

const url = API_BASE_URL_CHAT;

export const addChannel = (wID, data) => {
  return axios.post(`${url}${wID}/channel/add`, data);
};

export const editChannel = (id, data) => {
  return axios.put(`${url}channel/update/${id}`, data);
};

export const removeChannel = (id) => {
  return axios.delete(`${url}channel/delete/${id}`);
};

export const listChannel = (wID, uId , pId) => {
  return axios.get(`${url}/${wID}/${uId}/list?_project=${pId}`);
};

export const channelInWorkspace = (wID, uId , pId) => {
  return axios.get(`${url}/${wID}/${uId}/list?_project=${pId}`);
};


export const currentChannel = (channelID) => {
  return axios.get(`${url}/channel/${channelID}`);
};

export const listMessageByChannel = (channelID) => {
  return axios.get(`${url}channel/${channelID}/messages`);
};

export const addMessageByChannel = (channelID, data) => {
  return axios.post(`${url}channel/${channelID}/message`, data);
};


export const loadUserInConvesation = (cuserId) => {
  return axios.get(`${url}userinconversation/${cuserId}`);
};

export const listMessageByConversation = (conversationID) => {
  return axios.get(`${url}conversation/${conversationID}/messages`);
};

export const addMessageByConversation = (conversationID, data) => {
  return axios.post(`${url}conversation/${conversationID}/message`, data);
};

export const sendInvitation = (channelID, data) => {
  return axios.post(`${url}channel/invite/${channelID}`, data);
};

export const userInChannel = (channelID) => {
  return axios.get(`${url}channel/${channelID}/users/list`);
};

export const sentInvitation = (userId) => {
  return axios.get(`${url}channel/invitation-user/${userId}`);
};

export const getReceivedInvitation = (userId) => {
  return axios.get(`${url}channel/invitation-received/${userId}`);
};

export const cancelInvitation = (joinId) => {
  return axios.get(`${url}channel/cancel/invitation/${joinId}`);
};

export const acceptInvitation = (joinId) => {
  return axios.get(`${url}channel/accept/invitation/${joinId}`);
};
