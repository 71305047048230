import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const DesktopAppContext = React.createContext();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function DesktopAppProvider({ children, token }) {
  const redirectUrl = useQuery().get('redirect');

  useEffect(() => {
    if (token && redirectUrl) {
      const desktopRedirectUrl = `${redirectUrl}auth?token=${token}`;

      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = desktopRedirectUrl;

      document.body.appendChild(iframe);

      iframe.onload = () => {
        window.close();
      };

      setTimeout(() => {
        window.close();
      }, 3000);
    }
  }, [token, redirectUrl]);

  return (
    <DesktopAppContext.Provider value={{ url: redirectUrl }}>
      {children}
    </DesktopAppContext.Provider>
  );
}

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps)(DesktopAppProvider);
