import {
  API_BASE_URL_CHAT_SOCKET,
  API_BASE_URL_TRACKING,
} from 'constants/ApiConstant';
import React from 'react';
import io from 'socket.io-client';

export const socket = io(API_BASE_URL_CHAT_SOCKET, {
  reconnectionAttempts: 4,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 1000,
  timeout: 80000,
});
export const chatSocket = io(API_BASE_URL_CHAT_SOCKET, {
  reconnectionAttempts: 4,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 1000,
  timeout: 80000,
});
// export const trackingSocket = io(API_BASE_URL_TRACKING);
export const SocketContext = React.createContext();
export const SocketContextChat = React.createContext();
export const SocketContextTracking = React.createContext();
